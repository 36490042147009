import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IRubric } from '../../store/rubrics/types';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import SourceTwoToneIcon from '@mui/icons-material/SourceTwoTone';
import { format } from 'date-fns';
import { DeleteEvent, UpdateEventPositions } from '../../store/calendar/actions';
import { ROUTES } from '../../utils/routes';
import { IEvent } from '../../store/calendar/types';

const findChildren = (cards: any[] | null, ids: number[]): any[] | null => {
  if (!cards) return cards;
  const card = cards[ids[0]];
  if (card && card.children) {
    ids.splice(0, 1);
    return findChildren(card.children, ids);
  }
  return cards;
};

const setChildren = (cards: IRubric[] | null, ids: number[], newChildren: IRubric[] | null): IRubric[] | null => {
  if (!cards) return cards;
  let prev = null;
  for (const id of ids) {
    if (prev && prev[id]) {
      prev = prev[id].children;
    }
    if (!prev && cards[id]) {
      prev = cards[id].children;
    }
  }
  prev = newChildren;
  return cards;
};

type PropsType = {
  children: any[] | null;
  cards: any[] | null;
  chain?: string;
  level?: number;
  categoryId: number;
  setCards: (cards: IRubric[] | null) => void;
};

export const CalendarTree: React.FC<PropsType> = ({ children, cards, categoryId, chain, level = 0, setCards }) => {
  const [draggId, setDraggId] = React.useState('');
  const [overId, setOverId] = React.useState('');
  const [expend, setExpand] = React.useState<string[] >(
    children?.filter((el) => el.site_id).map((el, i) => i + '') || []
  );
  const [hoverId, setHoverId] = React.useState('');

  useEffect(() => {
    if (children) setExpand(children?.filter((el) => el.site_id).map((el, i) => i + ''));
  }, [children]);

  const [isCopied, setIsCopied] = useState({ id: 0 });

  const { Configurations } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();


  const moveCard = (dragIndex: number, hoverIndex: number, chain: string) => {
    if (!cards) return null;
    const ids = chain.split('-').map((str) => Number(str));
    ids.pop();
    const currentCards = findChildren(cards, ids);
    if (currentCards && currentCards[0].site_id) return;
    if (currentCards && currentCards[dragIndex]) {
      const card = currentCards[dragIndex];
      currentCards.splice(dragIndex, 1);
      currentCards.splice(hoverIndex, 0, card);

      // Якщо буде потрібно рухати дати
      if (currentCards[0].site_id) {
        // dispatch(UpdateCalendarPositions.request({ data: currentCards.map((it, i) => ({ id: it.id, position: i })) }));
      } else {
        dispatch(UpdateEventPositions.request({ data: currentCards.map((it, i) => ({ id: it.id, position: i })) }));
      }

      const ids = chain.split('-').map((str) => Number(str));
      const newCards = setChildren([...cards], ids, currentCards);
      setCards(newCards);
    }
  };

  const onDeleteCard = (id: number, calendar_id: number) => {
    dispatch(
      DeleteEvent.request({
        id,
        data: {
          calendar_id,
          site_id: Configurations.siteId,
        },
      })
    );
  };

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`edit/${path}?site_id=${Configurations.siteId}`));
    };

  const handleDraggEnd = (chainId: string) => {
    if (draggId !== overId) {
      const drId = draggId.split('-').pop();
      const chId = overId.split('-').pop();
      moveCard(Number(drId), Number(chId), chainId);
    }
    setDraggId('');
    setHoverId('');
  };

  const copyEvent = (event: IEvent) => {
    setIsCopied({ id: event.id });
    setTimeout(() => setIsCopied({ id: 0 }), 200);
    window.open(ROUTES.routeChange(`coppy/${event.id}?site_id=${Configurations.siteId}`));
  };

  

  return (
    <>
      {children
        ?.sort((a, b) => a.position - b.position)
        ?.map((event, index) => {
          const chainId = chain ? `${chain}-${index}` : String(index);
          const isDisabled = event.children ? event.children.length > 0 : false;
          return (
            <div
              key={chainId}
              onDragOver={() => {
                chainId.length !== expend.length && setHoverId(chainId);
              }}
              onDragLeave={() => setOverId(chainId)}
              onDrag={() => !draggId && setDraggId(chainId)}
              onDragEnd={() => handleDraggEnd(chainId)}
              draggable={event.site_id ? false : true}
            >
              <Box display={'flex'} mt={'5px'}>
                <div
                  
                  onClick={() =>
                    setExpand(
                      expend.includes(chainId) ? expend.filter((el, i) => el !== chainId) : [...expend, chainId]
                    )
                  }
                >
                  {event.children && event.children.length > 0 ? (
                    expend.includes(chainId) ? (
                      <RemoveIcon style={{ fontSize: '1rem' }} />
                    ) : (
                      <AddIcon style={{ fontSize: '1rem' }} />
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div
                  
                  style={{ background: chainId === hoverId && !expend.includes(hoverId) ? '#554e4e70' : '' }}
                >
                  <div  style={{ color: '#3f51b5' }}>
                    {event.children && event.children.length > 0 ? (
                      <AccessTimeTwoToneIcon fontSize='small' />
                    ) : (
                      <SourceTwoToneIcon fontSize='small' />
                    )}
                  </div>
                  <div  style={{ cursor: event.site_id ? 'pointer' : 'move' }}>
                    {!event.site_id ? event.title : format(new Date(event.title), 'dd.MM.yyyy')}
                  </div>

                  <div  onClick={(e) => copyEvent(event)}>
                    {level > 0 && (
                      <ContentCopyIcon
                        htmlColor={isCopied.id === event.id ? '#3F51B5' : 'black'}
                        style={{ fontSize: '1.1rem' }}
                      />
                    )}
                  </div>

                  <div
                    onClick={handleChangeRoute(event.id)}
                    style={{ display: !event.site_id ? 'flex' : 'none' }}
                    
                  >
                    <EditIcon style={{ fontSize: '1.1rem' }} />
                  </div>

                  <IconButton
                    style={{ display: !event.site_id ? 'flex' : 'none' }}
                    disabled={isDisabled}
                    
                    onClick={() => onDeleteCard(event.id, event.calendar_id)}
                  >
                    <DeleteIcon color={isDisabled ? 'disabled' : 'error'} style={{ fontSize: '1.1rem' }} />
                  </IconButton>
                </div>
              </Box>

              {expend.includes(chainId) && (
                <div >
                  <CalendarTree
                    children={event.children}
                    cards={cards}
                    categoryId={categoryId}
                    chain={chainId}
                    level={level + 1}
                    setCards={setCards}
                  />
                </div>
              )}
            </div>
          );
        })}
    </>
  );
};
