export interface TRatingsState {
  loading: boolean;
  error: string | undefined;
  ratings: IRatings[];
  commentators:
    | {
        id: number;
        fio: string;
      }[]
    | [];
  data: {
    pages: number;

    ratings: IRatings[];
    commentators:
      | {
          id: number;
          fio: string;
        }[];
  } | null;
  pages: number;
  total: number;
}

export interface IRatings {
  id: number;
  created_at: Date;
  title: string;
  comments: string;
  publicationType: string;
  publication_id: number;
  publication_title: string;
  userId3_fio: string;
  userId3_phone: string;
  userId3_email: string;
  userId3: number;
  type: string;
  publication: {
    title: string;
    site_id: number;
    id: number;
  };
  rating: IRatingDetail;
}

export interface IRatingDetail {
  created_at: string;
  id: number;
  rate: number;
  title: string;
  updated_at: null | Date;
}

enum ActionTypes {
  SET_RATINGS = '@@ratings/SET_RATINGS',

  SET_COMMENTATORS = '@@ratings/SET_COMMENTATORS',

  RESET_RATINGS = '@@ratings/RESET_RATINGS',

  GET_RATINGS_R = '@@ratings/GET_RATINGS_R',
  GET_RATINGS_S = '@@ratings/GET_RATINGS_S',
  GET_RATINGS_E = '@@ratings/GET_RATINGS_E',

  SEARCH_RATINGS_R = '@@ratings/SEARCH_RATINGS_R',
  SEARCH_RATINGS_S = '@@ratings/SEARCH_RATINGS_S',
  SEARCH_RATINGS_E = '@@ratings/SEARCH_RATINGS_E',

  GET_SEARCH_COMMENTATORS_R = '@@ratings/GET_SEARCH_COMMENTATORS_R',
  GET_SEARCH_COMMENTATORS_S = '@@ratings/GET_SEARCH_COMMENTATORS_S',
  GET_SEARCH_COMMENTATORS_E = '@@ratings/GET_SEARCH_COMMENTATORS_E',
}

export default ActionTypes;
