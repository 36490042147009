export interface TOfficialDocFilesState {
  data: IOfficialDocFile[] | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IOfficialDocFile {
  id: number;
  docId: number;
  groupId: number;
  name: string;
  fileName: string;
  path: string;
  adate: Date;
  udate: Date;
}

enum ActionTypes {
  SET_DATA = '@@officialDocFiles/SET_DATA',

  CREATE_FILE_R = '@@officialDocFiles/CREATE_FILE_R',
  CREATE_FILE_S = '@@officialDocFiles/CREATE_FILE_S',
  CREATE_FILE_E = '@@officialDocFiles/CREATE_FILE_E',

  UPDATE_FILE_R = '@@officialDocFiles/UPDATE_FILE_R',
  UPDATE_FILE_S = '@@officialDocFiles/UPDATE_FILE_S',
  UPDATE_FILE_E = '@@officialDocFiles/UPDATE_FILE_E',

  DELETE_FILE_R = '@@officialDocFiles/DELETE_FILE_R',
  DELETE_FILE_S = '@@officialDocFiles/DELETE_FILE_S',
  DELETE_FILE_E = '@@officialDocFiles/DELETE_FILE_E',
}

export default ActionTypes;
