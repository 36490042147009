import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IMainInMonthYears, ITemplate } from './types';

const GetMainInMonthAction = new Actions('GET_MAIN_IN_MONTH', ActionTypes);

export interface TypeGetMainInMonthR extends TDefRequest {
  data: {
    site_id: number;
  };
}

export const GetMainInMonth = {
  request: (payload: TypeGetMainInMonthR) => GetMainInMonthAction.request(payload),
  success: (payload: IMainInMonthYears[]) => GetMainInMonthAction.success(payload),
  error: (payload: string) => GetMainInMonthAction.error(payload),
};

export interface TypeGetOneMainInMonthR extends TDefRequest {
  id: number;
}

const GetOneMainInMonthAction = new Actions('GET_ONE_MAIN_IN_MONTH', ActionTypes);

export const GetOneMainInMonth = {
  request: (payload: TypeGetOneMainInMonthR) => GetOneMainInMonthAction.request(payload),
  success: (payload: IMainInMonthYears) => GetOneMainInMonthAction.success(payload),
  error: (payload: string) => GetOneMainInMonthAction.error(payload),
};

const CreateMainInMonthAction = new Actions('CREATE_MAIN_IN_MONTH', ActionTypes);

export interface TypeCreateMainInMonth extends TDefRequest {
  data: {
    site_id: number;
    templates: any[];
    month: number;
    is_public: 1 | 0;
    year: number;
  };
  id?: number;
}

export const CreateMainInMonth = {
  request: (payload: TypeCreateMainInMonth) => CreateMainInMonthAction.request(payload),
  success: (payload: IMainInMonthYears[]) => CreateMainInMonthAction.success(payload),
  error: (error: string) => CreateMainInMonthAction.error(error),
};

const DeleteMainInMonthAction = new Actions('DELETE_MAIN_IN_MONTH', ActionTypes);

export interface TypeDeleteMainInMonth extends TDefRequest {
  id: number;
}

export const DeleteMainInMonth = {
  request: (payload: TypeDeleteMainInMonth) => DeleteMainInMonthAction.request(payload),
  success: (id: number) => DeleteMainInMonthAction.success(id),
  error: (message: string) => DeleteMainInMonthAction.error(message),
};

const UpdateMainInMonthAction = new Actions('UPDATE_MAIN_IN_MONTH', ActionTypes);

export const UpdateMainInMonth = {
  request: (payload: TypeCreateMainInMonth) => UpdateMainInMonthAction.request(payload),
  success: (payload: IMainInMonthYears) => UpdateMainInMonthAction.success(payload),
  error: (message: string) => UpdateMainInMonthAction.error(message),
};

interface IPosition {
  id: number | string;
  position: number;
}

export interface TypeUpdatePositionsTemplateR extends TDefRequest {
  data: {
    positions: IPosition[];
    parentId: number;
  };
}

const UpdateTemplatePositionAction = new Actions('UPDATE_TEMPLATE_POSITION', ActionTypes);

export const UpdateTemplatePosition = {
  request: (payload: TypeUpdatePositionsTemplateR) => UpdateTemplatePositionAction.request(payload),
  success: (payload: ITemplate[]) => UpdateTemplatePositionAction.success(payload),
  error: (message: string) => UpdateTemplatePositionAction.error(message),
};
