import Modal from '@mui/material/Modal';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { BasicDatePicker } from '../ui';
import { Alert, AlertTitle } from '@mui/lab';

interface BoxModalProps {
  title: string;
  datePicker?: { date: Date | null; lable: string; id: string };
  open: boolean;
  initValue?: string;
  handleClose: () => void;
  handleConfirm: (value: string | { title: string; date: Date }, id?: number, type?: 'new' | 'edit') => void;
}

export const BoxModal: React.FC<BoxModalProps> = ({ title, open, initValue, handleClose, handleConfirm, datePicker }) => {
  const [value, setValue] = React.useState(initValue);
  const [error, setError] = React.useState(false);
  const [date, setDate] = React.useState(datePicker?.date);

  useEffect(() => {
    if ((!value?.trim() || !date || String(date) === 'Invalid Date') && datePicker?.lable) setError(true);
    if (!value?.trim() && !datePicker?.lable) setError(true);
    else if ((value?.trim() && date) || (!datePicker?.lable && value?.trim())) setError(false);
  }, [value, date, datePicker]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={'modal'}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className="boxModal-inner">
        <div className="boxBody">
          <h2>{title}</h2>
          <TextField
            error={!value}
            label="Заголовок:"
            placeholder="Заголовок"
            fullWidth
            margin="normal"
            multiline
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {datePicker?.date ? (
            <>
              <div style={{ display: 'flex', marginTop: '1em' }}>
                <BasicDatePicker id={datePicker.id} required label={datePicker?.lable} value={date || null} error={error} onChange={setDate} />
              </div>
              {error ? (
                <Alert severity="error" style={{ width: '100%', marginBottom: '-2em', marginTop: '3em' }}>
                  <AlertTitle>Заповніть поля</AlertTitle>
                </Alert>
              ) : null}
            </>
          ) : null}
        </div>
        <div className="boxFooter">
          <div style={{ display: 'flex' }}>
            <Button
              color="primary"
              variant="contained"
              disabled={error}
              onClick={() => {
                if (!error && value && date) {
                  handleConfirm({ title: value, date });
                  setError(false);
                } else if (!error && value && !datePicker?.lable) {
                  handleConfirm(value);
                  setError(false);
                }
              }}
            >
              підтвердити
            </Button>
          </div>
          <Button variant="contained" onClick={handleClose} style={{ minWidth: '118px', height: '36px' }}>
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
