import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import { EditDocument, Login } from './pages';
import { AppStore } from './store/applicationState';
import { GetBlocks } from './store/blocks/actions';
import { ConfigurationsActions, GetConfigurations, GetSites } from './store/configurations/actions';
import { GetMainBlocks } from './store/main/actions';
import { GetConfig } from './store/officialDoc/actions';
import { GetBlock } from './store/officialDocBlocks/actions';
import { GetRubrics } from './store/rubrics/actions';
import { setAuth, setUser } from './store/user/actions';
import { checkToken } from './utils/checkToken';
import { Box, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import { ROUTES } from './utils/routes';
import { OfficialDoc } from './components/OfficialDoc/OfficialDoc';
import { ListMainBlocks } from './components/Main/ListMainBlocks';
import { Normativka } from './components/Normativka';
import { ListAuthors } from './components/Authors/ListAuthors';
import { ListBlocks } from './components/Blocks/ListBlocks';
import { Configurator } from './components/Configurator';
import { ListFactoids } from './components/Factoids/ListFactoids';
import { PublicationCart } from './components/PublicationCart/PublicationCart';
import { MainWorkspace } from './components/MainWorkspace';
import { CreateConsultations, EditConsultation, ListConsultations } from './pages/consultations';
import { CreateForm, EditForm, ListForms } from './pages/forms';
import { CreateVideoPage, EditVideo, ListVideo } from './pages/video';
import { RedirectLaw } from './pages/RedirectLaw';
import { CreateCatalogues, EditCatalog, ListCatalogues } from './pages/catalogues';
import { CataloguesSelections } from './components/Selections/ListSelections';
import { GetSelections } from './store/selections/actions';
import { WidgetsWorkSpace } from './components/Widgets/WidgetsWorkSpace';
import { QuickAccess } from './pages/widgets/QuickAccess';
import { Universal } from './pages/widgets/Universal';
import { Widgets } from './pages/widgets/Widgets';
import { RatingsList } from './pages/Ratings/RatingsList';
import { RatingDetails } from './pages/Ratings/RatingDetails';
import { OpenPublicationsList } from './pages/publications';
import { Calendar } from './pages/calendar/Calendar';
import { CreateCalendar } from './pages/calendar/CreateCalendar';
import { EditCalendar } from './pages/calendar/EditCalendar';
import { CoppyEvent } from './pages/calendar/CoppyEvent';
import { CoppyMonthMain, EditMonthMain, CreateMonthMain, MonthMain } from './pages/monthMain';
import {
  CalculatorsService,
  CreateService,
  EditService,
  ListServices,
  MainService,
  NavigatorsService,
} from './pages/services';
import { ServiceWorkspace } from './components/Services';

const theme = createTheme({
  palette: {
    primary: { main: '#3f51b5' },
  },
});

function App() {
  const [isLoading, setIsLoading] = React.useState(true);
  const { User, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (window.location.href.includes('document')) {
      dispatch(ConfigurationsActions.setSiteId(6));
    }
  }, [dispatch]);

  React.useEffect(() => {
    const callBack = (isOk: boolean) => {
      dispatch(setAuth(isOk));
      setIsLoading(false);
    };

    (async () => {
      setIsLoading(true);
      const tokenData = await checkToken();
      if (tokenData.success) {
        dispatch(
          setUser({
            cat_id: tokenData.cat_id!,
            exp: tokenData.exp!,
            role: tokenData.role!,
            fio: tokenData.fio!,
            token: tokenData.token!,
            type: tokenData.type!,
            username: tokenData.username!,
            iat: tokenData.iat!,
            id: tokenData.id!,
            rights: tokenData.rights!,
          })
        );
        callBack(true);
      } else {
        setIsLoading(false);
      }
    })();
  }, [dispatch]);

  React.useEffect(() => {
    if (User.isAuth) {
      dispatch(GetSites.request({}));
    }
  }, [User.isAuth, dispatch]);

  React.useEffect(() => {
    if (Configurations.siteId !== 0 && Configurations.siteId < 6) {
      dispatch(GetConfigurations.request({ id: Configurations.siteId }));
      dispatch(GetRubrics.request({ id: Configurations.siteId }));
      dispatch(GetBlocks.request({ id: Configurations.siteId }));
      dispatch(GetSelections.request({ id: Configurations.siteId }));
      dispatch(GetMainBlocks.request({ id: Configurations.siteId }));
      dispatch(GetBlock.request({ id: Configurations.siteId }));
    }
    if (Configurations.siteId === 6) {
      dispatch(GetConfig.request({}));
    }
  }, [Configurations.siteId, dispatch]);

  if (isLoading)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  if (!User.isAuth) {
    return <Login />;
  }

  if (Configurations.siteId === 6) {
    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Box display={'flex'}>
            <CssBaseline />
            <Header open={false} />
            <Box component='main' sx={{ flexGrow: 1, p: 0, background: 'rgb(241, 243, 246)', minHeight: '100vh' }}>
              <Paper sx={{ m: '80px 16px 16px', p: 2 }} style={{ minHeight: 'calc(100vh - 96px)' }}>
                <Routes>
                  <Route index element={<OfficialDoc />} />
                  <Route path={`${ROUTES.document}/:id`} element={<EditDocument />} />
                </Routes>
              </Paper>
            </Box>
          </Box>
        </ThemeProvider>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Box display={'flex'}>
          <CssBaseline />
          <Header />
          <Sidebar />
          <Box component='main' sx={{ flexGrow: 1, p: 0, background: 'rgb(241, 243, 246)', minHeight: '100vh' }}>
            <Paper sx={{ m: '80px 16px 16px', p: 2 }} style={{ minHeight: 'calc(100vh - 96px)' }}>
              <Routes>
                <Route path={ROUTES.main} element={<ListMainBlocks />} />
                <Route path={ROUTES.consultations}>
                  <Route index element={<MainWorkspace element={<ListConsultations />} />} />
                  <Route path='list' element={<MainWorkspace element={<ListConsultations />} />} />
                  <Route path='list/new' element={<MainWorkspace element={<CreateConsultations />} />} />
                  <Route path='list/:id' element={<MainWorkspace element={<EditConsultation />} />} />
                </Route>
                <Route path={ROUTES.monthMain}>
                  <Route index element={<MonthMain />} />
                  <Route path='new' element={<CreateMonthMain />} />
                  <Route path='edit/:id' element={<EditMonthMain />} />
                  <Route path='coppy/:id' element={<CoppyMonthMain />} />
                </Route>
                <Route path={ROUTES.calendar}>
                  <Route index element={<Calendar />} />
                  <Route path='new' element={<CreateCalendar />} />
                  <Route path='edit/:id' element={<EditCalendar />} />
                  <Route path='coppy/:id' element={<CoppyEvent />} />
                </Route>
                <Route path={ROUTES.catalogues}>
                  <Route index element={<MainWorkspace element={<ListCatalogues />} />} />
                  <Route path='list' element={<MainWorkspace element={<ListCatalogues />} />} />
                  <Route path='selection' element={<MainWorkspace element={<CataloguesSelections />} />} />
                  <Route path='list/new' element={<MainWorkspace element={<CreateCatalogues />} />} />
                  <Route path='list/:id' element={<MainWorkspace element={<EditCatalog />} />} />
                </Route>
                <Route path={ROUTES.forms}>
                  <Route index element={<MainWorkspace element={<ListForms />} />} />
                  <Route path='list' element={<MainWorkspace element={<ListForms />} />} />
                  <Route path='list/new' element={<MainWorkspace element={<CreateForm />} />} />
                  <Route path='list/:id' element={<MainWorkspace element={<EditForm />} />} />
                </Route>
                <Route path={ROUTES.video}>
                  <Route index element={<MainWorkspace element={<ListVideo />} />} />
                  <Route path='list' element={<MainWorkspace element={<ListVideo />} />} />
                  <Route path='list/new' element={<MainWorkspace element={<CreateVideoPage />} />} />
                  <Route path='list/:id' element={<MainWorkspace element={<EditVideo />} />} />
                </Route>
                <Route path={ROUTES.services}>
                  <Route index element={<ServiceWorkspace element={<ListServices />} />} />
                  <Route path='list' element={<ServiceWorkspace element={<ListServices />} />} />
                  <Route path='list/new' element={<ServiceWorkspace element={<CreateService />} />} />
                  <Route path='list/:id' element={<ServiceWorkspace element={<EditService />} />} />
                  <Route path='main' element={<ServiceWorkspace element={<MainService />} />} />
                  <Route path='calculators' element={<ServiceWorkspace element={<CalculatorsService />} />} />
                  <Route path='navigators' element={<ServiceWorkspace element={<NavigatorsService />} />} />
                </Route>
                <Route path={ROUTES.widgets}>
                  <Route index element={<WidgetsWorkSpace element={<Widgets />} />} />
                  <Route path='free-acces' element={<WidgetsWorkSpace element={<QuickAccess />} />} />
                  <Route path='universal' element={<WidgetsWorkSpace element={<Universal />} />} />
                </Route>
                <Route path={ROUTES.ratings}>
                  <Route index element={<RatingsList />} />
                  <Route path='list' element={<RatingsList />} />
                  <Route path='list/:id' element={<RatingDetails />} />
                </Route>
                <Route path={ROUTES.npd} element={<Normativka />} />
                <Route path={ROUTES.authors} element={<ListAuthors />} />
                <Route path={ROUTES.blocks} element={<ListBlocks />} />
                <Route path={ROUTES.configurator} element={<Configurator />} />
                <Route path={ROUTES.publications} element={<OpenPublicationsList />} />
                <Route path={ROUTES.factoids} element={<ListFactoids />} />
                <Route path={ROUTES.archive} element={<PublicationCart />} />
                <Route path='/law/:id' element={<RedirectLaw />} />
                <Route path='/laws/:id' element={<RedirectLaw />} />
              </Routes>
            </Paper>
          </Box>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
