import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { SelectSearch } from '../../components/ui';
import CircularProgress from '@mui/material/CircularProgress';
import { IService } from '../../store/services/types';
import { GetServiceBlocks, GetServices } from '../../store/services/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';

interface Column {
  id: 'name' | 'isPublic' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Заголовок', align: 'left' },
  {
    id: 'edit',
    label: 'Видалити',
    align: 'center',
    width: '100px',
  },
];

interface Props {
  data?: IService[] | null;
  handleMove: (values: { id: number; position: number }[], callBack: () => void) => void;
  handleRemove: (id: number, callBack: () => void) => void;
}

export const ServiceChoice: React.FC<Props> = ({ data, handleMove, handleRemove }) => {
  const [loading, setLoading] = React.useState(false);
  const [serviceId, setServiceId] = React.useState('');
  const [deleteId, setDeleteId] = React.useState(0);
  const [options, setOptions] = React.useState<{ value: string; label: string }[]>([]);
  const [draggIndex, setDraggIndex] = React.useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = React.useState<number | null>(null);

  const { Configurations, Services } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();


  React.useEffect(() => {
    if (Configurations.siteId) {
      dispatch(GetServices.request({ data: { siteId: Configurations.siteId } }));
      dispatch(GetServiceBlocks.request({ siteId: Configurations.siteId }));
    }
  }, [dispatch, Configurations.siteId]);

  React.useEffect(() => {
    if (Services.services) {
      const options = [] as { value: string; label: string }[];
      // eslint-disable-next-line
      const selected = data?.reduce((acum, item) => (acum.push(item.id), acum), [] as number[]);
      Services.services.forEach((it) => {
        if (selected?.includes(it.id)) return null;
        options.push({ value: String(it.id), label: it.title });
      });
      setOptions(options);
    }
  }, [Services.services, data]);

  const onAdd = () => {
    if (!serviceId) return null;
    setLoading(true);
    const ids = data?.map((item) => item.id) || [];
    const resp = [Number(serviceId), ...ids].map((item, idx) => ({ id: item, position: idx }));
    handleMove(resp, () => {
      setServiceId('');
      setLoading(false);
    });
  };

  const onRemove = (id: number) => () => {
    setDeleteId(id);
    handleRemove(id, () => setDeleteId(0));
  };

  const handleDraggEnd = () => {
    if (data && draggIndex !== null && hoverIndex !== null) {
      const arr = [...data];
      const item = arr[draggIndex];
      arr.splice(draggIndex, 1);
      arr.splice(hoverIndex, 0, item);

      const resp = arr.map((item, idx) => ({ id: item.id, position: idx }));

      setLoading(true);

      handleMove(resp, () => {
        setDraggIndex(null);
        setHoverIndex(null);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <Box >
        <SelectSearch
          title='Сервіси'
          value={serviceId}
          
          options={options}
          onChange={setServiceId}
        />
        <Button
          disabled={loading || !serviceId}
          
          style={{ marginRight: '20px' }}
          variant='contained'
          color='primary'
          onClick={onAdd}
        >
          {loading ? <CircularProgress color='inherit' size={24} /> : 'Додати'}
        </Button>
      </Box>
      <TableContainer >
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ width: '100%' }}>
            {data?.map((row, i) => {
              return (
                <TableRow
                  style={{
                    cursor: 'grab',
                    backgroundColor: i === hoverIndex ? 'lightgrey' : '',
                  }}
                  hover
                  draggable={!loading}
                  onDragOver={() => setHoverIndex(i)}
                  onDragEnd={() => handleDraggEnd()}
                  onDrag={() => !draggIndex && setDraggIndex(i)}
                  key={`${i}-${row.id}`}
                >
                  <TableCell align='left'>{row.title}</TableCell>
                  <TableCell align='center'>
                    <IconButton disabled={!!deleteId} aria-label='edit' onClick={onRemove(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
