import React from 'react';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { useOutsideClick } from '../../hooks/useOutsideClick';

type TypeOptions = { id: number; name: string; children?: TypeOptions[] | undefined | null };

type ListItemProps = {
  option: TypeOptions;
  level?: number;
  setValue: (value: string) => void;
};

const ListItem: React.FC<ListItemProps> = ({ option, setValue, level = 0 }) => {
  const [expend, setExpand] = React.useState(0);

  if (!option) return null;
  if (option.id === 0) {
    return <MenuItem onClick={() => setValue(String(option.id))}>{option.name}</MenuItem>;
  }
  if (!option.children) {
    return (
      <MenuItem onClick={() => setValue(String(option.id))}>
        <div style={{ display: 'flex', marginRight: '5px' }}>
          {Array(level)
            .fill(0)
            .map(() => '-')
            .join('')}
        </div>
        {option.name}
      </MenuItem>
    );
  }

  return (
    <MenuList style={{ marginLeft: '10px', padding: '0' }}>
      <MenuItem style={{ marginLeft: '10px', padding: '0' }}>
        <div onClick={() => setExpand(expend ? 0 : option.id)}>
          <div style={{ display: 'flex' }}>
            {Array(level)
              .fill(0)
              .map(() => '-')
              .join('')}
          </div>
          <div>{option.name}</div>
        </div>
      </MenuItem>
      <MenuList>
        {option.children && option.children.map((it) => it && <ListItem key={it.id} option={it} setValue={setValue} level={level + 1} />)}
      </MenuList>
    </MenuList>
  );
};

type SelectRubricsProps = {
  title?: string;
  value?: string;
  options?: TypeOptions[] | null;
  required?: boolean;
  error?: boolean;
  style?: React.CSSProperties | undefined;
  styleSelect?: React.CSSProperties | undefined;
  className?: string;
  onChange: (value: string) => void;
};

export const SelectRubrics: React.FC<SelectRubricsProps> = React.memo(
  ({ style, styleSelect, className, title, required, error, value, options, onChange }) => {
    const [search, setSearch] = React.useState('');
    const [items, setItems] = React.useState<TypeOptions[] | null | undefined>();
    const [myValue, setMyValue] = React.useState('');
    const [values, setValues] = React.useState<TypeOptions[] | null>(null);
    const [isFocus, setIsFocus] = React.useState(false);
    const { ref } = useOutsideClick(() => setIsFocus(false));

    const findChildren = React.useCallback((items?: TypeOptions[] | null) => {
      if (!items) return null;
      let arr: TypeOptions[] = [];
      for (const item of items) {
        if (item.children) {
          const children = findChildren(item.children);
          if (children) {
            arr = [...arr, ...children];
          }
        } else {
          arr.push(item);
        }
      }
      return arr;
    }, []);

    React.useEffect(() => {
      if (options) {
        const children = findChildren(options);
        setValues(children);
      }
    }, [options, findChildren]);

    React.useEffect(() => {
      if (values && value) {
        const id = Number(value);
        const item = values.find((it) => it.id === id);
        if (item) {
          setSearch(item.name);
          setMyValue(item.name);
        }
      }
    }, [values, value]);

    const handleLeaveFocus = () => {
      setIsFocus(!isFocus);
    };

    const setClass = () => {
      if (isFocus) return 'placeholderFocus';
      if (!!value) return 'placeholderInit';
    };

    const searchItem = (search: string) => {
      if (!search) {
        setMyValue(search);
        setItems(null);
        onChange('');
      }
      setSearch(search);
      if (search && options && values) {
        const items = values.filter((item) => {
          if (item.name.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
          return false;
        });
        setItems(items);
      }
    };

    return (
      <div style={style} className={clsx('selectInput', className)} ref={ref}>
        {title && (
          <div className={clsx('selectInput-title', setClass())} style={{ color: error && !isFocus ? 'red' : '' }}>
            {required ? `${title} *` : title}
          </div>
        )}
        <div
          className={clsx('wrappField', isFocus && 'wrappFieldFocus')}
          style={{ borderColor: error && !isFocus ? 'red' : '' }}
          onClick={handleLeaveFocus}
        >
          <div className={'field'}>
            {isFocus ? <input autoFocus className={'field-input'} value={search} onChange={(e) => searchItem(e.target.value)} /> : myValue}
          </div>
          <div className={clsx('field-icon', isFocus && 'field-icon-focus')} onClick={handleLeaveFocus}>
            <ArrowDropDownIcon />
          </div>
        </div>
        {isFocus ? (
          <div style={styleSelect} className={'field-select'}>
            {!items ? (
              <MenuList>
                {options?.map(
                  (it) =>
                    it && (
                      <ListItem
                        key={it.id}
                        option={it}
                        setValue={(value) => {
                          onChange(value);
                          handleLeaveFocus();
                        }}
                      />
                    )
                )}
              </MenuList>
            ) : (
              <MenuList>
                {items?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    onClick={() => {
                      const item = values?.find((it) => String(it.id) === value);
                      if (item) {
                        setSearch(item.name);
                      }
                      onChange(String(option.id));
                      setIsFocus(false);
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </div>
        ) : null}
      </div>
    );
  }
);
