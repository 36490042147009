import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  CreateEvent,
  DeleteEvent,
  GetCalendar,
  GetEventById,
  TypeDeleteEventR,
  TypeGetCalendarR,
  TypeGetEventR,
  TypeUpdateEventR,
  UpdateCalendarPositions,
  UpdateEvent,
  UpdateEventPositions,
} from './actions';
import { callApi } from '../../utils/callApi';
import ActionTypes, { ICalendar, IEvent } from './types';
import { TypeUpdatePositionsR } from '../rubrics/actions';
import { TypeCreateVideoR } from '../video/actions';
import { buildQuery } from '../../utils/buildQuery';

function* getCalendarWorker(action: ReturnType<typeof GetCalendar.request>): Generator {
  const { callBack, data } = action.payload as TypeGetCalendarR;
  let success = false;
  const query = buildQuery(data);
  try {
    let res: ICalendar[] | null = null;
    res = (yield call(callApi, {
      method: 'get',
      data,
      path: `/calendar?${query}`,
    })) as ICalendar[];

    yield put(GetCalendar.success(res));
  } catch (e) {
    success = false;
    yield put(GetCalendar.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createEventWorker(action: ReturnType<typeof CreateEvent.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateVideoR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/calendar/',
    })) as ICalendar[];
    yield put(CreateEvent.success(resp));
  } catch (e) {
    success = false;
    resp = e;
    yield put(CreateEvent.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateCalendarPositionsWorker(action: ReturnType<typeof UpdateCalendarPositions.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/calendar/calendarPositions`,
    })) as ICalendar[];
    yield put(UpdateCalendarPositions.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateCalendarPositions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateEventPositionsWorker(action: ReturnType<typeof UpdateEventPositions.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/calendar/eventPositions`,
    })) as ICalendar[];
    yield put(UpdateEventPositions.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateEventPositions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* deleteEventWorker(action: ReturnType<typeof DeleteEvent.request>): Generator {
  const { id, callBack, data } = action.payload as TypeDeleteEventR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      data,
      path: `/calendar/${id}`,
    })) as ICalendar[];
    yield put(DeleteEvent.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteEvent.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* updateEventWorker(action: ReturnType<typeof UpdateEvent.request>): Generator {
  const { data, callBack, id } = action.payload as TypeUpdateEventR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'put',
      data,
      path: '/calendar/' + id,
    })) as { calendars: ICalendar[]; current: IEvent };
    yield put(UpdateEvent.success(resp));
  } catch (e) {
    success = false;
    resp = e;
    yield put(UpdateEvent.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getEventWorker(action: ReturnType<typeof GetEventById.request>): Generator {
  const { id, callBack } = action.payload as TypeGetEventR;

  let success = true;
  let resp: IEvent | null = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: '/calendar/' + id,
    })) as IEvent;
    yield put(GetEventById.success(resp));
  } catch (error) {
    yield put(GetEventById.error(error as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CALENDAR_R, getCalendarWorker);
  yield takeEvery(ActionTypes.UPDATE_CALENDAR_POSITIONS_R, updateCalendarPositionsWorker);
  yield takeEvery(ActionTypes.UPDATE_EVENT_POSITIONS_R, updateEventPositionsWorker);
  yield takeEvery(ActionTypes.CREATE_EVENT_R, createEventWorker);
  yield takeEvery(ActionTypes.DELETE_EVENT_R, deleteEventWorker);
  yield takeEvery(ActionTypes.UPDATE_EVENT_R, updateEventWorker);
  yield takeEvery(ActionTypes.GET_EVENT_R, getEventWorker);
}

export default function* CalendarSaga() {
  yield all([fork(watchFetchRequest)]);
}
