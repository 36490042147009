import React, { useEffect } from 'react';
import { CatalogForm } from '../../components/CatalogForm/index';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { GetCatalog, TypeUpdateCatalogR, UpdateCatalog } from '../../store/catalogues/actions';
import { GetBlocks } from '../../store/blocks/actions';
import { GetMainBlocks } from '../../store/main/actions';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfigurationsActions } from '../../store/configurations/actions';

export const EditCatalog: React.FC = () => {
  const { Catalogues } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(GetCatalog.request({ id: Number(id) }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (Catalogues.current) {
      dispatch(ConfigurationsActions.setSiteId(Catalogues.current.siteId));
    }
  }, [dispatch, Catalogues]);

  const handleUpdate = (data: TypeUpdateCatalogR['data'], callBack: (err?: string) => void) => {
    if (!Catalogues.current) return null;

    dispatch(
      UpdateCatalog.request({
        data,
        id: Catalogues.current.id,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) callBack('Такий заголовок вже використовується');
            else callBack('Сталася помилка');
          } else {
            callBack();
          }
          if (success && Catalogues.current?.isPublic === 1 && data.isPublic === 0) {
            dispatch(GetBlocks.request({ id: Catalogues.current.siteId }));
            dispatch(GetMainBlocks.request({ id: Catalogues.current.siteId }));
          }
        },
      })
    );
  };

  if (!Catalogues.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <CatalogForm
      type='edit'
      data={Catalogues.current}
      handleSubmit={handleUpdate}
      handleCancel={() => navigate(ROUTES.routeChange('list'))}
    />
  );
};
