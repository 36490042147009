import React from 'react';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { useOutsideClick } from '../../hooks/useOutsideClick';

type PropsType = {
  title?: string;
  value?: string;
  required?: boolean;
  options?: { value: string; label: string }[];
  error?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string;
  onChange: (value: string) => void;
};

export const SelectSearch: React.FC<PropsType> = React.memo(({ style, className, title, required, error, value, options, onChange }) => {
  const [search, setSearch] = React.useState('');
  const [myValue, setMyValue] = React.useState('');
  const [items, setItems] = React.useState<{ value: string; label: string }[] | undefined>([]);
  const [isFocus, setIsFocus] = React.useState(false);
  const { ref } = useOutsideClick(() => setIsFocus(false));

  React.useEffect(() => {
    if (options) {
      setItems(options);
    }
  }, [options]);

  React.useEffect(() => {
    if (options && value) {
      const item = options.find((it) => it.value === value);
      if (item) {
        setSearch(item.label);
        setMyValue(item.label);
      } else {
        setSearch(value);
        setMyValue(value);
      }
    } else {
      setSearch('');
      setMyValue('');
    }
  }, [options, value]);

  const handleLeaveFocus = () => {
    setIsFocus(!isFocus);
  };

  const setClass = () => {
    if (isFocus) return 'placeholderFocus';
    if (!!value) return 'placeholderInit';
  };

  const searchUser = (search: string) => {
    if (!search) {
      onChange(search);
      setMyValue(search);
    }
    setSearch(search);
    if (options) {
      const items = options
        .filter((item) => {
          if (item.label.toLowerCase().includes(search.toLowerCase())) {
            return true;
          }
          return false;
        })
        .sort((a) => (a.label.toLowerCase().includes(search.toLowerCase()) ? -1 : 1));
      setItems(items);
    }
  };

  return (
    <div style={style} className={clsx('selectInput', className)} ref={ref}>
      {title && (
        <div className={clsx('selectInput-title', setClass())} style={{ color: error && !isFocus ? 'red' : '' }}>
          {required ? `${title} *` : title}
        </div>
      )}
      <div
        className={clsx('wrappField', isFocus && 'wrappFieldFocus')}
        style={{ borderColor: error && !isFocus ? 'red' : '' }}
        onClick={() => setIsFocus(true)}
      >
        <div className={'field'}>
          {isFocus ? <input autoFocus className={'field-input'} value={search} onChange={(e) => searchUser(e.target.value)} /> : myValue}
        </div>
        <div className={clsx('field-icon', isFocus && 'field-icon-focus')} onClick={handleLeaveFocus}>
          <ArrowDropDownIcon />
        </div>
      </div>
      {isFocus ? (
        <div className={'field-select'}>
          <MenuList>
            {items?.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() => {
                  const item = options?.find((it) => it.value === value);
                  if (item) {
                    setSearch(item.label);
                  }
                  onChange(option.value);
                  setIsFocus(false);
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </div>
      ) : null}
    </div>
  );
});
