import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TCataloguesState, ICatalog } from './types';

export const setCatalogues = (payload: TCataloguesState['data']) =>
  action(ActionTypes.SET_CATALOGUES, payload);
export const resetCatalogues = () => action(ActionTypes.RESET_CATALOGUES, {});

const GetCataloguesAction = new Actions('GET_CATALOGUES', ActionTypes);
export interface TypeGetCataloguesR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}
export const GetCatalogues = {
  request: (payload: TypeGetCataloguesR) => GetCataloguesAction.request(payload),
  success: (payload: TCataloguesState['data']) => GetCataloguesAction.success(payload || {}),
  error: (message: string) => GetCataloguesAction.error(message),
};

const SearchCataloguesAction = new Actions('SEARCH_CATALOGUES', ActionTypes);
export interface TypeSearchCataloguesR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}
export const SearchCatalogues = {
  request: (payload: TypeSearchCataloguesR) => SearchCataloguesAction.request(payload),
  success: (payload: ICatalog[]) => SearchCataloguesAction.success(payload || {}),
  error: (message: string) => SearchCataloguesAction.error(message),
};

const CreateCatalogAction = new Actions('CREATE_CATALOG', ActionTypes);
export interface TypeCreateCatalogR extends TDefRequest {
  data: {
    rubricId: number;
    siteId: number;
    rubricsIds: number[];
    title: string;
    body: string;
    description?: string;
    publication_at?: Date | null;
    isPublic: number;
    searchTags: string;
    is_in_cart: number;
  };
}
export const CreateCatalog = {
  request: (payload: TypeCreateCatalogR) => CreateCatalogAction.request(payload),
  success: (payload: ICatalog) => CreateCatalogAction.success(payload || {}),
  error: (message: string) => CreateCatalogAction.error(message),
};

const UpdateCatalogAction = new Actions('UPDATE_CATALOG', ActionTypes);
export interface TypeUpdateCatalogR extends TDefRequest {
  data: {
    rubricId: number;
    siteId: number; 
    rubricsIds: number[];
    title: string;
    body: string;
    description?: string;
    isPublic: number;
    searchTags: string;
    publication_at?: Date | null;
    is_in_cart: number;
  };
  id: number;
}
export const UpdateCatalog = {
  request: (payload: TypeUpdateCatalogR) => UpdateCatalogAction.request(payload),
  success: (payload: ICatalog) => UpdateCatalogAction.success(payload || {}),
  error: (message: string) => UpdateCatalogAction.error(message),
};

const DeleteCatalogAction = new Actions('DELETE_CATALOG', ActionTypes);
export interface TypeDeleteCatalogR extends TDefRequest {
  id: number;
}
export const DeleteCatalog = {
  request: (payload: TypeDeleteCatalogR) => DeleteCatalogAction.request(payload),
  success: (payload: ICatalog) => DeleteCatalogAction.success(payload || {}),
  error: (message: string) => DeleteCatalogAction.error(message),
};

const GetCatalogAction = new Actions('GET_CATALOG', ActionTypes);

export interface TypeGetCatalogR extends TDefRequest {
  id: number;
}
export const GetCatalog = {
  request: (payload: TypeGetCatalogR) => GetCatalogAction.request(payload),
  success: (payload: ICatalog) => GetCatalogAction.success(payload || {}),
  error: (message: string) => GetCatalogAction.error(message),
};
