import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { BasicDatePicker, SelectSearch } from '../ui';
import { Alert, AlertTitle } from '@mui/lab';
import moment from 'moment';
import { GetDocsStatistic } from '../../store/officialDoc/actions';
import { GetEditorsSelect } from '../../store/editors/actions';

type PropsType = {};

export const Statistics: React.FC<PropsType> = () => {
  const [dateFrom, setDateFrom] = React.useState<Date | null>(new Date(moment().toString()));
  const [dateTo, setDateTo] = React.useState<Date | null>(new Date(moment().toString()));
  const [editor, setEditor] = React.useState<string>('');
  const [type, setType] = React.useState<string>('1');
  const [errors, setErrors] = React.useState('');
  const dispatch = useDispatch();
  const { Editors, OfficialDoc } = useSelector((store: AppStore) => store);

  React.useEffect(() => {
    dispatch(
      GetEditorsSelect.request({
        data: {},
        callBack: (success) => {
          if (!success) {
            setErrors('Помилка отримання даних!');
            const timeId = setTimeout(() => {
              setErrors('');
              clearTimeout(timeId);
            }, 1500);
          }
        },
      })
    );
  }, [dispatch]);

  const onSubmit = () => {
    setErrors('');
    if (dateTo && new Date(dateFrom || '') > new Date(dateTo || '')) {
      return setErrors('Дата закінчення має бути більша за дату старту');
    }

    dispatch(
      GetDocsStatistic.request({
        data: {
          dateFrom,
          dateTo,
          editor,
          type,
        },
        callBack: (success) => {
          console.log(success);
        },
      })
    );
  };

  return (
    <>
      <Box >
        <div className='row gx-3 gy-2 align-items-center'>
          <div className='col-sm-2'>
            <BasicDatePicker
              label='Дата з'
              value={dateFrom}
              onChange={(value) => {
                setDateFrom(value);
              }}
            />
          </div>
          <div className='col-sm-2'>
            <BasicDatePicker
              label='Дата по'
              value={dateTo}
              onChange={(value) => {
                setDateTo(value);
              }}
            />
          </div>
          <div className='col-sm-2'>
            <SelectSearch
              title='Предмет пошуку'
              value={type}
              
              style={{ width: 'auto', margin: '0 1rem' }}
              options={[
                { value: '1', label: 'Редакція документа' },
                { value: '2', label: 'Група документів' },
              ]}
              onChange={setType}
            />
          </div>
          <div className='col-sm-2'>
            <SelectSearch
              title='Редактор'
              value={editor}
              
              style={{ width: 'auto', margin: '0 1rem' }}
              options={[{ value: '0', label: 'Усі' }].concat(
                Editors.selectList?.map((it: { id: number; fio: string }) => ({
                  value: String(it.id),
                  label: it.fio,
                })) || []
              )}
              onChange={setEditor}
            />
          </div>
          <div className='col-auto'>
            <Button variant='contained' color='primary' onClick={onSubmit}>
              Пошук
            </Button>
          </div>
        </div>

        <div  style={{ width: '210px' }}></div>
        <div  style={{ width: '210px' }}></div>

        {errors ? (
          <Alert severity='error' style={{ width: '100%', marginTop: '10px', position: 'absolute' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </Box>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-4 m-2'>
            {OfficialDoc?.statistics?.editor && (
              <div className='rounded'>
                <h2>Редактор: {OfficialDoc?.statistics?.editor.author.fio}</h2>
                <p>
                  Створив за даний період <b>{OfficialDoc?.statistics?.editor.count} редакцій</b>
                </p>
              </div>
            )}
            {(OfficialDoc?.statistics?.redactions || OfficialDoc?.statistics?.redactions === 0) && (
              <div className='rounded'>
                <p>
                  За даний період створено <b>{OfficialDoc?.statistics?.redactions} редакцій</b>
                </p>
              </div>
            )}
            {(OfficialDoc?.statistics?.groups || OfficialDoc?.statistics?.groups === 0) && (
              <div className='rounded'>
                <p>
                  За даний період створено <b>{OfficialDoc?.statistics?.groups} груп</b>
                </p>
              </div>
            )}
            {(!OfficialDoc?.statistics || false) && <div className='rounded'>Нічого не знайдено</div>}
          </div>
        </div>
      </div>
    </>
  );
};
