import { Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainMonthTree } from '../../components/MainInMonth/MainMonthTree';
import { ROUTES } from '../../utils/routes';
import { AppStore } from '../../store/applicationState';
import { GetMainInMonth } from '../../store/mainInMonth/actions';

interface PropsType {}

export const MonthMain: React.FC<PropsType> = () => {
  const { Configurations, MainInMonth } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  


  useEffect(() => {
    dispatch(
      GetMainInMonth.request({
        data: {
          site_id: Configurations.siteId,
        },
      })
    );
  }, [dispatch, Configurations]);

  const handleChangeRoute =
    () =>
    () => {
      window.open(ROUTES.routeChange(`new?site_id=${Configurations.siteId}`));
    };

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <Box>
          <Button
            style={{ margin: '0 0 10px 0' }}
            variant='contained'
            color='primary'
            onClick={handleChangeRoute()}
          >
            Створити головне
          </Button>
        </Box>
      </Box>
      <MainMonthTree
        children={MainInMonth.mainInMonth}
        cards={MainInMonth.mainInMonth}
        categoryId={1}
        setCards={() => {}}
      />
    </>
  );
};
