import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IRatings, TRatingsState } from './types';
import { TypeSearchData } from '../../hooks/useSearchAny';

export const setRatings = (payload: TRatingsState['data']) => action(ActionTypes.SET_RATINGS, payload);
export const resetRatings = () => action(ActionTypes.RESET_RATINGS, {});
const GetRatingsAction = new Actions('GET_RATINGS', ActionTypes);

export interface TypeGetRatingsR extends TDefRequest {
  data: {
    siteId?: number;
    id?: number;
    date_at?: string;
    date_to?: string;
    author?: string;
    type?: string;
    rate?: number;
    page?: string;
    pub_id?: number;
  };
}

export const GetRatings = {
  request: (payload: TypeGetRatingsR) => GetRatingsAction.request(payload),
  success: (payload: TRatingsState['data']) => GetRatingsAction.success(payload || {}),
  error: (message: string) => GetRatingsAction.error(message),
};

const SearchRatingsAction = new Actions('SEARCH_RATINGS', ActionTypes);

export interface TypeSearchRatingsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    limit?: number;
    date_at?: string;
    date_to?: string;
    author?: string;
    type?: string;
    rate?: number;
    pub_id?: number;
    page?: string;
  };
}

export interface TypeSearchRatingsS {
  ratings: IRatings[];
  pages: number;
}

export const SearchRatings = {
  request: (payload: TypeSearchRatingsR | TypeSearchData) => SearchRatingsAction.request(payload),
  success: (payload: TypeSearchRatingsS | null) => SearchRatingsAction.success(payload || {}),
  error: (message: string) => SearchRatingsAction.error(message),
};

const GetSearchCommentatorsAction = new Actions('GET_SEARCH_COMMENTATORS', ActionTypes);

export interface TypeGetSearchCommentatorsR extends TDefRequest {
  data: { id?: number; fio?: string; limit?: number };
}

export const GetSearchCommentators = {
  request: (payload: TypeGetSearchCommentatorsR) => GetSearchCommentatorsAction.request(payload),
  success: (payload: { id: number; fio: string }[]) => GetSearchCommentatorsAction.success(payload || {}),
  error: (message: string) => GetSearchCommentatorsAction.error(message),
};
