export interface TMainMonthState {
  error: string | null;
  loading: boolean;
  mainInMonth: IMainInMonthYears[] | null;
  current: null | {
    id: number;
    month: number;
    title: string;
    url: string;
    is_public: boolean;
    year: number;
    templates: {
      title?: string;
      month_main_detail: { description?: string; category_id?: number; item_id?: number; author_id?: number };
      position: number;
      is_public: number;
    }[];
  };
}
export interface IMainInMonthYears {
  id: number;
  site_id: number;
  position: number;
  title: string;
  url: string;
  month: number;
  year: number;
  is_public: 1 | 0;
  children: IMainInMonthYears[] | null;
}

export interface ITemplate {
  title?: string;
  month_main_detail: {
    description?: string;
    category_id?: number;
    item_id?: number;
    author_id?: number;
    id?: number;
    title?: string;
  };
  position: number;
  is_public: number;
  id: number;
}

enum ActionTypes {
  GET_MAIN_IN_MONTH_R = '@@mainInMonth/GET_MAIN_IN_MONTH_R',
  GET_MAIN_IN_MONTH_S = '@@mainInMonth/GET_MAIN_IN_MONTH_S',
  GET_MAIN_IN_MONTH_E = '@@mainInMonth/GET_MAIN_IN_MONTH_E',

  GET_ONE_MAIN_IN_MONTH_R = '@@mainInMonth/GET_ONE_MAIN_IN_MONTH_R',
  GET_ONE_MAIN_IN_MONTH_S = '@@mainInMonth/GET_ONE_MAIN_IN_MONTH_S',
  GET_ONE_MAIN_IN_MONTH_E = '@@mainInMonth/GET_ONE_MAIN_IN_MONTH_E',

  CREATE_MAIN_IN_MONTH_R = '@@mainInMonth/CREATE_MAIN_IN_MONTH_R',
  CREATE_MAIN_IN_MONTH_S = '@@mainInMonth/CREATE_MAIN_IN_MONTH_S',
  CREATE_MAIN_IN_MONTH_E = '@@mainInMonth/CREATE_MAIN_IN_MONTH_E',

  DELETE_MAIN_IN_MONTH_R = '@@mainInMonth/DELETE_MAIN_IN_MONTH_R',
  DELETE_MAIN_IN_MONTH_S = '@@mainInMonth/DELETE_MAIN_IN_MONTH_S',
  DELETE_MAIN_IN_MONTH_E = '@@mainInMonth/DELETE_MAIN_IN_MONTH_E',

  UPDATE_MAIN_IN_MONTH_R = '@@mainInMonth/UPDATE_MAIN_IN_MONTH_R',
  UPDATE_MAIN_IN_MONTH_S = '@@mainInMonth/UPDATE_MAIN_IN_MONTH_S',
  UPDATE_MAIN_IN_MONTH_E = '@@mainInMonth/UPDATE_MAIN_IN_MONTH_E',

  UPDATE_TEMPLATE_POSITION_R = '@@mainInMonth/UPDATE_TEMPLATE_POSITION_R',
  UPDATE_TEMPLATE_POSITION_S = '@@mainInMonth/UPDATE_TEMPLATE_POSITION_S',
  UPDATE_TEMPLATE_POSITION_E = '@@mainInMonth/UPDATE_TEMPLATE_POSITION_E',
}

export default ActionTypes;
