import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearch } from '../../hooks/useSearch';
import { DeleteConsultation, GetConsultations } from '../../store/consultations/actions';
import { DeleteForms, GetForms } from '../../store/forms/actions';
import { WarningModal } from '../modals';
import { DeleteVideo, GetVideo } from '../../store/video/actions';
import { GetCart, SearchCart, resetCart } from '../../store/cart/actions';
import { DeleteCatalog, GetCatalogues } from '../../store/catalogues/actions';

interface Column {
  id: 'id' | 'name' | 'category' | 'open_publication_at' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '10%' },
  { id: 'name', label: 'Заголовок', align: 'left' },
  { id: 'category', label: 'Рубрика', align: 'left' },
  { id: 'open_publication_at', label: 'Розділ', align: 'center' },
  {
    id: 'delete',
    label: '',
    align: 'center',
    width: '25%',
  },
];

type PropsType = {};

export const PublicationCart: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const { Cart, Configurations } = useSelector((store: AppStore) => store);
  const [publications, setPublications] = useState<any[] | null>([]);

  const dispatch = useDispatch();

  const { loading, searchType, searchId, searchTitle, handleSearchId, handleSearchTitle, handleSearchType } = useSearch(
    {
      SearchData: SearchCart,
      ResetData: resetCart,
    }
  );

  useEffect(() => {
    setPublications(Cart.publications);
  }, [Cart]);

  const restorePublication = (id:number) => {
    const publication = publications?.find((el) => el.id === id) || null;
  
    if (!publication) return;
  
    const actions : any = {
      "Консультація": {
        deleteAction: DeleteConsultation,
        getRequest: GetConsultations,
      },
      "Форма": {
        deleteAction: DeleteForms,
        getRequest: GetForms,
      },
      "Відео": {
        deleteAction: DeleteVideo,
        getRequest: GetVideo,
      },
      "Довідник":{
        deleteAction:DeleteCatalog,
        getRequest:GetCatalogues
      }
    };
  
    const { deleteAction, getRequest } = actions[publication.type];
  
    if (deleteAction && getRequest) {
      dispatch(deleteAction.request({
        id,
        callBack: (success:boolean) => {
          if (success) {
            dispatch(
              GetCart.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
            dispatch(
              getRequest.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
          }
        },
      }));
    }
  };

  return (
    <>
      <Box >
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={searchId}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleSearchId(event.target.value as string);
          }}
        />
        <TextField
          
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={searchTitle}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleSearchTitle(event.target.value as string);
          }}
        />
        <TextField
          
          size='small'
          id='outlined-basic-title'
          label='Пошук по розділу'
          variant='outlined'
          value={searchType}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleSearchType(event.target.value as string);
          }}
        />
        {loading ? <CircularProgress size={25} /> : null}
      </Box>
      <TableContainer >
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {publications?.map((row, i) => {
              return (
                <TableRow
                  style={{ maxHeight: 50 }}
                  hover
                  role='checkbox'
                  tabIndex={-1}
                  key={`${i}-${row.id}-${row.id}`}
                >
                  <TableCell align='center' >
                    {row.id}
                  </TableCell>
                  <TableCell >{row.title}</TableCell>
                  <TableCell >{row.rubricName}</TableCell>
                  <TableCell align='center' >
                    {row.type}
                  </TableCell>
                  <TableCell align='center'>
                    <Button
                      style={{ margin: '0 0 10px 0' }}
                      variant='contained'
                      color='primary'
                      onClick={() => setIsModal({ id: row.id })}
                    >
                      Відновити
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && restorePublication(isModal.id);
            setIsModal(null);
          }}
          type='restore'
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
