import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectRubrics, TextareaOutline } from '../../ui';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import Box from '@mui/material/Box';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ISubBlock, IBlock } from '../../../store/officialDocBlocks/types';
import { ActualizationBlock, DeleteSubBlock, UpdateBlock } from '../../../store/officialDocBlocks/actions';

const initBlock: ISubBlock = {
  id: 0,
  blockId: 0,
  siteId: 0,
  title: '',
  label: '',
  url: '',
  position: 1,
  viewBlockId: 0,
  isChange: true,
};
const byDefault = { id: 0, name: 'По дефолту', children: [{ id: 0, name: 'По дефолту' }] };

export interface IOptions {
  index: number | null;
  type: 'title' | 'label' | 'url' | 'position' | 'viewBlockId' | 'add' | 'delete';
  value: string;
  id?: number;
}

interface ISelectProps {
  index: number;
  value?: string;
  onChange: (props: IOptions) => void;
}

const SelectView: React.FC<ISelectProps> = React.memo(({ index, value, onChange }) => {
  const { Configurations } = useSelector((store: AppStore) => store);

  return (
    <SelectRubrics
      style={{ width: '14%', cursor: 'pointer', marginRight: '5px' }}
      styleSelect={{ minWidth: '250px' }}
      title='Вигляд'
      value={value}
      options={Configurations.configurations ? [byDefault, ...Configurations.configurations.viewsBlocks] : [byDefault]}
      onChange={(value) => onChange({ value, index, type: 'viewBlockId' })}
    />
  );
});

interface PropsType {
  block: IBlock;
  handleCancel: () => void;
}

export const BlocksMain: React.FC<PropsType> = ({ block, handleCancel }) => {
  const [name, setName] = React.useState<string>('');
  const [subBlocks, setSubBlocks] = React.useState<ISubBlock[]>([initBlock]);
  const [error, setError] = React.useState(false);

  const [draggIndex, setDraggIndex] = React.useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = React.useState<number | null>(null);

  const { OfficialDocBlocks } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();



  React.useEffect(() => {
    if (block) {
      setName(block.name);
      if (block.subBlocks?.length > 0) setSubBlocks(block.subBlocks);
    }
  }, [block]);

  const handleDraggEnd = () => {
    setSubBlocks((prev) => {
      if (prev && draggIndex !== null && hoverIndex !== null) {
        const arr = [...prev];
        const item = arr[draggIndex];
        arr.splice(draggIndex, 1);
        arr.splice(hoverIndex, 0, item);
        return arr;
      }
      return prev;
    });

    setDraggIndex(null);
    setHoverIndex(null);
  };

  const deleteSubBlock = React.useCallback(
    (id: number) => {
      dispatch(
        DeleteSubBlock.request({
          id,
        })
      );
    },
    [dispatch]
  );

  const handleChange = React.useCallback(
    ({ index, type, value, id }: IOptions) => {
      setSubBlocks((prev) => {
        let subBlocksL = [] as ISubBlock[];
        if (type === 'add') {
          subBlocksL = [...prev, { ...initBlock }];
        }
        if (type === 'delete') {
          if (id) {
            const subBlock = prev.find((sub) => sub.id === id);
            if (subBlock) {
              deleteSubBlock(subBlock.id);
            }
          }
          subBlocksL = prev?.filter((it, i) => i !== index);
        }
        if (index !== null && type !== 'delete') {
          subBlocksL = prev?.map((it, i) => {
            if (i === index) {
              const obj: any = {};
              obj[type] = value;
              return { ...it, ...obj };
            }
            return it;
          });
        }
        return subBlocksL;
      });
    },
    [deleteSubBlock]
  );

  const onSubmit = () => {
    if (!block) return null;
    if (!name || !subBlocks || subBlocks.length === 0) return setError(true);
    const blocks = subBlocks.filter((it) => {
      if (it['title'] && it['label'] && it['url']) {
        return true;
      }
      return false;
    });

    setError(false);
    dispatch(
      UpdateBlock.request({
        data: {
          name,
          subBlocks: blocks.map((it, i) => ({ ...it, position: i })),
        },
        id: block.id,
      })
    );
  };

  const actualization = () => {
    dispatch(
      ActualizationBlock.request({
        id: block.id,
      })
    );
  };

  return (
    <>
      <Box >
        <Button
          variant='contained'
          color='primary'
          disabled={OfficialDocBlocks.loading}
          onClick={actualization}
          style={{ height: '36px', margin: 0 }}
        >
          {OfficialDocBlocks.loading ? <CircularProgress size={15} /> : 'Актуалізувати'}
        </Button>
      </Box>
      <TextField
        required
        size='small'
        id='outlined-basic'
        label='Назва'
        variant='outlined'
        value={name}
        error={error && !name}
        
        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
          setName(event.target.value as string);
        }}
      />
      {subBlocks.map((it, i) => (
        <div
          key={i}
          style={{
            display: 'flex',
            width: '1000px',
            paddingTop: '20px',
            cursor: 'move',
            background: i === hoverIndex ? '#554e4e70' : '',
          }}
          draggable
          onDragOver={() => setHoverIndex(i)}
          onDragEnd={() => handleDraggEnd()}
          onDrag={() => !draggIndex && setDraggIndex(i)}
        >
          <TextField
            size='small'
            id={`${i}-${it.siteId}-outlined-basic`}
            label='Заголовок блока'
            variant='outlined'
            value={it.title}
            error={error && !it.title}
            style={{ marginRight: '20px' }}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              handleChange({ index: i, type: 'title', value: event.target.value as string });
            }}
          />
          <TextField
            size='small'
            id={`${i}-${it.siteId}-outlined-basic`}
            label='URL'
            variant='outlined'
            value={it.url}
            error={error && !it.url}
            style={{ marginRight: '20px' }}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
              handleChange({ index: i, type: 'url', value: event.target.value as string });
            }}
          />
          <TextareaOutline
            title='Доповнення'
            value={it.label}
            error={error && !it.label}
            style={{ marginRight: '20px', width: '250px' }}
            onChange={(value) => {
              handleChange({ index: i, type: 'label', value });
            }}
          />
          <SelectView index={i} value={String(it.viewBlockId)} onChange={handleChange} />
          <IconButton
            aria-label='add'
            style={{ marginRight: '10px', color: '#3f51b5' }}
            onClick={() => handleChange({ index: null, type: 'add', value: '' })}
          >
            <AddIcon />
          </IconButton>
          {subBlocks.length > 1 && (
            <>
              <IconButton
                aria-label='delete'
                style={{ marginRight: '10px', color: '#e75d5d' }}
                onClick={() => handleChange({ index: i, type: 'delete', value: String(it.id), id: it.id })}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label='arrow' style={{ color: 'silver', cursor: 'move' }}>
                <OpenWithIcon />
              </IconButton>
            </>
          )}
        </div>
      ))}
      <Box display={'flex'} mt={2} gap={2}>
        <Button variant='contained' color='primary' disabled={OfficialDocBlocks.loading} onClick={onSubmit}>
          {OfficialDocBlocks.loading ? <CircularProgress size={15} /> : 'Зберегти'}
        </Button>
        <Button variant='contained' color='inherit' onClick={handleCancel}>
          Повернутися
        </Button>
      </Box>
      {error ? (
        <Alert severity='error' style={{ width: '100%' }}>
          <AlertTitle>Заповніть поля</AlertTitle>
        </Alert>
      ) : null}
      {OfficialDocBlocks.errors ? (
        <Alert severity='error' style={{ width: '100%' }}>
          <AlertTitle>Сталася помилка</AlertTitle>
        </Alert>
      ) : null}
    </>
  );
};
