export interface TWidgetsState {
  readonly loading: boolean;
  readonly errors?: string | undefined;
  additionalWidgets: IAdditionalWidgets[] | null;
  mainWidgets: IMainWidgets[] | null;
}

export interface IAdditionalWidgets {
  id: number;
  position: number;
  title: string;
  url: string;
  is_public: 1 | 0;
  description?: string;
  type_id: number;
}

export interface IMainWidgets {
  id: number;
  position: number;
  title: string;
  link?: string;
  summa?: number;
  is_public?: 1 | 0;
  month?: string;
  eur?: number;
  usd?: number;
  exchangedate?:string;
}

enum ActionTypes {
  SET_WIDGETES = '@@widgetes/SET_WIDGETES',

  GET_WIDGETES_R = '@@widgetes/GET_WIDGETES_R',
  GET_WIDGETES_S = '@@widgetes/GET_WIDGETES_S',
  GET_WIDGETES_E = '@@widgetes/GET_WIDGETES_E',

  UPDATE_MAIN_WIDGET_R = '@@widgetes/UPDATE_MAIN_WIDGET_R',
  UPDATE_MAIN_WIDGET_S = '@@widgetes/UPDATE_MAIN_WIDGET_S',
  UPDATE_MAIN_WIDGET_E = '@@widgetes/UPDATE_MAIN_WIDGET_E',

  UPDATE_ADDITION_WIDGET_R = '@@widgetes/UPDATE_ADDITION_WIDGET_R',
  UPDATE_ADDITION_WIDGET_S = '@@widgetes/UPDATE_ADDITION_WIDGET_S',
  UPDATE_ADDITION_WIDGET_E = '@@widgetes/UPDATE_ADDITION_WIDGET_E',

  CREATE_WIDGET_R = '@@widgetes/CREATE_WIDGET_R',
  CREATE_WIDGET_S = '@@widgetes/CREATE_WIDGET_S',
  CREATE_WIDGET_E = '@@widgetes/CREATE_WIDGET_E',

  UPDATE_MAIN_WIDGET_POSITIONS_R = '@@widgetes/UPDATE_MAIN_WIDGET_POSITIONS_R',
  UPDATE_MAIN_WIDGET_POSITIONS_S = '@@widgetes/UPDATE_MAIN_WIDGET_POSITIONS_S',
  UPDATE_MAIN_WIDGET_POSITIONS_E = '@@widgetes/UPDATE_MAIN_WIDGET_POSITIONS_E',

  UPDATE_ADDITIONAL_WIDGET_POSITIONS_R = '@@widgetes/UPDATE_ADDITIONAL_WIDGET_POSITIONS_R',
  UPDATE_ADDITIONAL_WIDGET_POSITIONS_S = '@@widgetes/UPDATE_ADDITIONAL_WIDGET_POSITIONS_S',
  UPDATE_ADDITIONAL_WIDGET_POSITIONS_E = '@@widgetes/UPDATE_ADDITIONAL_WIDGET_POSITIONS_E',

  CREATE_ADDITIONAL_WIDGET_R = '@@widgetes/CREATE_ADDITIONAL_WIDGET_R',
  CREATE_ADDITIONAL_WIDGET_S = '@@widgetes/CREATE_ADDITIONAL_WIDGET_S',
  CREATE_ADDITIONAL_WIDGET_E = '@@widgetes/CREATE_ADDITIONAL_WIDGET_E',
}

export default ActionTypes;
