import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IMain, ISubBlock, ITitleIdsPairs } from './types';

const GetBlocksAction = new Actions('GET_MAIN_BLOCKS', ActionTypes);

export interface TypeGetMainBlocksR extends TDefRequest {
  id: number;
  limit?: number;
}

export const GetMainBlocks = {
  request: (payload: TypeGetMainBlocksR) => GetBlocksAction.request(payload),
  success: (payload: IMain[]) => GetBlocksAction.success(payload || {}),
  error: (message: string) => GetBlocksAction.error(message),
};

const GetFreeIdsAction = new Actions('GET_FREE_IDS', ActionTypes);

export interface TypeGetFreeIdsR extends TDefRequest {
  siteId: number;
}
export const GetFreeIds = {
  request: (payload: TypeGetFreeIdsR) => GetFreeIdsAction.request(payload),
  success: (payload: ITitleIdsPairs) => GetFreeIdsAction.success(payload || {}),
  error: (message: string) => GetFreeIdsAction.error(message),
};

const SearchFreeIdsAction = new Actions('SEARCH_FREE_IDS', ActionTypes);

export interface TypeSearchFreeIdsR extends TDefRequest {
  data: { siteId: number; categoryId: number; itemId: number };
}
export const SearchFreeIds = {
  request: (payload: TypeSearchFreeIdsR) => SearchFreeIdsAction.request(payload),
  success: (payload: ITitleIdsPairs) => SearchFreeIdsAction.success(payload || {}),
  error: (message: string) => GetFreeIdsAction.error(message),
};

const ResetItemIdAction = new Actions('RESET_ITEM_ID', ActionTypes);

export interface TypeResetItemIdR extends TDefRequest {
  id: number;
}
export const ResetItemId = {
  request: (payload: TypeResetItemIdR) => ResetItemIdAction.request(payload),
  success: () => ResetItemIdAction.success({}),
  error: (message: string) => ResetItemIdAction.error(message),
};

const CreateBlockAction = new Actions('CREATE_MAIN_BLOCK', ActionTypes);

export interface TypeCreateBlockR extends TDefRequest {
  data: {
    siteId: number;
    setdate: Date;
    subBlocks: ISubBlock[];
  };
}

export const CreateBlock = {
  request: (payload: TypeCreateBlockR) => CreateBlockAction.request(payload),
  success: (payload: IMain) => CreateBlockAction.success(payload || {}),
  error: (message: string) => CreateBlockAction.error(message),
};

const UpdateBlockAction = new Actions('UPDATE_MAIN_BLOCK', ActionTypes);

export interface TypeUpdateBlockR extends TDefRequest {
  data: {
    siteId: number;
    setdate: Date;
    subBlocks: ISubBlock[];
    isPublic: number;
  };
  id: number;
}

export const UpdateBlock = {
  request: (payload: TypeUpdateBlockR) => UpdateBlockAction.request(payload),
  success: (payload: IMain) => UpdateBlockAction.success(payload || {}),
  error: (message: string) => UpdateBlockAction.error(message),
};

const DeleteSubBlockAction = new Actions('DELETE_SUB_MAIN_BLOCK', ActionTypes);

export interface TypeDeleteSubBlockR extends TDefRequest {
  id: number;
}

export const DeleteSubBlock = {
  request: (payload: TypeDeleteSubBlockR) => DeleteSubBlockAction.request(payload),
  success: (payload: IMain) => DeleteSubBlockAction.success(payload || {}),
  error: (message: string) => DeleteSubBlockAction.error(message),
};

const DeleteBlockAction = new Actions('DELETE_MAIN_BLOCK', ActionTypes);

export interface TypeDeleteBlockR extends TDefRequest {
  id: number;
}

export const DeleteBlock = {
  request: (payload: TypeDeleteBlockR) => DeleteBlockAction.request(payload),
  success: (payload: IMain) => DeleteBlockAction.success(payload || {}),
  error: (message: string) => DeleteBlockAction.error(message),
};
