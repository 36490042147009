import { Reducer } from 'redux';
import ActionTypes, { TFormsState } from './types';

export const initialState: TFormsState = {
  data: null,
  forms: null,
  current: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TFormsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_FORMS:
      return { ...state, data: action.payload, forms: action.payload.forms, loading: false, errors: '' };

    case ActionTypes.GET_FORMS_S:
      return { ...state, forms: action.payload.forms, loading: false, errors: '', data: action.payload };

    case ActionTypes.RESET_FORMS:
      return {
        ...state,
        forms: state.data ? state.data.forms : null,
      };

    case ActionTypes.GET_FORMS_R:
    case ActionTypes.SEARCH_FORMS_R:
    case ActionTypes.CREATE_FORMS_R:
    case ActionTypes.UPDATE_FORMS_R:
    case ActionTypes.DELETE_FORMS_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.SEARCH_FORMS_S:
      return {
        ...state,
        forms: action.payload,
        loading: false,
        errors: '',
      };

    case ActionTypes.GET_FORM_R:
      return {
        ...state,
        current: null,
      };

    case ActionTypes.GET_FORM_S:
      return {
        ...state,
        loading: false,
        errors: '',
        current: action.payload,
      };

    case ActionTypes.CREATE_FORMS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, forms: [action.payload, ...state.data.forms], total: state.data.total + 1 }
          : { forms: [action.payload], total: 1 },
        forms: state.forms ? [action.payload, ...state.forms] : [action.payload],
      };

    case ActionTypes.UPDATE_FORMS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, forms: state.data.forms.map((it) => (it.id === action.payload.id ? action.payload : it)) }
          : state.data,
        forms: state.forms?.map((it) => (it.id === action.payload.id ? action.payload : it)) || null,
        current: action.payload,
      };

    case ActionTypes.DELETE_FORMS_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? {
              ...state.data,
              forms: state.data.forms.filter((it) => it.id !== action.payload.id),
              total: state.data.total - 1,
            }
          : state.data,
        forms: state.forms?.filter((it) => it.id !== action.payload.id),
      };

    case ActionTypes.GET_FORMS_E:
    case ActionTypes.SEARCH_FORMS_E:
    case ActionTypes.CREATE_FORMS_E:
    case ActionTypes.UPDATE_FORMS_E:
    case ActionTypes.DELETE_FORMS_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as FormsReducer };
