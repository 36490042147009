import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IconButton from '@mui/material/IconButton';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import DeleteIcon from '@mui/icons-material/Delete';
import { URI } from '../../config';
import { callApi } from '../../utils/callApi';

const Input = styled('input')({
  display: 'none',
});

type PropsType = {
  id: string;
  title?: string;
  value?: File | string;
  name?: string;
  required?: boolean;
  error?: boolean;
  accept?: string;
  style?: React.CSSProperties | undefined;
  className?: string;
  isDelete?: boolean;
  hint?: string;
  onChange: (file: File | string) => void;
};

export const UploadFileOfficial: React.FC<PropsType> = ({
  style,
  className,
  id,
  title,
  required,
  error,
  value,
  name,
  accept,
  isDelete,
  hint,
  onChange,
}) => {
  const [preview, setPreview] = React.useState<string>(typeof value === 'string' ? `${URI.trim()}/${value}` : '');
  const [isFocus, setIsFocus] = React.useState(false);
  const { ref } = useOutsideClick(() => setIsFocus(false));

  const fileName = typeof value !== 'string' && value ? value.name : name ? name : '';

  const setClass = () => {
    if (isFocus) return 'placeholderFocus';
    if (!!value) return 'placeholderInit';
  };

  const handleLeaveFocus = () => {
    setIsFocus(!isFocus);
  };

  const findTypeFlie = (name: string) => {
    const types = ['docx', 'doc', 'xlsx', 'xls', 'pdf'];
    const fnType = types.find((tp) => tp.includes(name.substring(name.lastIndexOf('.') + 1)));
    return fnType;
  };

  const renderPreviewImg = (file: File, callBack: (value: string) => void) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        callBack(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const renderPreviewPdf = (file: File, callBack: (value: string) => void) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result !== null) {
        const url = window.URL.createObjectURL(new Blob([reader.result], { type: 'application/pdf' }));
        callBack(url);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleChange = (file: File) => {
    if (file.type.includes('image')) {
      onChange(file);
      renderPreviewImg(file, setPreview);
    }
    if (findTypeFlie(file.name)) {
      const type = findTypeFlie(file.name);
      if (type === 'pdf') {
        onChange(file);
        renderPreviewPdf(file, setPreview);
      } else if (type && file) {
        const fd = new FormData();
        fd.append('file', file);
        callApi({
          method: 'post',
          data: fd,
          isFormData: true,
          path: '/upload/preview',
        })
          .then((path) => {
            onChange(file);
            setPreview(`${URI.trim()}${path}`);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleDelete = () => {
    onChange('');
    setPreview('');
  };

  const openPreview = () => {
    if (!findTypeFlie(fileName)) {
      const iframe = "<iframe width='100%' height='100%' src='" + preview + "'></iframe>";
      const x = window.open();
      x?.document.write(iframe);
      x?.document.close();
    } else {
      const type = findTypeFlie(fileName);
      if (type === 'pdf') window.open(preview);
      else window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${preview}`);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
      <div style={style} className={clsx('selectInput', className)} ref={ref}>
        {title && (
          <div className={clsx('selectInput-title', setClass())} style={{ color: error && !isFocus ? 'red' : '' }}>
            {required ? `${title} *` : title}
          </div>
        )}
        <label htmlFor={id}>
          <Input
            accept={accept}
            style={{ display: 'none' }}
            id={id}
            type="file"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                handleChange(e.target.files[0]);
              }
            }}
          />
          <div
            className={clsx('wrappField', isFocus && 'wrappFieldFocus')}
            style={{ borderColor: error && !isFocus ? 'red' : '', justifyContent: 'space-between' }}
            onClick={() => setIsFocus(true)}
          >
            <div className={'field'}>{fileName}</div>
            <div className={'field-icon'} onClick={handleLeaveFocus}>
              <IconButton color="default" style={{ color: isFocus ? '#3f51b5' : error ? 'red' : '' }} aria-label="upload picture" component="span">
                <UploadFileIcon />
              </IconButton>
            </div>
            {isDelete ? (
              <div className={'field-icon'} onClick={handleLeaveFocus}>
                <IconButton aria-label="delete" style={{ color: isFocus ? '#3f51b5' : error ? 'red' : '' }} onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ) : null}
          </div>
        </label>
      </div>
      <div className={'wrapHint'}>
        {preview ? (
          <div className={'wrapLink'} onClick={openPreview}>
            {fileName}
          </div>
        ) : null}
        {hint ? <div style={{ marginLeft: '10px' }}>{hint}</div> : null}
      </div>
    </div>
  );
};
