import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { TextField } from '@mui/material';
import { SelectOutline } from '../../components/ui';
import { GetWidgetes, UpdateMainWidget, UpdateMainWidgetsPosition } from '../../store/widgets/actions';
import { monthsOptions } from '../../utils/consts';

interface Column {
  id: 'name' | 'isPublic' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Назва', align: 'left', width: '10%' },
  { id: 'isPublic', label: 'Публікція/URL', align: 'center', width: '10%' },
  {
    id: 'edit',
    label: 'Значення',
    align: 'center',
    width: '10%',
  },
];



type PropsType = {};

export const Widgets: React.FC<PropsType> = () => {
  const { Widgetes, Configurations } = useSelector((store: AppStore) => store) as unknown as AppStore;
  const [mainWidgets, setMainWidgets] = useState(Widgetes.mainWidgets || []);
  const [timeout, setTimeoutState] = useState<null | NodeJS.Timeout | undefined>(null);

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(
      GetWidgetes.request({
        data: {
          site_id: Configurations.siteId,
        },
      })
    );
  }, [dispatch, Configurations]);

  useEffect(() => {
    if (!mainWidgets.length) setMainWidgets(Widgetes.mainWidgets || []);
  }, [Widgetes, mainWidgets.length]);

  const onDragEnd = (result: DropResult, event: any) => {
    if (!result.destination) {
      return;
    }

    const item = mainWidgets[result.source.index];

    const payload = [
      { id: result.draggableId, position: result.destination.index },
      { id: mainWidgets[result.destination.index]?.id, position: result.source.index },
    ];

    mainWidgets.splice(result.source.index, 1);
    mainWidgets.splice(result.destination.index, 0, item);

    dispatch(
      UpdateMainWidgetsPosition.request({
        data: {
          positions: payload,
        },
        callBack: (success, data) => {
          if (success) {
            setMainWidgets(data);
          }
        },
      })
    );
  };

  const debounce = (callback: (data: string) => void, delay: number) => {
    return (value: string) => {
      if (timeout) clearTimeout(timeout);
      setTimeoutState(null);

      setTimeoutState(
        setTimeout(() => {
          callback(value);
        }, delay)
      );
    };
  };
  // eslint-disable-next-line
  const debounceSave = React.useCallback(
    debounce((data) => {
      const parseData = JSON.parse(String(data));
      const item = mainWidgets.find((el) => el.id === parseData.id);
      const keyValue = Object.keys(parseData)[0];
      if (item) {
        dispatch(
          UpdateMainWidget.request({
            data: {
              ...item,
              [keyValue]: parseData[keyValue],
            },
            id: item.id,
          })
        );
      }
    }, 600),
    [mainWidgets, dispatch, timeout]
  );

  const handleChange = (data: any) => {
    const item = mainWidgets.find((el) => el.id === data.id);
    const keyValue = Object.keys(data)[1];
    setMainWidgets(
      mainWidgets.map((el) => {
        if (el.id === data.id) {
          return { ...el, [keyValue]: data[keyValue] };
        }
        return el;
      })
    );
    if (item) {
      debounceSave(JSON.stringify({ [keyValue]: data[keyValue], id: data.id }));
    }
  };

  return (
    <>
      <TableContainer >
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='documents-list'>
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%' }}>
                  {mainWidgets
                    .sort((a, b) => a.position - b.position)
                    ?.map((row, i) =>
                      row.title === 'Індекс інфляції' ? (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                              ref={provided.innerRef}
                            >
                              <TableCell align='left'>{row.title}</TableCell>
                              <TableCell align='center' >
                                {
                                  <TextField
                                    size='small'
                                    id='outlined-basic'
                                    label='Посилання'
                                    variant='outlined'
                                    value={row.link}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                      handleChange({ id: row.id, link: e.target.value });
                                    }}
                                  />
                                }
                              </TableCell>
                              <TableCell>
                                <div>
                                  <SelectOutline
                                    required
                                    id='outlined-basic-status'
                                    title='Оберіть місяць'
                                    value={row.month || monthsOptions[0].label}
                                    error={false}
                                    options={monthsOptions}
                                    handleChange={(value) => {
                                      handleChange({ id: row.id, month: value });
                                    }}
                                  />
                                  <TextField
                                    size='small'
                                    id='outlined-basic'
                                    label={'Число'}
                                    variant='outlined'
                                    value={row?.summa || 0}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                      handleChange({ id: row.id, summa: e.target.value });
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ) : row.title === 'Курс валют' ? (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                              ref={provided.innerRef}
                            >
                              <TableCell align='left'>{` ${row.title}  станом на ${row.exchangedate}`}</TableCell>
                              <TableCell align='center' >
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => {
                                    const forex = mainWidgets.find((el) => el.title === 'Курс валют');
                                    handleChange({ id: forex?.id, is_public: !forex?.is_public });
                                  }}
                                >
                                  {row.is_public ? <PlayArrowIcon /> : <PauseIcon />}
                                </IconButton>
                              </TableCell>
                              <TableCell>
                                <div>
                                  <TextField
                                    size='small'
                                    id='outlined-basic'
                                    label={'Число'}
                                    variant='outlined'
                                    value={`USD  ${row.usd?.toFixed(2)}`}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
                                    disabled
                                    sx={{
                                      '& input': {
                                        fontWeight: 'bold', // Изменение жирности шрифта
                                      },
                                    }}
                                  />
                                  <TextField
                                    sx={{
                                      '& input': {
                                        fontWeight: 'bold', // Изменение жирности шрифта
                                      },
                                    }}
                                    disabled
                                    size='small'
                                    id='outlined-basic'
                                    label={'Число'}
                                    variant='outlined'
                                    value={`EUR  ${row.eur?.toFixed(2)}`}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ) : (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                              ref={provided.innerRef}
                            >
                              <TableCell align='left'>{row.title}</TableCell>
                              <TableCell align='center' >
                                {
                                  <TextField
                                    size='small'
                                    id='outlined-basic'
                                    label='Посилання'
                                    variant='outlined'
                                    value={row.link}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                      handleChange({ id: row.id, link: e.target.value });
                                    }}
                                  />
                                }
                              </TableCell>
                              <TableCell align='left'>
                                {
                                  <TextField
                                    size='small'
                                    id='outlined-basic'
                                    label={i !== 1 ? 'Введіть суму *' : 'Число, %'}
                                    variant='outlined'
                                    value={row.summa}
                                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                      handleChange({ id: row.id, summa: e.target.value });
                                    }}
                                  />
                                }
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      )
                    )}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
};
