import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DeleteBlock, UpdateBlock, UpdateBlockPosition } from '../../store/blocks/actions';
import { EditBlock } from './EditBlock';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IBlock } from '../../store/blocks/types';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Pagination from '@mui/material/Pagination';
import { ROW_PAGE } from '../../utils/consts';
import { WarningModal } from '../modals';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface Column {
  id: 'id' | 'name' | 'adate' | 'isPublic' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '3%' },
  { id: 'name', label: 'Назва', align: 'left', width: '10%' },
  { id: 'adate', label: 'Створено', align: 'left', width: '10%' },
  { id: 'isPublic', label: 'Публікція', align: 'left', width: '10%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListBlocks: React.FC<PropsType> = () => {
  const [page, setPage] = React.useState(1);
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: IBlock | null } | null>(null);
  const { Blocks, Sidebar } = useSelector((store: AppStore) => store);
  const [blocks, setBlocks] = useState(
    [...(Blocks.blocks?.slice((page - 1) * ROW_PAGE, page * ROW_PAGE) || [])].sort((a: any, b: any) => {
      return Number(a.position) - Number(b.position);
    })
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      setEditData(null);
    }
  }, [Sidebar.page]);

  useEffect(() => {
    setBlocks(
      [...(Blocks.blocks?.slice((page - 1) * ROW_PAGE, page * ROW_PAGE) || [])].sort((a: any, b: any) => {
        return Number(a.position) - Number(b.position);
      })
    );
  }, [Blocks, page]);

  const handleRemove = (id: number) => {
    dispatch(DeleteBlock.request({ id }));
  };

  const onPublic = (row: IBlock) => {
    dispatch(
      UpdateBlock.request({
        data: {
          siteId: row.siteId,
          name: row.name,
          isPublic: row.isPublic ? 0 : 1,
          subBlocks: row.subBlocks,
        },
        id: row.id,
      })
    );
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onDragEnd = (result: DropResult, event: any) => {
    if (!result.destination) {
      return;
    }

    const item = blocks[result.source.index];
    const payload = [
      { id: result.draggableId, position: result.destination.index },
      { id: blocks[result.destination.index]?.id, position: result.source.index },
    ];

    blocks.splice(result.source.index, 1);
    blocks.splice(result.destination.index, 0, item);
    if (item.siteId) {
      setBlocks(blocks);

      dispatch(
        UpdateBlockPosition.request({
          data: {
            positions: payload,
            site_id: item.siteId,
          },
        })
      );
    }
  };

  if (editData) {
    return <EditBlock type={editData.type} data={editData.data} handleCancel={() => setEditData(null)} />;
  }

  return (
    <>
      <Box style={{ marginBottom: '20px' }}>
        <Box>
          <Button
            style={{ margin: '0 0 10px 0' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setEditData({ type: 'new' });
            }}
          >
            Створити блок
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="documentsList">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef} style={{ width: '100%' }}>
                  {blocks
                    .sort((a: any, b: any) => {
                      return Number(a.position) - Number(b.position);
                    })
                    .map((row, i) => {
                      return (
                        <Draggable draggableId={String(row.id)} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                            >
                              <TableCell align="center">{row.id}</TableCell>
                              <TableCell>{row.name || ''}</TableCell>
                              <TableCell>{moment(row.adate).format('DD.MM.YYYY HH:mm')}</TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => {
                                    onPublic(row);
                                  }}
                                >
                                  {
                                    //@ts-ignore
                                    row.isPublic || row?.is_public ? <PlayArrowIcon /> : <PauseIcon />
                                  }
                                </IconButton>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton aria-label="edit" onClick={() => setEditData({ type: 'edit', data: row })}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell align="center">
                                <IconButton aria-label="delete" onClick={() => setIsModal({ id: row.id })}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Pagination count={Blocks.blocks ? Math.ceil(Blocks.blocks.length / ROW_PAGE) : 0} page={page} onChange={handleChangePage} />
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
