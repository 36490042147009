import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { GetBlocks } from '../../store/blocks/actions';
import { GetMainBlocks } from '../../store/main/actions';
import { AppStore } from '../../store/applicationState';
import { GetForm, UpdateForms } from '../../store/forms/actions';
import { FormsForm } from '../../components/FormsForm';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ConfigurationsActions } from '../../store/configurations/actions';

export const EditForm: React.FC = () => {
  const { Forms } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (id) {
      dispatch(GetForm.request({ id: Number(id) }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (Forms.current) {
      dispatch(ConfigurationsActions.setSiteId(Forms.current.siteId));
    }
  }, [dispatch, Forms]);

  const handleUpdate = (data: FormData, callBack: (err?: string) => void) => {
    if (!Forms.current) return null;

    dispatch(
      UpdateForms.request({
        data,
        id: Forms.current.id,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) callBack('Такий заголовок вже використовується');
            else callBack('Сталася помилка');
          }
          const isPublic = data.get('isPublic') || 0;
          if (success && Forms.current?.isPublic === 1 && Number(isPublic) === 0) {
            dispatch(GetBlocks.request({ id: Forms.current.siteId }));
            dispatch(GetMainBlocks.request({ id: Forms.current.siteId }));
          }
        },
      })
    );
  };

  if (!Forms.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <FormsForm
      type='edit'
      data={Forms.current}
      handleSubmit={handleUpdate}
      handleCancel={() => navigate(ROUTES.routeChange('list'))}
    />
  );
};
