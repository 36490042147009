import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle, Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ICatalog } from '../../store/catalogues/types';
import { TypeCreateCatalogR } from '../../store/catalogues/actions';
import { SelectRubrics, SelectPopup, BasicDatePicker } from '../ui';
import { EditorDefault, EditorFeatured } from '../Editors';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { RubricsModal } from '../modals';
import { Editor } from '@tinymce/tinymce-react';
import { uuid } from '../Editors/EditorDocum/entities/utils';
import { CATEGORIES } from '../../utils/consts';

interface PropsType {
  type: 'edit' | 'new';
  data?: ICatalog | null;
  handleSubmit: (data: TypeCreateCatalogR['data'], callBack: (err?: string) => void) => void;
  handleCancel: () => void;
}

export const CatalogForm: React.FC<PropsType> = ({ type, data, handleSubmit, handleCancel }) => {
  const [searchTags, setSearchTags] = React.useState<string>(data?.searchTags || '');
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [publicationAt, setPublicationAt] = React.useState<Date | null>(data?.publication_at || null);
  const [isPublic, setIsPublic] = React.useState(data?.isPublic || 0);
  const [rubricId, setRubricId] = React.useState<string>(data ? String(data.rubricId) : '');
  const [rubricIds, setRubricIds] = React.useState<{ id: number; name: string }[]>([]);
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState('');

  const editorDefRef = React.useRef<Editor['editor'] | null>(null);
  const editorRef = React.useRef<Editor['editor'] | null>(null);

  const { Catalogues, Rubrics, Configurations } = useSelector((store: AppStore) => store);



  React.useEffect(() => {
    if (data) {
      if (Array.isArray(data.rubrics) && data.rubrics.length > 0)
        setRubricIds(data.rubrics.map((it) => ({ id: it.id, name: it.name })));
    }
  }, [data]);

  const onSubmit = () => {
    if (!publicationAt) {
      setError(true);
      return;
    }
    const _body = editorRef.current?.getBody();

    if (_body?.children[0].classList.contains('anchor') && editorRef.current && _body.childNodes) {
      editorRef.current.dom.removeClass(_body.childNodes as any, 'anchor');
    }

    const description = editorDefRef.current?.getContent();
    const content = editorRef.current?.getContent();
    const tempElement = document.createElement('html');
    tempElement.innerHTML = content || '';

    Array.from(tempElement.getElementsByTagName('p')).forEach((item) => {
      if (!item.hasAttribute('id')) {
        item.setAttribute('id', uuid());
      }
    });

    const tdElements = tempElement.querySelectorAll('td');
    tdElements.forEach((tdElement) => {
      const pElement = tdElement.querySelector('p');

      if (!pElement || !pElement.id) {
        const tdContent = tdElement.textContent;
        if (tdContent !== null) {
          const trimmedContent = tdContent.trim();
          if (trimmedContent) {
            const pElement = document.createElement('p');
            pElement.id = uuid();
            pElement.innerHTML = tdElement.innerHTML;
            tdElement.innerHTML = pElement.outerHTML;
          }
        }
      }
    });

    const body = tempElement.innerHTML;

    if (!title || !Number(rubricId) || !body) return setError(true);
    setError(false);
    setErrors('');

    handleSubmit(
      {
        isPublic,
        title,
        publication_at: publicationAt,
        description,
        body,
        siteId: data ? data.siteId : Configurations.siteId,
        rubricId: Number(rubricId),
        searchTags,
        rubricsIds: rubricIds.map((it) => it.id),
        is_in_cart: data?.is_in_cart || 0,
      },
      (err) => {
        if (err) {
          setErrors(err);
        }
      }
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div >
        <Box >
          <Typography component='h1' variant='h5'>
            {type === 'new' ? 'Створити довідник' : 'Редагувати довідник'}
          </Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box >Дата створення {moment(data?.adate).format('DD.MM.YYYY HH:mm')}</Box>
              <Box>Дата редагування {moment(data?.update).format('DD.MM.YYYY HH:mm')}</Box>
            </Box>
          )}
        </Box>
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isPublic} />}
          onChange={() => {
            setIsPublic(isPublic ? 0 : 1);
          }}
          label='Публікувати'
        />
        <TextField
          size='small'
          id='outlined-basic'
          label='Теги'
          variant='outlined'
          value={searchTags}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSearchTags(event.target.value as string);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={error && !title}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />
        <div  style={{ display: 'flex' }}>
          <BasicDatePicker
            id='publicationAt'
            required
            label='Дата публікації'
            value={publicationAt}
            error={(!!errors && !!publicationAt) || (error && !publicationAt)}
            onChange={setPublicationAt}
          />
        </div>

        <div style={{ marginTop: '20px' }}>
          <EditorDefault placeholder='Опис' editorRef={editorDefRef} initialValue={data?.description} />
        </div>
        <SelectRubrics
          required
          title='Рубрикатор'
          value={rubricId}
          error={error && !rubricId}
          
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.CATALOGUES]}
          onChange={setRubricId}
        />
        <SelectPopup
          title='Додатк. Рубрикатор'
          value={rubricIds}
          
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.CATALOGUES]}
          onChange={setRubricIds}
          PropsModal={RubricsModal}
        />
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <EditorFeatured
            categoryId={CATEGORIES.CATALOGUES}
            editorRef={editorRef}
            initialValue={data?.body}
            siteId={Configurations.siteId}
            docId={data?.id}
          />
          <div style={{ padding: '130px 10px 0px' }}>Ширина 1100px</div>
        </div>
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Catalogues.loading} onClick={onSubmit}>
            {Catalogues.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
