import { Reducer } from 'redux';
import ActionTypes, { TOfficialDocState, IOfficialDoc, ITypeDoc } from './types';

export const initialState: TOfficialDocState = {
  statistics: null,
  docsTotal: 0,
  officialDocUpdate: [],
  selectList: [],
  data: null,
  searchData: null,
  redactions: null,
  configurations: null,
  editData: null,
  status: {
    lowStatus: {
      '2': 'Чинний',
      '1': 'Не набрав чинності',
      '0': 'Втратив чинність',
      '3': 'Частково втратив чинність',
    },
    approved: {
      '0': 'видалено',
      '1': 'створено',
      '2': 'опубліковано',
    },
  },
  loading: false,
  errors: undefined,
};

const changeDocuments = (documents: IOfficialDoc[], doc: IOfficialDoc) => {
  const isExist = documents.find((it) => it.groupId === doc.groupId);
  if (isExist) {
    return documents.map((it) => (it.groupId === doc.groupId ? doc : it));
  } else {
    return [doc, ...documents];
  }
};

const reducer: Reducer<TOfficialDocState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_REDACTIONS_R:
    case ActionTypes.GET_DOCS_R:
    case ActionTypes.GET_DOC_R:
    case ActionTypes.CREATE_GROUP_R:
    case ActionTypes.CREATE_DOC_R:
    case ActionTypes.UPDATE_DOC_R:
    case ActionTypes.DELETE_DOC_R:
    case ActionTypes.GET_CONFIG_R:
    case ActionTypes.CREATE_TYPE_DOC_R:
    case ActionTypes.CREATE_OFFICIAL_DOC_OF_UPDATE_R:
    case ActionTypes.UPDATE_OFFICIAL_DOCS_FROM_UPDATE_R:
    case ActionTypes.UPDATE_TYPE_DOC_R:
    case ActionTypes.DELETE_TYPE_DOC_R:
    case ActionTypes.DELETE_OFFICIAL_DOCS_FROM_UPDATE_R:
    case ActionTypes.GET_DOCS_STATISTICS_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.GET_REDACTIONS_E:
    case ActionTypes.GET_DOCS_E:
    case ActionTypes.SEARCH_DOCS_E:
    case ActionTypes.GET_OFFICIAL_DOCS_FROM_UPDATE_E:
    case ActionTypes.UPDATE_OFFICIAL_DOCS_FROM_UPDATE_E:
    case ActionTypes.GET_DOC_E:
    case ActionTypes.CREATE_GROUP_E:
    case ActionTypes.CREATE_DOC_E:
    case ActionTypes.UPDATE_DOC_E:
    case ActionTypes.DELETE_DOC_E:
    case ActionTypes.GET_CONFIG_E:
    case ActionTypes.CREATE_TYPE_DOC_E:
    case ActionTypes.UPDATE_TYPE_DOC_E:
    case ActionTypes.DELETE_TYPE_DOC_E:
    case ActionTypes.GET_DOCS_OF_SELECT_E:
    case ActionTypes.GET_DOCS_STATISTICS_E:
      return { ...state, loading: false, errors: action.payload };

    case ActionTypes.GET_TYPES_DOC_S:
      return {
        ...state,
        configurations: state.configurations
          ? { ...state.configurations, typesDoc: action.payload }
          : state.configurations,
        loading: false,
      };

    case ActionTypes.GET_CONFIG_S:
      return {
        ...state,
        configurations: action.payload,
        data: action.payload.officialDoc,
        loading: false,
      };

    case ActionTypes.UPDATE_OFFICIAL_DOCS_FROM_UPDATE_S:
    case ActionTypes.CREATE_OFFICIAL_DOC_OF_UPDATE_S:
      return {
        ...state,
        officialDocUpdate: action.payload,
        loading: false,
      };

    case ActionTypes.CREATE_TYPE_DOC_S:
      return {
        ...state,
        configurations: state.configurations
          ? {
              ...state.configurations,
              typesDoc: [...state.configurations.typesDoc, action.payload].sort((a: ITypeDoc, b: ITypeDoc) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              ),
            }
          : state.configurations,
        loading: false,
      };

    case ActionTypes.UPDATE_TYPE_DOC_S:
      return {
        ...state,
        configurations: state.configurations
          ? {
              ...state.configurations,
              typesDoc: state.configurations.typesDoc.map((it) => (it.id === action.payload.id ? action.payload : it)),
            }
          : state.configurations,
        loading: false,
      };

    case ActionTypes.DELETE_TYPE_DOC_S:
      return {
        ...state,
        configurations: state.configurations
          ? {
              ...state.configurations,
              typesDoc: state.configurations.typesDoc.map((it) => (it.id === action.payload.id ? action.payload : it)),
            }
          : state.configurations,
        loading: false,
      };

    case ActionTypes.SET_DOCS:
      return {
        ...state,
        data: { officialDoc: action.payload },
        loading: false,
      };

    case ActionTypes.SET_DOCS_TOTAL: {
      return {
        ...state,
        docsTotal: action.payload,
        searchData: state.searchData ? {
          ...state.searchData,
          total: action.payload,
        } : null,
      };
    }

    case ActionTypes.GET_DOCS_OF_SELECT_S:
      return {
        ...state,
        selectList: action.payload,
        loading: false,
      };

    case ActionTypes.GET_DOCS_STATISTICS_S:
      return {
        ...state,
        statistics: action.payload,
        loading: false,
      };

    case ActionTypes.RESET_DATA:
    case ActionTypes.GET_DOCS_S:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case ActionTypes.SET_SEARCH_DATA:
    case ActionTypes.SEARCH_DOCS_S:
      return {
        ...state,
        searchData: action.payload,
        loading: false,
      };

    case ActionTypes.GET_OFFICIAL_DOCS_FROM_UPDATE_S:
      return {
        ...state,
        officialDocUpdate: action.payload,
        loading: false,
      };

    case ActionTypes.DELETE_OFFICIAL_DOCS_FROM_UPDATE_S:
      return {
        ...state,
        officialDocUpdate: state.officialDocUpdate?.filter((officialDocU) => {
          return officialDocU.id !== action.payload?.id;
        }),
        loading: false,
      };

    case ActionTypes.GET_DOCS_OF_SELECT_R:
      return {
        ...state,
        selectList: action.payload,
        loading: false,
      };

    case ActionTypes.SET_EDIT_DOC:
      return {
        ...state,
        editData: action.payload,
        loading: false,
      };

    case ActionTypes.SET_DOC:
    case ActionTypes.GET_DOC_S:
      return {
        ...state,
        editData: state.editData ? { ...state.editData, data: action.payload } : state.editData,
        loading: false,
      };

    case ActionTypes.GET_REDACTIONS_S:
      return {
        ...state,
        redactions: action.payload,
        loading: false,
      };

    case ActionTypes.CREATE_GROUP_S:
    case ActionTypes.CREATE_DOC_S:
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              officialDoc: changeDocuments(state.data.officialDoc, action.payload.document).slice(0, 25),
            }
          : { officialDoc: [action.payload.document], currentPage: 1, pages: 1 },
        configurations: state.configurations
          ? {
              ...state.configurations,
              officialDoc: {
                ...state.configurations.officialDoc,
                officialDoc: changeDocuments(
                  state.configurations.officialDoc.officialDoc,
                  action.payload.document
                ).slice(0, 25),
              },
            }
          : state.configurations,
        editData: { type: 'new', data: action.payload.document },
      };

    case ActionTypes.DELETE_DOC_S:
    case ActionTypes.UPDATE_DOC_S: {
      return {
        ...state,
        loading: false,
        data: state.data
          ? {
              ...state.data,
              officialDoc: state.data.officialDoc.map((it) => (it.id === action.payload.id ? action.payload : it)),
            }
          : state.data,
        configurations: state.configurations
          ? {
              ...state.configurations,
              officialDoc: {
                ...state.configurations.officialDoc,
                officialDoc: state.configurations.officialDoc.officialDoc.map((it) =>
                  it.id === action.payload.id ? action.payload : it
                ),
              },
            }
          : state.configurations,
        editData: { type: 'edit', data: action.payload },
      };
    }

    default:
      return state;
  }
};

export { reducer as OfficialDocReducer };
