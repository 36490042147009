import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TConsultationsState, IConsultation } from './types';

export const setConsultations = (payload: TConsultationsState['data']) =>
  action(ActionTypes.SET_CONSULTATIONS, payload);
export const resetConsultations = () => action(ActionTypes.RESET_CONSULTATIONS, {});

const GetConsultationsAction = new Actions('GET_CONSULTATIONS', ActionTypes);
export interface TypeGetConsultationsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}
export const GetConsultations = {
  request: (payload: TypeGetConsultationsR) => GetConsultationsAction.request(payload),
  success: (payload: TConsultationsState['data']) => GetConsultationsAction.success(payload || {}),
  error: (message: string) => GetConsultationsAction.error(message),
};

const SearchConsultationsAction = new Actions('SEARCH_CONSULTATIONS', ActionTypes);
export interface TypeSearchConsultationsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}
export const SearchConsultations = {
  request: (payload: TypeSearchConsultationsR) => SearchConsultationsAction.request(payload),
  success: (payload: IConsultation[]) => SearchConsultationsAction.success(payload || {}),
  error: (message: string) => SearchConsultationsAction.error(message),
};

const CreateConsultationAction = new Actions('CREATE_CONSULTATION', ActionTypes);
export interface TypeCreateConsultationR extends TDefRequest {
  data: {
    rubricId: number;
    siteId: number;
    authorsIds: number[];
    rubricsIds: number[];
    title: string;
    body: string;
    description?: string;
    publication_at?: Date | null;
    isPublic: number;
    searchTags: string;
    expire_at: Date | null;
    is_news: number;
    is_in_cart: number;
  };
}
export const CreateConsultation = {
  request: (payload: TypeCreateConsultationR) => CreateConsultationAction.request(payload),
  success: (payload: IConsultation) => CreateConsultationAction.success(payload || {}),
  error: (message: string) => CreateConsultationAction.error(message),
};

const UpdateConsultationAction = new Actions('UPDATE_CONSULTATION', ActionTypes);
export interface TypeUpdateConsultationR extends TDefRequest {
  data: {
    rubricId: number;
    siteId: number;
    authorsIds: number[];
    rubricsIds: number[];
    title: string;
    body: string;
    description?: string;
    isPublic: number;
    searchTags: string;
    publication_at?: Date | null;
    expire_at: Date | null;
    is_news: number;
    is_in_cart: number;
  };
  id: number;
}
export const UpdateConsultation = {
  request: (payload: TypeUpdateConsultationR) => UpdateConsultationAction.request(payload),
  success: (payload: IConsultation) => UpdateConsultationAction.success(payload || {}),
  error: (message: string) => UpdateConsultationAction.error(message),
};

const DeleteConsultationAction = new Actions('DELETE_CONSULTATION', ActionTypes);
export interface TypeDeleteConsultationR extends TDefRequest {
  id: number;
}
export const DeleteConsultation = {
  request: (payload: TypeDeleteConsultationR) => DeleteConsultationAction.request(payload),
  success: (payload: IConsultation) => DeleteConsultationAction.success(payload || {}),
  error: (message: string) => DeleteConsultationAction.error(message),
};

const GetConsultationAction = new Actions('GET_CONSULTATION', ActionTypes);
export interface TypeGetConsultationR extends TDefRequest {
  id: number;
}
export const GetConsultation = {
  request: (payload: TypeGetConsultationR) => GetConsultationAction.request(payload),
  success: (payload: IConsultation) => GetConsultationAction.success(payload || {}),
  error: (message: string) => GetConsultationAction.error(message),
};
