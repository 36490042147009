import { TAuthorsState } from '../authors/types';
import { TCartState } from '../cart/types';
import { TCataloguesState } from '../catalogues/types';
import { TConsultationsState } from '../consultations/types';
import { IForms } from '../forms/types';
import { IOfficialDoc, ITypeDoc } from '../officialDoc/types';
import { TRatingsState } from '../ratings/types';
import { IVideo } from '../video/types';
import { TypeGetWidgetesS } from '../widgets/actions';

export interface TConfigurationsState {
  configurations: IConfigurations | null;
  sites: ISites[] | null;
  siteId: number;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IConfigurations {
  viewsBlocks: IRespViewBlock[];
  video: IVideo[] | null;
  authors: TAuthorsState['data'];
  forms: IForms[] | null;
  consultations: TConsultationsState['data'];
  officialDoc: IOfficialDoc[];
  typesDoc: ITypeDoc[];
  catalogues: TCataloguesState['data'];
  publications: TCartState['data'];
  widgets: TypeGetWidgetesS;
  ratings: TRatingsState['data'];
}

export interface ISites {
  id: number;
  name: string;
}

export interface IViewBlock {
  id: number;
  mainId: number;
  name: string;
  link: string;
  contentType: number;
}

export interface IRespViewBlock {
  id: number;
  name: string;
  children: IViewBlock[];
}

enum ActionTypes {
  SET_SITE_ID = '@@configurations/SET_SITE_ID',

  GET_SITES_R = '@@configurations/GET_SITES_R',
  GET_SITES_S = '@@configurations/GET_SITES_S',
  GET_SITES_E = '@@configurations/GET_SITES_E',

  GET_CONFIGURATIONS_R = '@@configurations/GET_CONFIGURATIONS_R',
  GET_CONFIGURATIONS_S = '@@configurations/GET_CONFIGURATIONS_S',
  GET_CONFIGURATIONS_E = '@@configurations/GET_CONFIGURATIONS_E',
}

export default ActionTypes;
