export const mainInMonthTemplates: IMonthMainTemplates[] = [
  {
    title: 'Опис',
    is_public: false,
    id: 1,
    position: 0,
  },
  {
    title: 'Робочі ситуації',
    is_public: false,
    id: 2,
    position: 1,
  },
  {
    title: 'Новини',
    is_public: false,
    id: 3,
    position: 2,
  },
  {
    title: 'Форми/Зразки',
    is_public: false,
    id: 4,
    position: 3,
  },
  {
    title: 'Вебінари',
    is_public: false,
    id: 5,
    position: 4,
  },
  {
    title: 'Довідники',
    is_public: false,
    id: 6,
    position: 5,
  },
  {
    title: 'Пам’ятки',
    is_public: false,
    id: 7,
    position: 6,
  },
  {
    title: 'Банер',
    is_public: false,
    id: 8,
    position: 7,
  },
  {
    title: 'Інтерактив',
    is_public: false,
    id: 9,
    position: 8,
  },
];

export interface IMonthMainTemplates {
  title: string;
  is_public: boolean;
  id: number;
  position: number;
  month_main_detail?: {
    title?: string;
    description?: string;
    author_id?: number;
    category_id?: number;
    item_id?: number;
  };
}
