import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { WarningModal } from '../WarningModal';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { API_URL } from '../../../config';
import moment from 'moment';
import { IFormFile } from '../../../store/formFiles/types';
import { DeleteFormFile, UpdateFormFilePosition } from '../../../store/formFiles/actions';
import { EditFormFile } from './EditFormFile';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface Column {
  id: 'position' | 'name' | 'fileName' | 'isTop' | 'udate' | 'download' | 'delete' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'position', label: '№', align: 'center', width: '5%' },
  { id: 'name', label: 'Назва', align: 'left' },
  {
    id: 'fileName',
    label: 'Назва файлу',
    align: 'left',
  },
  {
    id: 'isTop',
    label: 'Головний',
    align: 'center',
  },
  {
    id: 'udate',
    label: 'Дата змін',
    align: 'center',
    width: '16%',
  },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '10%',
  },
  {
    id: 'download',
    label: 'Скачати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '10%',
  },
];

interface PropsType {
  docId: number;
  urlLink?: string;
  type?: number;
}

export const ListFormFile: React.FC<PropsType> = ({ docId, urlLink, type }) => {
  const [url] = React.useState<string>(urlLink || '');
  const [isModal, setIsModal] = React.useState<{ id: 'всі' | number } | null>(null);
  const [editData, setEditData] = React.useState<{
    type: 'edit' | 'new';
    data?: IFormFile;
  } | null>(null);
  const { FormFiles } = useSelector((store: AppStore) => store);
  const [files, setFiles] = React.useState([
    ...FormFiles.data
      .filter((file: any) => file.type === type)
      .sort((a: any, b: any) => {
        return Number(a.position) - Number(b.position);
      }),
  ]);

  const dispatch = useDispatch();

  const handleEdit = (data: IFormFile) => {
    setEditData({ type: 'edit', data });
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const arr = [...files];

    const item = arr[result.source.index];

    const payload = [
      { id: result.draggableId, position: result.destination.index },
      { id: arr[result.destination.index]?.id, position: result.source.index },
    ];

    arr.splice(result.source.index, 1);
    arr.splice(result.destination.index, 0, item);
    setFiles([...arr]);
    dispatch(
      UpdateFormFilePosition.request({
        data: { positions: payload, form_id: item.form_id },
      })
    );
  };

  React.useEffect(() => {
    setFiles([
      ...FormFiles.data
        .filter((file: any) => file.type === type)
        .sort((a: any, b: any) => {
          return Number(a.position) - Number(b.position);
        }),
    ]);
    // eslint-disable-next-line
  }, [FormFiles.data]);

  const handleRemove = (modalContent: number | 'всі') => {
    dispatch(
      DeleteFormFile.request({
        id: modalContent !== 'всі' ? modalContent : 0,
        deleteAll: modalContent === 'всі' ? true : false,
        url,
        form_id: docId,
        type: files[0].type,
      })
    );
  };

  const handleDownload = (file: IFormFile) => {
    const link = document.createElement('a');
    link.download = file.fileName;
    link.href = `${API_URL}${url}/download/${file.id}`;
    document.body.appendChild(link);
    link.click();
    link.parentNode && link.parentNode.removeChild(link);
  };

  if (editData) {
    return (
      <EditFormFile
        docId={docId}
        handleCancel={() => setEditData(null)}
        url={url}
        type={type}
        editData={editData?.data}
      />
    );
  }

  return (
    <>
      <Box >
        <Button
          
          variant='contained'
          color='primary'
          onClick={() => {
            setEditData({ type: 'new' });
          }}
        >
          Додати файли
        </Button>
        <Button
          
          variant='contained'
          color='error'
          onClick={() => {
            setIsModal({ id: 'всі' });
          }}
        >
          Видалити всі
        </Button>
      </Box>
      <TableContainer  style={{ height: '400px' }}>
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='documents-list'>
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef} style={{ width: '100%' }}>
                  {files
                    ?.filter((file: any) => file.type === type)
                    .sort((a: any, b: any) => {
                      return Number(a.position) - Number(b.position);
                    })
                    .map((row: any, i: number) => {
                      return (
                        <Draggable draggableId={`${row.id}`} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              role='checkbox'
                              tabIndex={-1}
                            >
                              <TableCell align='center'>{i + 1}</TableCell>
                              <TableCell >{row.name}</TableCell>
                              <TableCell >{row.fileName}</TableCell>
                              <TableCell align='center'>{row.is_top && <DoneIcon />}</TableCell>
                              <TableCell  align='center'>
                                {moment(row.udate).format('DD.MM.YYYY HH:mm')}
                              </TableCell>
                              <TableCell  align='center'>
                                <IconButton aria-label='edit' onClick={() => handleEdit(row)}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell  align='center'>
                                <IconButton aria-label='edit' onClick={() => handleDownload(row)}>
                                  <FileDownloadIcon />
                                </IconButton>
                              </TableCell>
                              <TableCell align='center'>
                                <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`${isModal.id === 'всі' ? '' : ' № '}${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
