import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { GetConfigurator, UpdateConfigurator } from '../../store/configurator/actions';
import { TextareaAutosize } from '@mui/material';

export const Configurator: React.FC = () => {
  const [nameBtn, setNameBtn] = React.useState<string>('');
  const [url, setUrl] = React.useState<string>('');
  const [allUpdatesName, setAllUpdatesName] = React.useState<string>('');
  const [allUpdatesText, setAllUpdatesText] = React.useState<string>('');
  const [error, setError] = React.useState(false);

  const { Configurator, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Configurations.siteId) {
      dispatch(
        GetConfigurator.request({
          siteId: Configurations.siteId,
        })
      );
    }
  }, [dispatch, Configurations.siteId]);

  React.useEffect(() => {
    if (Configurator.data) {
      const { pb_name, pb_url, all_updates_name, all_updates_text } = Configurator.data;
      setNameBtn(pb_name);
      setUrl(pb_url);
      setAllUpdatesName(all_updates_name);
      setAllUpdatesText(all_updates_text);
    }
  }, [Configurator.data]);

  const handleUpdate = () => {
    if (!Configurations.siteId) return null;
    if (!url || !nameBtn || !allUpdatesName) return setError(true);

    dispatch(
      UpdateConfigurator.request({
        siteId: Configurations.siteId,
        data: {
          pb_name: nameBtn,
          pb_url: url,
          site_id: Configurations.siteId,
          all_updates_name: allUpdatesName,
          all_updates_text: allUpdatesText,
        },
      })
    );
  };

  return (
    <Box display='flex'>
      <CssBaseline />
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <Typography component='h1' variant='h5'>
              Конфігуратор
            </Typography>
          </div>
          <Typography className='mb-2' component='h5' variant='h6'>
            Головне у місяці
          </Typography>
          <div className='col-4'>
            <TextField
              required
              size='small'
              id='outlined-basic'
              label='Назва кнопки'
              variant='outlined'
              value={nameBtn}
              error={error && !nameBtn}
              style={{ width: '100%' }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setNameBtn(event.target.value as string);
              }}
            />
          </div>
          <div className='col-8'>
            <TextField
              required
              size='small'
              id='outlined-basic-1'
              label='Посилання'
              variant='outlined'
              value={url}
              error={error && !url}
              style={{ width: '100%' }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setUrl(event.target.value as string);
              }}
            />
          </div>
          <Typography className='mt-3 mb-2' component='h5' variant='h6'>
            Усі оновлення
          </Typography>
          <div className='col-4'>
            <TextField
              required
              size='small'
              id='outlined-basic-2'
              label='Заголовок'
              variant='outlined'
              value={allUpdatesName}
              error={error && !allUpdatesName}
              style={{ width: '100%' }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setAllUpdatesName(event.target.value as string);
              }}
            />
          </div>
          <div className='col-8'>
            <TextareaAutosize
              value={allUpdatesText}
              aria-label='maximum height'
              maxRows={255}
              placeholder='Текст'
              style={{ width: '100%', minHeight: '40px' }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setAllUpdatesText(event.target.value as string);
              }}
            />
          </div>
          <div className='col-12 mt-4'>
            <Box>
              <Button variant='contained' color='primary' disabled={Configurator.loading} onClick={handleUpdate}>
                {Configurator.loading ? <CircularProgress size={15} /> : 'Зберегти'}
              </Button>
              {/* <Button variant="contained" style={{ height: '36px', marginLeft: '1rem' }}>
                Повернутися
              </Button> */}
            </Box>
          </div>
          {error ? (
            <Alert severity='error' style={{ width: '100%' }}>
              <AlertTitle>Заповніть поля</AlertTitle>
            </Alert>
          ) : null}
          {Configurator.errors ? (
            <Alert severity='error' style={{ width: '100%' }}>
              <AlertTitle>Сталася помилка</AlertTitle>
            </Alert>
          ) : null}
        </div>
      </div>
    </Box>
  );
};
