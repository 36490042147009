import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TFormsState, IForms } from './types';

export const setForms = (payload: TFormsState['forms']) => action(ActionTypes.SET_FORMS, payload);
export const resetForms = () => action(ActionTypes.RESET_FORMS, {});

const GetFormsAction = new Actions('GET_FORMS', ActionTypes);
export interface TypeGetFormsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}
export const GetForms = {
  request: (payload: TypeGetFormsR) => GetFormsAction.request(payload),
  success: (payload: TFormsState['data']) => GetFormsAction.success(payload || {}),
  error: (message: string) => GetFormsAction.error(message),
};

const SearchFormsAction = new Actions('SEARCH_FORMS', ActionTypes);
export interface TypeSearchFormsR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}
export const SearchForms = {
  request: (payload: TypeSearchFormsR) => SearchFormsAction.request(payload),
  success: (payload: IForms[]) => SearchFormsAction.success(payload || {}),
  error: (message: string) => SearchFormsAction.error(message),
};

const CreateFormsAction = new Actions('CREATE_FORMS', ActionTypes);
export interface TypeCreateFormsR extends TDefRequest {
  data: FormData;
}
export const CreateForms = {
  request: (payload: TypeCreateFormsR) => CreateFormsAction.request(payload),
  success: (payload: IForms) => CreateFormsAction.success(payload || {}),
  error: (message: string) => CreateFormsAction.error(message),
};

const UpdateFormsAction = new Actions('UPDATE_FORMS', ActionTypes);
export interface TypeUpdateFormsR extends TDefRequest {
  data: FormData;
  id: number;
}
export const UpdateForms = {
  request: (payload: TypeUpdateFormsR) => UpdateFormsAction.request(payload),
  success: (payload: IForms) => UpdateFormsAction.success(payload || {}),
  error: (message: string) => UpdateFormsAction.error(message),
};

const DeleteFormsAction = new Actions('DELETE_FORMS', ActionTypes);
export interface TypeDeleteFormsR extends TDefRequest {
  id: number;
}
export const DeleteForms = {
  request: (payload: TypeDeleteFormsR) => DeleteFormsAction.request(payload),
  success: (payload: IForms) => DeleteFormsAction.success(payload || {}),
  error: (message: string) => DeleteFormsAction.error(message),
};

const GetFormAction = new Actions('GET_FORM', ActionTypes);
export interface TypeGetFormR extends TDefRequest {
  id: number;
}
export const GetForm = {
  request: (payload: TypeGetFormR) => GetFormAction.request(payload),
  success: (payload: IForms) => GetFormAction.success(payload || {}),
  error: (message: string) => GetFormAction.error(message),
};
