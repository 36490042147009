import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch } from 'react-redux';
import { CreateForms } from '../../store/forms/actions';
import { FormsForm } from '../../components/FormsForm';
import { ConfigurationsActions } from '../../store/configurations/actions';

export const CreateForm: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);

  const handleCreate = (data: FormData, callBack: (err?: string) => void) => {
    dispatch(
      CreateForms.request({
        data,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) callBack('Такий заголовок вже використовується');
            else callBack('Сталася помилка');
          } else {
            callBack();
            navigate(ROUTES.routeChange(`list/${dta?.id || ''}`));
          }
        },
      })
    );
  };

  return <FormsForm type='new' handleSubmit={handleCreate} handleCancel={() => navigate(ROUTES.routeChange('list'))} />;
};
