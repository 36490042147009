import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { EditQuickAccess } from '../../components/Widgets/EditQuickAccess';
import { UpdateAdditionalWidget, UpdateAdditionalWidgetsPosition } from '../../store/widgets/actions';
import { IAdditionalWidgets } from '../../store/widgets/types';

interface Column {
  id: 'name' | 'isPublic' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Назва', align: 'left', width: '10%' },
  { id: 'isPublic', label: 'Публікція', align: 'center', width: '5%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '5%',
  },
];

type PropsType = {};

export const QuickAccess: React.FC<PropsType> = () => {
  const { Widgetes } = useSelector((store: AppStore) => store);
  const [editData, setEditData] = React.useState<IAdditionalWidgets | null>(null);
  const [quickAccessWidgets, setQuickAccessWidgets] = React.useState<IAdditionalWidgets[]>([]);
  const dispatch = useDispatch();


  React.useEffect(() => {
    if (Widgetes.additionalWidgets) {
      setQuickAccessWidgets(
        [...Widgetes.additionalWidgets.filter((el) => el.type_id === 2)].sort((a: any, b: any) => {
          return Number(a.position) - Number(b.position);
        })
      );
    }
  }, [Widgetes.additionalWidgets]);

  const onPublic = (id: number, value: 1 | 0) => {
    const item = quickAccessWidgets.find((el) => el.id === id);

    if (item) {
      dispatch(
        UpdateAdditionalWidget.request({
          data: {
            ...item,
            is_public: value,
          },
          id,
        })
      );
    }
  };


  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const item = quickAccessWidgets[result.source.index];
    const payload = [
      { id: result.draggableId, position: result.destination.index },
      { id: quickAccessWidgets[result.destination.index]?.id, position: result.source.index },
    ];

    quickAccessWidgets.splice(result.source.index, 1);
    quickAccessWidgets.splice(result.destination.index, 0, item);

    setQuickAccessWidgets(quickAccessWidgets);

    dispatch(
      UpdateAdditionalWidgetsPosition.request({
        data: {
          positions: payload,
        },
      })
    );
  };

  if (editData) {
    return <EditQuickAccess data={editData} handleCancel={() => setEditData(null)} />;
  }

  return (
    <>
      <TableContainer >
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable  droppableId='board'>
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%' }}>
                  {quickAccessWidgets
                    .sort((a: any, b: any) => {
                      return Number(a.position) - Number(b.position);
                    })
                    .map((row, i) => {
                      return (
                        <Draggable draggableId={String(row.id)} index={i} key={`${i}-${row.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${row.id}`}
                            >
                              <TableCell align='left'>{row.title}</TableCell>
                              <TableCell align='center' >
                                <IconButton
                                  aria-label='edit'
                                  onClick={() => {
                                   
                                    onPublic(row.id, row.is_public === 1 ? 0 : 1);
                                  }}
                                >
                                  {row.is_public ? <PlayArrowIcon /> : <PauseIcon />}
                                </IconButton>
                              </TableCell>
                              <TableCell align='center'>
                                <IconButton aria-label='edit' onClick={() => setEditData({ ...row })}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
};
