import React from 'react';
import { IMeaning } from './EditorTypes';
import { ELEMENT_OFFSET } from './consts';
import { replaceQuotes } from '../../../../utils/replaceQuotesParser';

const uuid = () => {
  return 'xxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const defineMeaning = (text = '', tag = 'h2') => {
  const meaning = [] as { id: string; level: number; text: string }[];
  text.split(`<${tag}`).forEach((item, i) => {
    if (i === 0 && item && tag === 'h2') {
      const level2 = defineMeaning(item, 'h3');
      meaning.push(...level2);
    }
    if (i === 0) return null;
    const arr = item.split(`</${tag}>`);
    const title = arr[0];
    const dataId = item.split('id="')[1];
    let id = '';
    if (dataId) {
      id = dataId?.substring(0, dataId.indexOf('"'));
    }
    if (title) {
      meaning.push({
        id,
        level: tag === 'h2' ? 1 : 2,
        text: title.substring(title.indexOf('>') + 1),
      });
    }
    if (tag === 'h3') return null;
    const level2 = defineMeaning(arr[1], 'h3');
    meaning.push(...level2);
  });
  return meaning;
};

const setUuid = (text = '', tags = ['p']): string => {
  if (tags.length === 0) return text;
  const tag = tags.shift();
  const str = text
    .split(`<${tag}`)
    .map((item, i) => {
      if (i === 0) return item;
      return `<${tag} id="${uuid()}"${item}`;
    })
    .join('');
  return setUuid(str, tags);
};

const callTimeout = (callback: () => void, delay: number) => {
  let data: any;
  return () => {
    if (data) {
      clearTimeout(data);
    }
    data = setTimeout(() => {
      callback();
      clearTimeout(data);
    }, delay);
  };
};

const handleScrollMeaning = (
  e: any,
  meaningRef: React.RefObject<IMeaning[]>,
  editorRef: any,
  selectedMeaningId: string | null,
  setSelectedMeaningId: (id: string) => void
) => {
  // Отримуємо позицію прокрутки.
  const scrollPosY = e?.currentTarget?.scrollY;
  // Отримуємо елементи текстового редактора, які відповідають елементам списку зі значеннями.
  const jsxElements = meaningRef?.current?.map((el) => editorRef.current?.dom?.get(el.id));
  // Фільтруємо елементи текстового редактора, які зараз знаходяться на видимій частині екрану.
  const selectedElements =
    jsxElements?.filter((el) => {
      const relativePosY = scrollPosY - (el?.offsetTop || 0) + ELEMENT_OFFSET;
      return relativePosY >= 0 && relativePosY < (el?.offsetHeight || 0);
    }) || [];

  if (!selectedElements.length) {
  }

  // Отримуємо ID першого елементу зі списку, який знаходиться на видимій частині екрану.
  const selectedId = selectedElements.length > 0 ? selectedElements[0]?.id : selectedMeaningId;
  // Встановлюємо ID виділеного елементу, який буде підсвічено на текстовому редакторі.
  if (selectedId) setSelectedMeaningId(selectedId);
};

function parseHTML(text: string) {
  const htmlString = text.replace(/<br\s*\/?>/gm, ' ');
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const plainText = doc.body.innerText;
  return plainText;
}

const defineScrollDirection = (
  prevScrollY: number,
  setPrevScrollY: (prevScrollY: number) => void,
  setScrollDirection: (scrollDirection: 'down' | 'up') => void
) => {
  return (e: any) => {
    const currentScrollY = e.currentTarget.scrollY;
    const scrollDirection = currentScrollY > prevScrollY ? 'down' : 'up';
    setPrevScrollY(currentScrollY);
    setScrollDirection(scrollDirection);
  };
};

function handleSelectedMeaning(
  selectedMeaning: string,
  meaningList: IMeaning[],
  containerRef: React.RefObject<HTMLUListElement>,
  scrollDirection: 'up' | 'down'
) {
  const selectedMeaningIndex = meaningList.findIndex((el) => el.id === selectedMeaning);
  const element = document.getElementById(selectedMeaning + '.' + selectedMeaningIndex);
  
  const delta = ELEMENT_OFFSET * 12; //Це щоб ползунок був по середині списку
  if (element && containerRef.current && selectedMeaning !== meaningList[0].id) {
    const scrollOffset = scrollDirection === 'down' ? 0 : -ELEMENT_OFFSET;
    containerRef.current.scrollTop = ELEMENT_OFFSET * selectedMeaningIndex + scrollOffset - delta;
  }
}

function handleConverteQuotes(editorRef: any, setShowActiveQuotes: (value: boolean) => void) {
  const editor = editorRef?.current;
  if (editor) {
    const selectedText = editor.selection.getContent({ format: 'html' });
    const parseText = replaceQuotes(selectedText);
    if (parseText.trim()) {
      editor.selection.setContent(parseText);
      const content = editorRef?.current?.getContent();
      const tempElement = document.createElement('html');
      tempElement.innerHTML = content || '';
      const body = backlight(false, tempElement.innerHTML);
      editor.setContent(backlight(true, body));
      setShowActiveQuotes(true);
    }
  }
}

function backlight(isActive: boolean, value: string | undefined) {
  if (!value) return '';
  if (isActive) {
    return value.replaceAll('«', '<mark>«</mark>').replaceAll('»', '<mark>»</mark>');
  } else {
    return value.replaceAll('<mark>', '').replaceAll('</mark>', '');
  }
}

function createAndOpenNewTab(url: string) {
  const anchorElement = document.createElement('a');
  anchorElement.href = `${url}`;
  anchorElement.target = '_blank';
  anchorElement.click();
}

export {
  callTimeout,
  setUuid,
  defineMeaning,
  uuid,
  handleScrollMeaning,
  parseHTML,
  defineScrollDirection,
  handleSelectedMeaning,
  handleConverteQuotes,
  backlight,
  createAndOpenNewTab,
};
