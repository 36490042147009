import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

interface SelectProps {
  id?: string;
  error?: boolean;
  required?: boolean;
  options: { value: string; label: string; disabled?: boolean }[];
  title: string;
  handleChange: (value: string) => void;
  value: string;
  style?: React.CSSProperties | undefined;
  className?: string;
}
export const SelectOutline: React.FC<SelectProps> = ({
  id,
  style,
  className,
  options,
  title,
  handleChange,
  value,
  required,
  error,
}) => {
  return (
    <Box style={style} className={className}>
      <FormControl fullWidth required={required} error={error} size='small' variant='outlined'>
        <InputLabel id={`${id}-label`}>{title}</InputLabel>
        <Select labelId={`${id}-label`} id={id} value={value} label={title}>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ opacity: option.disabled ? '0.5' : '' }}
              onClick={() => !option.disabled && handleChange(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
