import React from 'react';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Alert, AlertTitle } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { IAdditionalWidgets } from '../../store/widgets/types';
import { CreateAdditionalWidget, UpdateAdditionalWidget } from '../../store/widgets/actions';
import { useDispatch } from 'react-redux';

interface PropsType {
  type: 'edit' | 'new';
  data?: IAdditionalWidgets | null;
  handleCancel: () => void;
}

export const EditUniversalWidgets: React.FC<PropsType> = ({ data, handleCancel, type }) => {
  const [error, setError] = React.useState(false);
  const [title, setTitle] = React.useState(type === 'edit' ? data?.title || '' : '');
  const [url, setUrl] = React.useState(type === 'edit' ? data?.url || '' : '');
  const [description, setDescription] = React.useState(type === 'edit' ? data?.description || '' : '');

  const dispatch = useDispatch();

  const onSubmit = () => {
    setError(false);
    if (type === 'edit') {
      if (!data || !data.title ) return setError(true);
      dispatch(
        UpdateAdditionalWidget.request({
          data: {
            ...data,
            title: title || data.title,
            url: url || data.url,
            description: description || data.description,
          },
          id: data.id,
          callBack: (success) => {
            if (success) return handleCancel();
            return setError(true);
          },
        })
      );
    } else {
      dispatch(CreateAdditionalWidget.request({
        data:{
          title,
          description,
          url
        },
        callBack: (success) => {
          if (success) return handleCancel();
          return setError(true);
        },
      }))
    }
  };

  return (
    <Box display='flex'>
      <CssBaseline />
      <div >
        <Typography component='h1' variant='h5'>
          {type === 'new' ? 'Створити' : data?.title}
        </Typography>
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={false}
          
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setTitle(event.target.value);
          }}
        />
        <TextField
          size='small'
          id='outlined-basic'
          label='Опис'
          variant='outlined'
          value={description}
          
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setDescription(event.target.value);
          }}
        />
        <TextField
          size='small'
          id='outlined-basic'
          label='Посилання'
          variant='outlined'
          value={url}
          
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setUrl(event.target.value);
          }}
        />
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={false} onClick={onSubmit}>
            {false ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {false ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
