import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle, Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BasicDatePicker } from '../ui';
import { EditorDefault } from '../Editors';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Editor } from '@tinymce/tinymce-react';
import { IEvent } from '../../store/calendar/types';
import { CreateEvent, UpdateEvent } from '../../store/calendar/actions';

interface PropsType {
  type: 'edit' | 'new' | 'coppy';
  data?: IEvent;
  handleCancel: () => void;
}

export const CalendarForm: React.FC<PropsType> = ({ type, data, handleCancel }) => {
  const { Calendar, Configurations } = useSelector((store: AppStore) => store);
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [isPublic, setIsPublic] = React.useState<0 | 1>(type === 'edit' ? data?.is_public || 0 : 0);
  const [isMain, setIsMain] = React.useState<0 | 1>(type === 'edit' ? data?.is_main || 0 : 0);
  const [link, setLink] = React.useState(data?.link || '');
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState('');
  const [eventDate, setEventDate] = React.useState<Date | null>(
    type === 'edit'
      ? Calendar?.current?.calendar?.title || null
      : type === 'coppy'
      ? null
      : type === 'new'
      ? new Date()
      : null
  );
  const editorDefRef = React.useRef<Editor['editor'] | null>(null);
  const dispatch = useDispatch();


  const onSubmit = () => {
    if (!title || !eventDate) return setErrors('Заповніть поля !');
    const description = editorDefRef.current?.getContent();
    if (type === 'new' || type === 'coppy') {
      dispatch(
        CreateEvent.request({
          data: {
            title,
            site_id: Configurations.siteId,
            event_date: new Date(eventDate),
            is_public: isPublic,
            is_main: isMain,
            description,
            link,
          },
        })
      );
    } else {
      if (!data) return setError(true);
      dispatch(
        UpdateEvent.request({
          id: data?.id,
          data: {
            title,
            site_id: Configurations.siteId,
            event_date: new Date(eventDate),
            is_public: isPublic,
            is_main: isMain,
            description: description || data.description || '',
            link,
            calendar_id: data.calendar.id,
          },
        })
      );
    }
    if (type === 'coppy' || type === 'new') {
      handleCancel();
    }
  };

  return (
    <Box>
      <CssBaseline />
      <div >
        <Box >
          <Typography component='h1' variant='h5'>
            {type === 'new' ? 'Створити подію' : type === 'edit' ? 'Редагувати подію' : 'Клонувати подію'}
          </Typography>
        </Box>
        <Box marginTop={4} marginBottom={2}>
          <FormControlLabel
            style={{ marginTop: '7px' }}
            control={<Checkbox checked={!!isPublic} />}
            onChange={() => {
              setIsPublic(isPublic ? 0 : 1);
            }}
            label='Публікувати'
            value={isPublic}
          />
          <FormControlLabel
            style={{ marginTop: '7px' }}
            control={<Checkbox checked={!!isMain} />}
            onChange={() => {
              setIsMain(isMain ? 0 : 1);
            }}
            label='Головна'
            value={isMain}
          />
        </Box>
        <BasicDatePicker
          style={{ width: '150px' }}
          label='Дата події'
          id='publicationTo'
          required
          value={eventDate}
          error={false}
          onChange={(value) => {
            if (value) {
              setEventDate(value);
            }
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={error && !title}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />

        <div style={{ marginTop: '20px' }}>
          <EditorDefault placeholder='Опис' editorRef={editorDefRef} initialValue={data?.description || ''} />
        </div>

        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Посиланя'
          variant='outlined'
          value={link}
          error={error && !link}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setLink(event.target.value as string);
          }}
        />

        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Calendar.loading} onClick={onSubmit}>
            {Calendar.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
