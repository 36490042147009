import Modal from '@mui/material/Modal';
import React from 'react';
import Button from '@mui/material/Button';

interface BoxModalProps {
  open: boolean;
  value: string;
  type?: 'restore' | 'delete' | 'comeback';
  handleApprove: (isApprove: boolean) => void;
}

export const WarningModal: React.FC<BoxModalProps> = ({ open, value, handleApprove, type = 'delete' }) => {
  return (
    <Modal
      open={open}
      onClose={() => handleApprove(false)}
      className={'modal'}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <div className="boxModal-inner" style={{ minWidth: '300px' }}>
        <div className="boxBody" style={{ textAlign: 'center' }}>
          <h2>{type === 'restore' ? `Ви дійсно хочете відновити ${value} ?` : type === 'delete' ? `Ви дійсно хочете видалити ${value} ?` : value}</h2>
        </div>
        <div className="boxFooter">
          <div style={{ display: 'flex' }}>
            <Button color="primary" variant="contained" onClick={() => handleApprove(true)}>
              так
            </Button>
          </div>
          <Button color="inherit" variant="contained" onClick={() => handleApprove(false)}>
            скасувати
          </Button>
        </div>
      </div>
    </Modal>
  );
};
