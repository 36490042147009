export const URI = process.env.REACT_APP_URL || 'https://dev.back101.exp.com.ua';
export const CDN_URL = process.env.CDN_URL || 'https://images.expertus.com.ua';
export const API_URL = URI.trim() + '/api';
export const API_URL_SYSTEM = process.env.REACT_APP_API_URL_SYSTEM || 'https://dev.content-api.exp.com.ua';
export const drawerWidth = 280;
export const TOKEN = {
  access: 'YWNjZXNzLXRva2Vu',
  refresh: 'cmVmcmVzaC10b2tlbg',
};

console.log('url', URI);

export const configSites: { [x: number]: { url: string } } = {
  1: {
    url: process.env.REACT_APP_URL_GB || 'https://dev.golovbuh.exp.com.ua',
  },
  2: {
    url: process.env.REACT_APP_URL_OP || 'https://dev.1opexpertus.expertus.com.ua',
  },
  3: {
    url: process.env.REACT_APP_URL_EK || 'https://dev.1kexpertus.expertus.com.ua',
  },
  4: {
    url: process.env.REACT_APP_URL_DZ || 'https://dev.1dzexpertus.expertus.com.ua',
  },
  5: {
    url: process.env.REACT_APP_URL_MED || 'https://dev.1medexpertus.expertus.com.ua',
  },
};
