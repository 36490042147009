export interface TPublishersState {
  selectPublishers: { id: number, name: string }[] | null;
  ids: { id: number, title: string }[] | null;
  dataOpenPublications: {
      categories: { id: number, name: string }[];
      publishers: IOpenPublication[]
  } | null;
  data: { publishers: IPublisherDoc[]; currentPage: number; pages: number } | null;
  editData: { type: 'edit' | 'new'; data?: IPublisherDoc | null } | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface TOpenPublicationsState {
  data: { publishers: IOpenPublication[]; } | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IPublisherDoc {
  id: number;
  name: string;
  shortName: string;
  shortNameGenitive: string;
  synonyms: string;
  status: number;
  approved: number;
  docCount: number;
}

export interface IOpenPublication {
  category_id: number;
  id: number;
  title: string;
  category: string,
  url: string,
  open_publication_at: Date
}

enum ActionTypes {
  SET_PUBLISHERS = '@@publishers/SET_PUBLISHERS',
  GET_PUBLISHERS_R = '@@publishers/GET_PUBLISHERS_R',
  GET_PUBLISHER_SELECT_R = '@@publishers/GET_PUBLISHER_SELECT_R',
  GET_OPEN_PUBLICATION_R = '@@publishers/GET_OPEN_PUBLICATION_R',
  GET_PUBLISHERS_S = '@@publishers/GET_PUBLISHERS_S',
  GET_OPEN_PUBLICATION_S = '@@publishers/GET_OPEN_PUBLICATION_S',
  GET_PUBLISHER_SELECT_S = '@@publishers/GET_PUBLICATION_SELECT_S',
  GET_PUBLISHERS_E = '@@publishers/GET_PUBLISHERS_E',
  GET_OPEN_PUBLICATION_E = '@@publishers/GET_OPEN_PUBLICATION_E',
  GET_PUBLISHER_SELECT_E = '@@publishers/GET_PUBLICATION_SELECT_E',
  CREATE_PUBLISHER_R = '@@publishers/CREATE_PUBLISHER_R',
  CREATE_OPEN_PUBLICATION_R = '@@publishers/CREATE_OPEN_PUBLICATION_R',
  CREATE_PUBLISHER_S = '@@publishers/CREATE_PUBLISHER_S',
  CREATE_OPEN_PUBLICATION_S = '@@publishers/CREATE_OPEN_PUBLICATION_S',
  CREATE_PUBLISHER_E = '@@publishers/CREATE_PUBLISHER_E',
  CREATE_OPEN_PUBLICATION_E = '@@publishers/CREATE_OPEN_PUBLICATION_E',
  UPDATE_PUBLISHER_R = '@@publishers/UPDATE_PUBLISHER_R',
  UPDATE_PUBLISHER_S = '@@publishers/UPDATE_PUBLISHER_S',
  UPDATE_PUBLISHER_E = '@@publishers/UPDATE_PUBLISHER_E',
  DELETE_PUBLISHER_R = '@@publishers/DELETE_PUBLISHER_R',
  DELETE_OPEN_PUBLICATION_R = '@@publishers/DELETE_OPEN_PUBLICATION_R',
  DELETE_PUBLISHER_S = '@@publishers/DELETE_PUBLISHER_S',
  DELETE_OPEN_PUBLICATION_S = '@@publishers/DELETE_OPEN_PUBLICATION_S',
  DELETE_PUBLISHER_E = '@@publishers/DELETE_PUBLISHER_E',
  DELETE_OPEN_PUBLICATION_E = '@@publishers/DELETE_OPEN_PUBLICATION_E',
  GET_OPEN_PUBLICATION_CATEGORY_IDS_R = '@@publishers/GET_OPEN_PUBLICATION_C_IDS_R',
  GET_OPEN_PUBLICATION_CATEGORY_IDS_S = '@@publishers/GET_OPEN_PUBLICATION_C_IDS_S',
  GET_OPEN_PUBLICATION_CATEGORY_IDS_E = '@@publishers/GET_OPEN_PUBLICATION_C_IDS_E',
}

export default ActionTypes;
