import React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import { Alert, AlertTitle } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { UpdateAdditionalWidget } from '../../store/widgets/actions';
import { IAdditionalWidgets } from '../../store/widgets/types';

interface PropsType {
  data?: IAdditionalWidgets | null;
  handleCancel: () => void;
}

export const EditQuickAccess: React.FC<PropsType> = ({ data, handleCancel }) => {
  const [error, setError] = React.useState(false);
  const [url, setUrl] = React.useState(data?.url);

  const dispatch = useDispatch();


  const onSubmit = () => {
    setError(false);
    if (error || !data || !data.url) return setError(true);

    if (data?.url) {
      dispatch(
        UpdateAdditionalWidget.request({
          data: { ...data, url: String(url), type_id: 2 },
          id: data.id,
          callBack:(success)=>{
            if(success) return handleCancel();
            return setError(true)
          }
        })
      );
    }
  };

  

  return (
    <Box display='flex'>
      <CssBaseline />
      <div >
        <Typography component='h1' variant='h5'>
          {data?.title}
        </Typography>
        <TextField
          required
          disabled
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={data?.title}
          error={false}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
        />
        <TextField
          size='small'
          id='outlined-basic'
          label='Посилання'
          variant='outlined'
          value={url}
          
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            setUrl(event.target.value as string);
          }}
        />
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={false} onClick={onSubmit}>
            {false ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {false ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
