import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle, Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IConsultation } from '../../store/consultations/types';
import { TypeCreateConsultationR } from '../../store/consultations/actions';
import { SelectRubrics, SelectPopup, SelectSearchReq, BasicDatePicker } from '../ui';
import { EditorDefault, EditorFeatured } from '../Editors';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSearchAuthors } from '../../hooks/useSearchAuthors';
import { IDataOpt } from '../ui/SelectSearchReq';
import { RubricsModal } from '../modals';
import { Editor } from '@tinymce/tinymce-react';
import { backlight, uuid } from '../Editors/EditorDocum/entities/utils';
import { CATEGORIES } from '../../utils/consts';
import { API_URL_SYSTEM, URI } from '../../config';

interface PropsType {
  type: 'edit' | 'new';
  data?: IConsultation | null;
  handleSubmit: (data: TypeCreateConsultationR['data'], callBack: (err?: string) => void) => void;
  handleCancel: () => void;
}

export const ConsultationForm: React.FC<PropsType> = ({ type, data, handleSubmit, handleCancel }) => {
  const [searchTags, setSearchTags] = React.useState<string>(data?.searchTags || '');
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [publicationAt, setPublicationAt] = React.useState<Date | null>(data?.publication_at || null);
  const [expireDateAt, setExpireDateAt] = React.useState<Date | null>(data?.expire_at || null);
  const [authorFirst, setAuthorFirst] = React.useState<IDataOpt | null>(null);
  const [authorSecond, setAuthorSecond] = React.useState<IDataOpt | null>(null);
  const [authorThird, setAuthorThird] = React.useState<IDataOpt | null>(null);
  const [isPublic, setIsPublic] = React.useState(data?.isPublic || 0);
  const [rubricId, setRubricId] = React.useState<string>(data ? String(data.rubricId) : '');
  const [rubricIds, setRubricIds] = React.useState<{ id: number; name: string }[]>([]);
  const [isNews, setIsNews] = React.useState<number>(data?.is_news || 0);
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState('');

  const editorDefRef = React.useRef<Editor['editor'] | null>(null);
  const editorRef = React.useRef<Editor['editor'] | null>(null);

  const { Consultations, Rubrics, Configurations } = useSelector((store: AppStore) => store);

  const { options, handleSearchAuthors } = useSearchAuthors();

  React.useEffect(() => {
    if (data && data.expire_at && data.is_news) {
      setExpireDateAt(data.expire_at);
    }
  }, [data]);

  React.useEffect(() => {
    if (publicationAt && !expireDateAt) {
      const newExpireDate = new Date(publicationAt);
      newExpireDate.setMonth(newExpireDate.getMonth() + 6);
      setExpireDateAt(newExpireDate);
    }
  }, [publicationAt, data, isNews, expireDateAt]);

  React.useEffect(() => {
    if (data) {
      if (data.authors[0]) setAuthorFirst({ id: data.authors[0].id, label: data.authors[0].fio });
      if (data.authors[1]) setAuthorSecond({ id: data.authors[1].id, label: data.authors[1].fio });
      if (data.authors[2]) setAuthorThird({ id: data.authors[2].id, label: data.authors[2].fio });
      if (Array.isArray(data.rubrics) && data.rubrics.length > 0) setRubricIds(data.rubrics.map((it) => ({ id: it.id, name: it.name })));
    }
  }, [data]);

  const onSubmit = () => {
    if (publicationAt && expireDateAt) {
      const expDateF = new Date(expireDateAt);
      const pubDateF = new Date(publicationAt);
      if (expDateF < pubDateF && isNews) {
        setErrors('Дата закриття не може бути меншою за дату публікації.');
        return;
      }
    }
    if (!publicationAt) {
      setError(true);
      return;
    }
    const _body = editorRef.current?.getBody();

    if (_body?.children[0].classList.contains('anchor') && editorRef.current && _body.childNodes) {
      editorRef.current.dom.removeClass(_body.childNodes as any, 'anchor');
    }

    const description = editorDefRef.current?.getContent();
    const content = editorRef.current?.getContent();
    //
    const tempElement = document.createElement('html');
    tempElement.innerHTML = backlight(false, content || '');

    Array.from(tempElement.getElementsByTagName('p')).forEach((item) => {
      if (!item.hasAttribute('id')) {
        item.setAttribute('id', uuid());
      }
    });

    const tdElements = tempElement.querySelectorAll('td');
    tdElements.forEach((tdElement) => {
      const pElement = tdElement.querySelector('p');

      if (!pElement || !pElement.id) {
        const tdContent = tdElement.textContent;
        if (tdContent !== null) {
          const trimmedContent = tdContent.trim();
          if (trimmedContent) {
            const pElement = document.createElement('p');
            pElement.id = uuid();
            pElement.innerHTML = tdElement.innerHTML;
            tdElement.innerHTML = pElement.outerHTML;
          }
        }
      }
    });

    const body = tempElement.innerHTML.replaceAll(URI, API_URL_SYSTEM);

    if (!title || !Number(rubricId) || !body) return setError(true);
    setError(false);
    setErrors('');
    const authorsIds = [authorFirst, authorSecond, authorThird].map((it) => Number(it?.id)).filter((num) => !!num && !Number.isNaN(num));

    handleSubmit(
      {
        isPublic,
        authorsIds,
        title,
        publication_at: publicationAt,
        description,
        body,
        siteId: data ? data.siteId : Configurations.siteId,
        rubricId: Number(rubricId),
        searchTags,
        rubricsIds: rubricIds.map((it) => it.id),
        is_news: isNews,
        expire_at: expireDateAt && new Date(expireDateAt),
        is_in_cart: data?.is_in_cart || 0,
      },
      (err) => {
        if (err) {
          setErrors(err);
        }
      }
    );
  };

  return (
    <Box>
      <CssBaseline />
      <div className={'paper'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            {type === 'new' ? 'Створити консультацію' : 'Редагувати консультацію'}
          </Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box className={'headerItem'}>Дата створення {moment(data?.adate).format('DD.MM.YYYY HH:mm')}</Box>
              <Box>Дата редагування {moment(data?.update).format('DD.MM.YYYY HH:mm')}</Box>
            </Box>
          )}
        </Box>
        <div>
          <FormControlLabel
            style={{ marginTop: '7px' }}
            control={<Checkbox checked={!!isPublic} />}
            onChange={() => {
              setIsPublic(isPublic ? 0 : 1);
            }}
            label="Публікувати"
          />
          <FormControlLabel
            style={{ marginTop: '7px' }}
            control={<Checkbox checked={!!isNews} />}
            onChange={() => {
              setIsNews(isNews ? 0 : 1);
              if (!isNews) setExpireDateAt(null);
            }}
            label="Новина"
          />
        </div>

        <TextField
          size="small"
          id="outlined-basic"
          label="Теги"
          variant="outlined"
          value={searchTags}
          className={'textField'}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSearchTags(event.target.value as string);
          }}
        />
        <TextField
          required
          size="small"
          id="outlined-basic"
          label="Заголовок"
          variant="outlined"
          value={title}
          error={error && !title}
          className={'textField'}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />
        <div className={'textField'} style={{ display: 'flex' }}>
          <BasicDatePicker
            id="publicationAt"
            required
            label="Дата публікації"
            value={publicationAt}
            error={(!!errors && !!publicationAt) || (error && !publicationAt)}
            onChange={setPublicationAt}
          />
        </div>
        <div className={'textField'} style={{ display: isNews ? 'flex' : 'none' }}>
          <BasicDatePicker id="expireAt" label="Дата закриття" value={expireDateAt} error={!!errors} onChange={setExpireDateAt} />
        </div>
        <div style={{ marginTop: '20px' }}>
          <EditorDefault placeholder="Опис" editorRef={editorDefRef} initialValue={data?.description} />
        </div>
        <SelectRubrics
          required
          title="Рубрикатор"
          value={rubricId}
          error={error && !rubricId}
          className={'textField'}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.CONSULTATIONS]}
          onChange={setRubricId}
        />
        <SelectPopup
          title="Додатк. Рубрикатор"
          value={rubricIds}
          className={'textField'}
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.CONSULTATIONS]}
          onChange={setRubricIds}
          PropsModal={RubricsModal}
        />
        <SelectSearchReq
          title="Автор"
          data={authorFirst}
          className={'textField'}
          onChange={setAuthorFirst}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <SelectSearchReq
          title="Автор"
          data={authorSecond}
          className={'textField'}
          onChange={setAuthorSecond}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <SelectSearchReq
          title="Автор"
          data={authorThird}
          className={'textField'}
          onChange={setAuthorThird}
          options={options}
          handleRequest={handleSearchAuthors}
        />
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <EditorFeatured
            categoryId={CATEGORIES.CONSULTATIONS}
            editorRef={editorRef}
            initialValue={data?.body?.replaceAll(API_URL_SYSTEM, URI) || ''}
            siteId={Configurations.siteId}
            docId={data?.id}
          />
          <div style={{ padding: '130px 10px 0px' }}>Ширина 1100px</div>
        </div>
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant="contained" color="primary" disabled={Consultations.loading} onClick={onSubmit}>
            {Consultations.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant="contained" color="inherit" onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity="error" style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity="error" style={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
