import { FC, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { MainMonthForm } from '../../components/MainInMonth/MainMonthForm';
import { GetOneMainInMonth } from '../../store/mainInMonth/actions';
import { AppStore } from '../../store/applicationState';

export const EditMonthMain: FC = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { MainInMonth } = useSelector((store: AppStore) => store);

  useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);

  useEffect(() => {
    if (id)
      dispatch(
        GetOneMainInMonth.request({
          id: +id,
        })
      );
  }, [dispatch, id]);

  return (
    <MainMonthForm editData={MainInMonth.current} type='edit' handleCancel={() => navigate(ROUTES.routeChange(''))} />
  );
};
