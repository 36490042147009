import { put, call, takeEvery, all, fork } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import {
  CreateMainInMonth,
  DeleteMainInMonth,
  GetMainInMonth,
  GetOneMainInMonth,
  TypeCreateMainInMonth,
  TypeDeleteMainInMonth,
  TypeGetMainInMonthR,
  TypeGetOneMainInMonthR,
  TypeUpdatePositionsTemplateR,
  UpdateMainInMonth,
  UpdateTemplatePosition,
} from './actions';
import { callApi } from '../../utils/callApi';
import ActionTypes, { IMainInMonthYears, ITemplate } from './types';

function* getMainInMonthWorker(action: ReturnType<typeof GetMainInMonth.request>): Generator {
  const { callBack, data } = action.payload as TypeGetMainInMonthR;
  let success = false;
  const query = buildQuery(data);
  try {
    let res: IMainInMonthYears[] | null = null;
    res = (yield call(callApi, {
      method: 'get',
      data,
      path: `/mainInMonth?${query}`,
    })) as IMainInMonthYears[];

    yield put(GetMainInMonth.success(res));
  } catch (e) {
    success = false;
    yield put(GetMainInMonth.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getOneMainInMonthWorker(action: ReturnType<typeof GetOneMainInMonth.request>): Generator {
  const { id, callBack } = action.payload as TypeGetOneMainInMonthR;

  let success = true;
  let resp: IMainInMonthYears | null = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: '/mainInMonth/one/' + id,
    })) as IMainInMonthYears;
    yield put(GetOneMainInMonth.success(resp));
  } catch (error) {
    yield put(GetOneMainInMonth.error(error as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* deleteMainInMonthWorker(action: ReturnType<typeof DeleteMainInMonth.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteMainInMonth;

  let success = true;
  let resp: number | null = null;
  try {
    resp = (yield call(callApi, {
      method: 'delete',
      path: '/mainInMonth/' + id,
    })) as number;
    yield put(DeleteMainInMonth.success(resp));
  } catch (error) {
    yield put(DeleteMainInMonth.error(error as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* createMainInMonthWorker(action: ReturnType<typeof CreateMainInMonth.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateMainInMonth;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'post',
      data: data,
      path: '/mainInMonth/',
    })) as IMainInMonthYears[];
    yield put(CreateMainInMonth.success(resp));
  } catch (e: any) {
    success = false;
    resp = e.data.message;
    yield put(CreateMainInMonth.error(e.data.message as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateMainInMonthWorker(action: ReturnType<typeof UpdateMainInMonth.request>): Generator {
  const { data, callBack, id } = action.payload as TypeCreateMainInMonth;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'put',
      data: data,
      path: '/mainInMonth/' + id,
    })) as IMainInMonthYears;
    yield put(UpdateMainInMonth.success(resp));
  } catch (e: any) {
    success = false;
    resp = e.data.message;
    yield put(UpdateMainInMonth.error(e.data.message as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateTemplatePositionsWorker(action: ReturnType<typeof UpdateTemplatePosition.request>): Generator {
  const { data, callBack } = action.payload as TypeUpdatePositionsTemplateR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: `/mainInMonth/positions`,
    })) as ITemplate[];
    yield put(UpdateTemplatePosition.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateTemplatePosition.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_MAIN_IN_MONTH_R, getMainInMonthWorker);
  yield takeEvery(ActionTypes.GET_ONE_MAIN_IN_MONTH_R, getOneMainInMonthWorker);
  yield takeEvery(ActionTypes.CREATE_MAIN_IN_MONTH_R, createMainInMonthWorker);
  yield takeEvery(ActionTypes.DELETE_MAIN_IN_MONTH_R, deleteMainInMonthWorker);
  yield takeEvery(ActionTypes.UPDATE_MAIN_IN_MONTH_R, updateMainInMonthWorker);
  yield takeEvery(ActionTypes.UPDATE_TEMPLATE_POSITION_R, updateTemplatePositionsWorker);
}

export default function* MainInMonthSaga() {
  yield all([fork(watchFetchRequest)]);
}
