import { IAuthor } from '../authors/types';
import { IRubricItem } from '../rubrics/types';

export interface TConsultationsState {
  data: { consultations: IConsultation[]; total: number } | null;
  consultations: IConsultation[] | null;
  current: IConsultation | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IConsultation {
  id: number;
  rubricId: number;
  siteId: number;
  authors: IAuthor[];
  title: string;
  description: string;
  body: string;
  rubrics: IRubricItem[];
  rubricName: string;
  adate: Date;
  update: Date;
  isPublic: number;
  searchTags: string;
  publication_at: Date;
  is_news: number;
  expire_at: Date;
  is_in_cart: number;
  authorFio?: string;
}

enum ActionTypes {
  SET_CONSULTATIONS = '@@consultations/SET_CONSULTATIONS',
  RESET_CONSULTATIONS = '@@consultations/RESET_CONSULTATIONS',

  GET_CONSULTATIONS_R = '@@consultations/GET_CONSULTATIONS_R',
  GET_CONSULTATIONS_S = '@@consultations/GET_CONSULTATIONS_S',
  GET_CONSULTATIONS_E = '@@consultations/GET_CONSULTATIONS_E',

  GET_CONSULTATION_R = '@@consultations/GET_CONSULTATION_R',
  GET_CONSULTATION_S = '@@consultations/GET_CONSULTATION_S',
  GET_CONSULTATION_E = '@@consultations/GET_CONSULTATION_E',

  SEARCH_CONSULTATIONS_R = '@@consultations/SEARCH_CONSULTATIONS_R',
  SEARCH_CONSULTATIONS_S = '@@consultations/SEARCH_CONSULTATIONS_S',
  SEARCH_CONSULTATIONS_E = '@@consultations/SEARCH_CONSULTATIONS_E',

  CREATE_CONSULTATION_R = '@@consultations/CREATE_CONSULTATION_R',
  CREATE_CONSULTATION_S = '@@consultations/CREATE_CONSULTATION_S',
  CREATE_CONSULTATION_E = '@@consultations/CREATE_CONSULTATION_E',

  UPDATE_CONSULTATION_R = '@@consultations/UPDATE_CONSULTATION_R',
  UPDATE_CONSULTATION_S = '@@consultations/UPDATE_CONSULTATION_S',
  UPDATE_CONSULTATION_E = '@@consultations/UPDATE_CONSULTATION_E',

  DELETE_CONSULTATION_R = '@@consultations/DELETE_CONSULTATION_R',
  DELETE_CONSULTATION_S = '@@consultations/DELETE_CONSULTATION_S',
  DELETE_CONSULTATION_E = '@@consultations/DELETE_CONSULTATION_E',
}

export default ActionTypes;
