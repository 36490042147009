export function replaceQuotes(html: string) {
  let modifiedHtml = '';

  for (let i = 0; i < html.length; i++) {
    const currentChar = html[i];
    const prevChar = modifiedHtml[i - 1];
    const next = html[i + 1];

    if (!isInsideAttribute(html, i)) {
      if (currentChar === '"') {
        if (
          (prevChar === ' ' ||
            prevChar === '\n'||
            !prevChar ||
            prevChar === '>' ||
            prevChar === ';' ||
            prevChar === '.' ||
            prevChar === ',') &&
          next !== '' &&
          next !== ' ' &&
          next !== '<'&&
          next !== '»'&&
          next !== '\n'&&
          next !== undefined &&
          next !== 'undefined'

        ) {
          modifiedHtml += '«';
          continue;
        } else if (prevChar !== '' && prevChar !== ' ') {
          modifiedHtml += '»';
          continue;
        } else {
          modifiedHtml += '»';
          continue;
        }
      }
    }
    modifiedHtml += currentChar;
  }

  return modifiedHtml;
}

function isInsideAttribute(html: string, index: number) {
  let insideAttribute = false;

  for (let i = index; i >= 0; i--) {
    const currentChar = html[i];
    if (currentChar === '>') {
      break;
    }
    if (currentChar === '=') {
      insideAttribute = true;
      break;
    }
  }

  return insideAttribute;
}
