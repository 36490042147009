import React from 'react';
import clsx from 'clsx';

export type TypeOptions = { id: number; name: string; children?: TypeOptions[] | undefined | null };

interface Props {
  values?: TypeOptions[];
  open: boolean;
  handleApprove: (approved: boolean, value: TypeOptions[]) => void;
  options?: TypeOptions[] | null;
}

type SelectPopupProps = {
  title?: string;
  value?: TypeOptions[];
  options?: TypeOptions[] | null;
  required?: boolean;
  error?: boolean;
  style?: React.CSSProperties | undefined;
  styleSelect?: React.CSSProperties | undefined;
  className?: string;
  onChange: (value: TypeOptions[]) => void;
  PropsModal: React.FC<Props>;
};

export const SelectPopup: React.FC<SelectPopupProps> = React.memo(
  ({ style, styleSelect, className, title, value, options, onChange, PropsModal, error }) => {
    const [myValue, setMyValue] = React.useState('');
    const [isFocus, setIsFocus] = React.useState(false);

    const handleLeaveFocus = () => {
      setIsFocus(!isFocus);
    };

    const setClass = () => {
      if (isFocus) return 'placeholderFocus';
      if (value && value.length > 0) return 'placeholderInit';
    };

    React.useEffect(() => {
      if (value) {
        const selectedValue = value.map((val) => val.name).join('; ');
        setMyValue(selectedValue);
      }
    }, [value]);

    return (
      <div style={style} className={clsx('selectInput', className)}>
        {title && (
          <div className={clsx('selectInput-title', setClass())} style={{ color: error && !isFocus ? 'red' : '' }}>
            {title}
          </div>
        )}
        <div
          className={clsx('wrappField', isFocus && 'wrappFieldFocus')}
          style={{ borderColor: error && !isFocus ? 'red' : '' }}
          onClick={handleLeaveFocus}
        >
          <div className={'field'}>{myValue}</div>
        </div>
        {isFocus ? (
          <PropsModal
            values={value}
            open={isFocus}
            options={options}
            handleApprove={(approved, val) => {
              setIsFocus(false);
              approved && onChange(val);
            }}
          />
        ) : null}
      </div>
    );
  }
);
