import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateConsultation,
  DeleteConsultation,
  GetConsultation,
  GetConsultations,
  SearchConsultations,
  TypeCreateConsultationR,
  TypeDeleteConsultationR,
  TypeGetConsultationR,
  TypeGetConsultationsR,
  TypeSearchConsultationsR,
  TypeUpdateConsultationR,
  UpdateConsultation,
} from './actions';
import ActionTypes, { IConsultation, TConsultationsState } from './types';

function* getConsultationsWorker(action: ReturnType<typeof GetConsultations.request>): Generator {
  const { data, callBack } = action.payload as TypeGetConsultationsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/consultations?${query}`,
    })) as TConsultationsState['data'];
    yield put(GetConsultations.success(resp));
  } catch (e) {
    success = false;
    yield put(GetConsultations.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getConsultationWorker(action: ReturnType<typeof GetConsultation.request>): Generator {
  const { id, callBack } = action.payload as TypeGetConsultationR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/consultations/one/${id}`,
    })) as IConsultation;
    yield put(GetConsultation.success(resp));
  } catch (e) {
    success = false;
    yield put(GetConsultation.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* searchConsultationsWorker(action: ReturnType<typeof SearchConsultations.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchConsultationsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/consultations?${query}`,
    })) as TConsultationsState['data'];
    yield put(SearchConsultations.success(resp ? resp.consultations : []));
  } catch (e) {
    success = false;
    yield put(SearchConsultations.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createConsultationWorker(action: ReturnType<typeof CreateConsultation.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateConsultationR;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'post',
      data: data,
      path: '/consultations/',
    })) as IConsultation;
    yield put(CreateConsultation.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(CreateConsultation.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateConsultationWorker(action: ReturnType<typeof UpdateConsultation.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateConsultationR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data: data,
      path: `/consultations/${id}`,
    })) as IConsultation;
    yield put(UpdateConsultation.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateConsultation.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteConsultationWorker(action: ReturnType<typeof DeleteConsultation.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteConsultationR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/consultations/${id}`,
    })) as IConsultation;
    yield put(DeleteConsultation.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteConsultation.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CONSULTATIONS_R, getConsultationsWorker);
  yield takeEvery(ActionTypes.GET_CONSULTATION_R, getConsultationWorker);
  yield takeEvery(ActionTypes.SEARCH_CONSULTATIONS_R, searchConsultationsWorker);
  yield takeEvery(ActionTypes.CREATE_CONSULTATION_R, createConsultationWorker);
  yield takeEvery(ActionTypes.UPDATE_CONSULTATION_R, updateConsultationWorker);
  yield takeEvery(ActionTypes.DELETE_CONSULTATION_R, deleteConsultationWorker);
}

export default function* consultationsSaga() {
  yield all([fork(watchFetchRequest)]);
}
