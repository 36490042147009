import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { AppStore } from '../../store/applicationState';
import { setAuth, setUser } from '../../store/user/actions';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { callApi } from '../../utils/callApi';
import { drawerWidth } from '../../config';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { ROUTES } from '../../utils/routes';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  open?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ open = true }) => {
  const [loading, setLoading] = React.useState(false);
  const { User, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <AppBar position='absolute'>
      <Toolbar
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        style={{ paddingLeft: open ? drawerWidth + 16 : 20 }}
      >
        <Box display='flex'>
          <h1 style={{ margin: '0' }}>
            {Configurations.sites?.find((it) => it.id === Configurations.siteId)?.name || ''}
          </h1>
          {Configurations.siteId !== 6 && User.data?.role === 1 ? (
            <Button
              variant='outlined'
              color='inherit'
              disabled={loading}
              onClick={() => {
                if (!loading && Configurations.siteId && Configurations.siteId !== 6) {
                  setLoading(true);
                  callApi({
                    method: 'get',
                    path: `/upload/delete-cache/${Configurations.siteId}`,
                  }).finally(() => setLoading(false));
                }
              }}
              style={{ marginLeft: '20px' }}
            >
              Очистити кеш
            </Button>
          ) : null}
        </Box>
        {!open && Configurations.sites && Configurations.sites?.length > 1 ? (
          <Box>
            <FormControl fullWidth color='info'>
              <FormHelperText >Напрямки</FormHelperText>
              <Select
                variant='standard'
                placeholder='Напрямки'
                value={String(Configurations.siteId)}
                onChange={(event) => {
                  const subvision = Number(event.target.value);
                  dispatch(ConfigurationsActions.setSiteId(subvision));
                  navigate(ROUTES.main);
                }}
                
              >
                {Configurations.sites?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : null}
        <Box display='flex' alignItems='center'>
          <AccountCircle />
          <Typography style={{ marginLeft: '10px' }}>{User.data?.fio || 'Нема данних'}</Typography>
          <Button
            variant='outlined'
            color='inherit'
            onClick={() => {
              localStorage.clear();
              dispatch(setAuth(false));
              dispatch(setUser(null));
              navigate(ROUTES.main);
            }}
            startIcon={<ExitToApp />}
            style={{ marginLeft: '20px' }}
          >
            Вийти
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
