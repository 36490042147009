import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { BasicDatePicker } from '../ui';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import { WarningModal } from '../modals';
import EditIcon from '@mui/icons-material/Edit';
import { mainInMonthTemplates } from './TemplatesData';
import AddIcon from '@mui/icons-material/Add';
import { EditDescriptionTemplate } from './templates/EditDescriptionTemplate';
import { IDataOpt } from '../ui/SelectSearchReq';
import { useDispatch, useSelector } from 'react-redux';
import { CreateMainInMonth, TypeCreateMainInMonth, UpdateMainInMonth } from '../../store/mainInMonth/actions';
import { AppStore } from '../../store/applicationState';
import moment from 'moment';
import { ITemplate } from '../../store/mainInMonth/types';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { EditTextFieldTemplate } from './templates/EditTextFieldTemplate';

interface Column {
  id: 'name' | 'edit' | 'textField';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Назва блоку (шаблону)', align: 'left' },
  { id: 'edit', label: 'Редагувати', align: 'center' },
  { id: 'textField', label: '(Додати/Прибрати) текстове поле', align: 'center' },
];

interface ComponentProps {
  type: 'new' | 'coppy' | 'edit';
  handleCancel: () => void;
  editData: { id: number; month: number; is_public: boolean; templates: any[]; year: number } | null;
}

export const MainMonthForm: FC<ComponentProps> = ({ type, handleCancel, editData }) => {
  const [comeBackModal, setComeBackModal] = useState<{ id: number } | null>(null);
  const [templates, setTemplates] = useState<ITemplate[]>(
    type === 'edit' ? editData?.templates || mainInMonthTemplates : mainInMonthTemplates
  );
  const [isPublic, setIsPublic] = useState(type === 'edit' ? !!editData?.is_public : false);
  const [errors, setErrors] = useState('');
  const containerRef = useRef(null);

  const dispatch = useDispatch();

  const [month, setMonth] = useState<Date | null>(
    type === 'edit'
      ? moment()
          .month((editData?.month || 0) - 1 || 0)
          .year(editData?.year || new Date().getFullYear())
          .toDate()
      : null
  );
  const [editTextFieldTemplate, setEditTextFieldTemplate] = useState<{
    position: number;
    id: number;
    type: 'new' | 'edit';
  } | null>(null);
  const [editDescriptionTemplate, setEditDescriptionTemplate] = useState<{
    id: number;
    type: 'new' | 'edit';
  } | null>(null);

  const [descriptionTemplate, setDescriptionTemplate] = useState<{
    author: IDataOpt | null;
    description: string;
  } | null>(null);

  const [textFieldTemplate, setTextFieldTemplate] = useState('');

  const textFieldTemplateRef = useRef<MutableRefObject<any> | undefined>(null);
  const descriptionTemplateRef = useRef<MutableRefObject<any> | undefined>(null);


  const { Configurations } = useSelector((store: AppStore) => store);

  useEffect(() => {
    if ((type === 'edit' || type === 'coppy') && editData) {
      const newDate = moment()
        .month(editData.month - 1)
        .year(editData.year)
        .toDate();

      setMonth(type === 'coppy' ? null : newDate);
      setIsPublic(editData?.is_public);
      setTemplates(editData.templates);
      const describeTemplate = editData.templates.find((el) => el.title === 'Опис');
      const authorId = describeTemplate?.month_main_detail?.author_id;
      const authorFio = describeTemplate?.month_main_detail?.author_fio;
      const description = describeTemplate?.month_main_detail?.description;
      setDescriptionTemplate({
        author: {
          id: authorId || 0,
          label: authorFio,
          title: authorFio,
        },
        description: description || '',
      });
    }
  }, [editData, type]);

  const onSubmit = () => {
    if (!month) return setErrors(`Заповнення поля 'Місяць' є обов'язковим.`);
    setErrors('');
    const options: TypeCreateMainInMonth = {
      data: {
        site_id: Configurations.siteId,
        templates,
        month: month.getMonth() + 1,
        year: month.getFullYear(),
        is_public: isPublic ? 1 : 0,
      },
    };
    if (type === 'new' || type === 'coppy') {
      dispatch(
        CreateMainInMonth.request({
          ...options,
          callBack: (success, err) => {
            if (!success) {
              return setErrors(err);
            }
            if (success) handleCancel();
          },
        })
      );
    } else if (type === 'edit') {
      dispatch(
        UpdateMainInMonth.request({
          ...options,
          id: editData?.id,
          callBack: (success, err) => {
            if (!success) {
              return setErrors(err);
            } else handleCancel();
          },
        })
      );
    }
  };

  const addTextField = (targetPosition: number, templateId: number, type: 'new' | 'edit') => {
    setEditTextFieldTemplate({ position: targetPosition, id: templateId, type });
  };

  const removeTextField = (id: number) => {
    setTemplates(templates.filter((el) => el.id !== id));
  };

  const onDragEnd = (result: DropResult, event: any) => {
    if (!result.destination) {
      return;
    }

    const payload = [
      { id: Number(result.draggableId), position: result.destination.index },
      { id: Number(templates[result.destination.index]?.id), position: result.source.index },
    ];

    setTemplates(
      templates.map((template) => {
        if (template.id === payload[0].id) {
          return { ...template, position: payload[0].position };
        } else if (template.id === payload[1].id) {
          return { ...template, position: payload[1].position };
        }
        return template;
      })
    );
  };

  const handleReturnBack = () => {
    let isUnsavedData = false;

    if (editData?.month !== (month?.getMonth() ? month?.getMonth() + 1 : null) || editData?.is_public !== isPublic) {
      isUnsavedData = true;
    }

    isUnsavedData && type === 'edit' ? setComeBackModal({ id: Math.random() + 1 }) : handleCancel();
  };

  if (editDescriptionTemplate?.id)
    return (
      <EditDescriptionTemplate
        templates={templates}
        type={editDescriptionTemplate.type}
        descriptionTemplate={descriptionTemplate}
        descriptionTemplateRef={descriptionTemplateRef}
        setDescriptionTemplate={setDescriptionTemplate}
        setTemplates={setTemplates}
        handleCancel={() => setEditDescriptionTemplate(null)}
      />
    );
  if (editTextFieldTemplate?.id)
    return (
      <EditTextFieldTemplate
        type={editTextFieldTemplate.type}
        templates={templates}
        setTemplates={setTemplates}
        textFieldEditorRef={textFieldTemplateRef}
        handleCancel={() => setEditTextFieldTemplate(null)}
        editTextFieldTemplate={editTextFieldTemplate}
        textFieldTemplate={textFieldTemplate}
        setTextFieldTemplate={setTextFieldTemplate}
      />
    );

  return (
    <Box ref={containerRef}>
      <div >
        {errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
        <Box >
          <Typography component='h1' variant='h5'>
            {type === 'new'
              ? 'Створити нове у місяці'
              : type === 'coppy'
              ? 'Клонувати нове у місяці'
              : 'Редагувати нове у місяці'}
          </Typography>
        </Box>
        <Box
          marginTop={4}
          width={'100%'}
          marginBottom={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <BasicDatePicker
            disabled={true}
            format='MM.yyyy'
            style={{ width: '200px' }}
            id='monthMain'
            views={['year', 'month']}
            required
            label={'Місяць-Рік'}
            value={month}
            error={!!errors}
            onChange={(value) => {
              setMonth(value);
            }}
          />

          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Button variant='contained' color='primary' onClick={onSubmit}>
              Зберегти
            </Button>
            <Button
              variant='contained'
              color='inherit'
              onClick={() => (type === 'new' || type === 'coppy' ? setComeBackModal({ id: 1 }) : handleReturnBack())}
            >
              Повернутися
            </Button>
          </Box>
        </Box>
        <Box display={'flex'} marginBottom={2} alignItems={'center'} gap={8}>
          <FormControlLabel
            control={<Checkbox checked={isPublic} onChange={(e) => setIsPublic(!isPublic)} />}
            label='Публікувати'
            value={isPublic}
          />
          <RemoveRedEyeOutlinedIcon fontSize={'medium'} />
        </Box>
        <TableContainer >
          <Table  stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='documents-list'>
                {(provided) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {templates
                      .sort((a, b) => a.position - b.position)
                      .map((template, i) => (
                        <Draggable draggableId={String(template.id)} index={i} key={`${i}-${template.id}`}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                cursor: 'grab',
                                backgroundColor: snapshot.isDragging ? 'lightgrey' : 'white',
                                ...provided.draggableProps.style,
                              }}
                              hover
                              key={`${i}-${template.id}`}
                            >
                              <TableCell>
                                <Checkbox
                                  onChange={() => {
                                    setTemplates(
                                      templates.map((el) => {
                                        if (el.id === template.id) {
                                          return { ...el, is_public: el.is_public ? 0 : 1 };
                                        }
                                        return el;
                                      })
                                    );
                                  }}
                                  checked={template.is_public ? true : false}
                                />
                                {template.title}
                              </TableCell>
                              <TableCell style={{ textAlign: 'center' }}>
                                <div style={{ cursor: template.is_public ? 'pointer' : 'default' }}>
                                  <IconButton
                                    disabled={!template.is_public}
                                    onClick={() =>
                                      template.title === 'Опис'
                                        ? setEditDescriptionTemplate({ id: template.id, type: 'edit' })
                                        : template.title === 'Текстове поле'
                                        ? addTextField(template.position, template.id, 'edit')
                                        : null
                                    }
                                  >
                                    <EditIcon
                                      htmlColor={template.is_public ? '#3f51b5' : 'gray'}
                                      style={{ fontSize: '1.5rem' }}
                                    />
                                  </IconButton>
                                </div>
                              </TableCell>

                              <TableCell style={{ textAlign: 'center' }}>
                                <IconButton onClick={() => addTextField(template.position, template.id, 'new')}>
                                  <AddIcon style={{ fontSize: '1.5rem' }} />
                                </IconButton>
                                {template.title === 'Текстове поле' ? (
                                  <IconButton onClick={() => removeTextField(template.id)}>
                                    <RemoveIcon style={{ fontSize: '1.5rem' }} />
                                  </IconButton>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
        {comeBackModal ? (
          <WarningModal
            handleApprove={(isApprove) => {
              isApprove && handleCancel();
              setComeBackModal(null);
            }}
            value={`Ви впевнені, що бажаєте повернутись? Всі незбережені зміни будуть втрачені!`}
            open={!!comeBackModal}
            type='comeback'
          />
        ) : null}
      </div>
    </Box>
  );
};
