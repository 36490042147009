import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Alert, AlertTitle } from '@mui/lab';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Copyright } from '../components/Copyright';
import { setAuth, setUser } from '../store/user/actions';
import { getToken } from '../utils/getToken';
import TextField from '@mui/material/TextField';

export const Login: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const values = new FormData(event.currentTarget);
      const email = values.get('email') as string;
      const password = values.get('password') as string;
      if (!email || !password || loading) return null;

      setLoading(true);
      setError('');
      const data = await getToken(email, password);

      if (data.status) {
        return setError(data.detail?.message || 'Щось пiшло не так');
      }

      dispatch(
        setUser({
          cat_id: data.cat_id!,
          exp: data.exp!,
          role: data.role!,
          fio: data.fio!,
          token: data.accessToken!,
          type: data.type!,
          username: data.username!,
          iat: data.iat!,
          id: data.id!,
        })
      );
      dispatch(setAuth(true));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component='main' maxWidth='xs' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Увійти
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Пароль'
            type='password'
            id='password'
            autoComplete='current-password'
          />
          <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
            {loading ? <CircularProgress size={15} /> : 'Увiйти'}
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Помилка</AlertTitle>
            {error}
          </Alert>
        ) : null}
      </Box>
      <Box mt={3}>
        <Copyright />
      </Box>
    </Container>
  );
};
