import React from 'react';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { CreateFile } from '../../../store/officialDocFiles/actions';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

interface PropsType {
  docId: number;
  handleCancel: () => void;
  url?: string;
  type?: number;
}

export const EditFile: React.FC<PropsType> = ({ docId, handleCancel, url, type }) => {
  const [files, setFiles] = React.useState<{ file: File; success: 0 | 1 | 2 }[]>([]);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();


  const changeFile = (idx: number, success: 1 | 2) => {
    setFiles((prev) => prev.map((item, i) => (i === idx ? { ...item, success } : item)));
  };

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file) => ({ file, success: 0 })));
      setLoading(true);
      for (let i = 0; i < acceptedFiles.length; i++) {
        try {
          await new Promise((resolve) => {
            const name = acceptedFiles[i].name.substring(0, acceptedFiles[i].name.lastIndexOf('.'));

            const fd = new FormData();
            fd.append('name', name);
            fd.append('file', acceptedFiles[i]);
            fd.append('docId', String(docId));
            fd.append('typeId', String(type));

            dispatch(
              CreateFile.request({
                data: fd,
                url,
                callBack: (succ) => {
                  if (succ) {
                    changeFile(i, 1);
                  } else {
                    changeFile(i, 2);
                  }
                  resolve(null);
                },
              })
            );
          });
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      }
    },
    [dispatch, docId, url, type]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box>
      <CssBaseline />
      <div >
        <Box  style={{ justifyContent: 'flex-start',gap:'20px' }}>
          <Typography component='h1' variant='h5'>
            Додати файли
          </Typography>
          <Button variant='contained' onClick={handleCancel} color='inherit'>
            Повернутися
          </Button>
        </Box>
        {files.length > 0 ? (
          <div>
            {files.map((item, idx) => {
              return (
                <div key={idx}>
                  <div style={{ marginRight: '10px' }}>{item.file.name}</div>
                  {item.success === 0 ? (
                    <CircularProgress size={15} />
                  ) : (
                    (item.success === 1 && <DoneIcon color='success' />) ||
                    (item.success === 2 && <CloseIcon color='error' />)
                  )}
                </div>
              );
            })}
            <Button
              variant='contained'
              color='primary'
              style={{ margin: '10px 0 0 0', width: '170px' }}
              disabled={loading}
              onClick={() => setFiles([])}
            >
              Завантажити ще
            </Button>
          </div>
        ) : (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {!isDragActive ? 'Перетягніть сюди деякий файл або клацніть, щоб вибрати файл' : 'Можете вiдпускати'}
          </div>
        )}
      </div>
    </Box>
  );
};
