import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import Computer from '@mui/icons-material/Computer';
import AutoAwesomeMosaic from '@mui/icons-material/AutoAwesomeMosaic';
import DeleteIcon from '@mui/icons-material/Delete';
import Publish from '@mui/icons-material/Publish';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { ROUTES } from './routes';
import { CATEGORIES } from './consts';

const defineForm = (appId: number) => {
  if (appId === 5) return 'Форми та зразки';
  if (appId === 2 || appId === 3) return 'Зразки';
  return 'Форми';
};

export const MENU = (appId = 1) => [
  {
    icon: <HomeIcon fontSize='small' />,
    name: 'Головна',
    path: ROUTES.main,
  },
  {
    icon: <HelpIcon fontSize='small' />,
    name: 'Консультації',
    path: ROUTES.consultations,
    category: CATEGORIES.CONSULTATIONS,
  },
  {
    icon: <FormatListNumberedIcon fontSize='small' />,
    name: defineForm(appId),
    path: ROUTES.forms,
    category: CATEGORIES.FORMS,
  },
  {
    icon: <AutoStoriesOutlinedIcon color='inherit' fontSize='small' />,
    name: 'Довідники',
    path: ROUTES.catalogues,
    category: CATEGORIES.CATALOGUES,
  },
  {
    icon: <PlayCircleFilledIcon fontSize='small' />,
    name: 'Відео',
    path: ROUTES.video,
    category: CATEGORIES.VIDEO,
  },
  {
    icon: <MiscellaneousServicesIcon fontSize='small' />,
    name: 'Сервіси',
    path: ROUTES.services,
    category: CATEGORIES.SERVICES,
    isAdmin: true,
  },
  {
    icon: <ArticleIcon fontSize='small' />,
    name: 'Нормативні документи',
    path: ROUTES.npd,
    category: CATEGORIES.NPD,
  },
  {
    icon: <PeopleAltIcon fontSize='small' />,
    name: 'Автори',
    path: ROUTES.authors,
  },
  {
    icon: <GridViewIcon fontSize='small' />,
    name: 'Блоки',
    path: ROUTES.blocks,
  },
  {
    icon: <CalendarMonthOutlinedIcon color='inherit' fontSize='small' />,
    name: 'Календар',
    path: ROUTES.calendar,
  },
  {
    icon: <Computer fontSize='small' />,
    name: 'Конфігуратор',
    path: ROUTES.configurator,
  },
  {
    icon: <Publish fontSize='small' />,
    name: 'Відкриті публікаці',
    path: ROUTES.publications,
  },
  {
    icon: <AutoAwesomeMosaic fontSize='small' />,
    name: 'Фактоїди',
    path: ROUTES.factoids,
  },
  {
    icon: <HandymanOutlinedIcon color='inherit' fontSize='small' />,
    name: 'Віджети',
    path: ROUTES.widgets,
  },
  {
    icon: <ThumbUpOutlinedIcon color='inherit' fontSize='small' />,
    name: 'Оцінки',
    path: ROUTES.ratings,
  },
  {
    icon: <ErrorOutlinedIcon color='inherit' fontSize='small' />,
    name: 'Головне у місяці',
    path: ROUTES.monthMain,
  },
  {
    icon: <DeleteIcon fontSize='small' />,
    name: 'Архів',
    path: ROUTES.archive,
  },
];
