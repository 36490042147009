import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle, Button, CircularProgress, CssBaseline, TextField, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { IForms } from '../../store/forms/types';
import { BasicDatePicker, SelectRubrics, UploadFile } from '../ui';
import { EditorDefault, EditorFeatured } from '../Editors';
import moment from 'moment';
import { Editor } from '@tinymce/tinymce-react';
import { SelectPopupFormFile } from '../ui/SelectPopupFormFile';
import { CATEGORIES } from '../../utils/consts';
import { backlight } from '../Editors/EditorDocum/entities/utils';

interface PropsType {
  type: 'edit' | 'new';
  data?: IForms | null;
  handleSubmit: (data: FormData, callBack: (err?: string) => void) => void;
  handleCancel: () => void;
}

export const FormsForm: React.FC<PropsType> = ({ type, data, handleSubmit, handleCancel }) => {
  const [searchTags, setSearchTags] = React.useState<string>(data?.searchTags || '');
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [title_short, setTitleShort] = React.useState<string>(data?.title_short || '');
  const [publicationAt, setPublicationAt] = React.useState<Date | null>(data?.publication_at || null);
  const [rubricId, setRubricId] = React.useState<string>(data ? String(data.rubricId) : '');
  const [img, setImg] = React.useState<File | string>(data?.img || '');
  const [isPopular, setIsPopular] = React.useState(data?.isPopular || 0);
  const [isMonth, setIsMonth] = React.useState(data?.isMonth || 0);
  const [isPublic, setIsPublic] = React.useState(data?.isPublic || 0);
  const editorDefRef = React.useRef<Editor['editor'] | null>(null);
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState('');

  const editorRef = React.useRef<any | null>(null);
  const { Forms, Rubrics, Configurations } = useSelector((store: AppStore) => store);


  const onSubmit = () => {
    const _body = editorRef.current?.getBody();
    if (_body.children[0].classList.contains('anchor')) {
      editorRef.current.dom.removeClass(_body.childNodes, 'anchor');
    }

    const body = backlight(false, editorRef.current?.getContent());

    const description = editorDefRef.current?.getContent();

    if (!title || !rubricId || !img || !publicationAt) return setError(true);
    setError(false);
    setErrors('');
    const fd = new FormData();
    const siteId = data ? data.siteId : Configurations.siteId;
    fd.append('siteId', String(siteId));
    fd.append('rubricId', rubricId);
    fd.append('title', title);
    fd.append('title_short', title_short);
    fd.append('body', body);
    fd.append('description', description || '');
    fd.append('img', img);
    fd.append('isPopular', String(isPopular));
    fd.append('isMonth', String(isMonth));
    fd.append('isPublic', String(isPublic));
    fd.append('searchTags', searchTags);
    fd.append('publication_at', String(publicationAt));
    fd.append('is_in_cart', '0');

    handleSubmit(fd, (err) => {
      if (err) {
        setErrors(err);
      }
    });
  };

  const defineForm = (appId: number) => {
    if (appId === 5) return 'форму або зразок';
    if (appId === 2 || appId === 3) return 'зразок';
    return 'форму';
  };

  const checkName = () => {
    const word1 = type === 'new' ? 'Створити' : 'Редагувати';
    const word2 = defineForm(Configurations.siteId);
    return `${word1} ${word2}`;
  };

  return (
    <Box>
      <CssBaseline />
      <div >
        <Box >
          <Typography component='h1' variant='h5'>
            {checkName()}
          </Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box >Дата створення {moment(data?.adate).format('DD.MM.YYYY HH:mm')}</Box>
              <Box>Дата редагування {moment(data?.update).format('DD.MM.YYYY HH:mm')}</Box>
            </Box>
          )}
        </Box>
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isPublic} />}
          onChange={() => setIsPublic(isPublic ? 0 : 1)}
          label='Публікувати'
        />
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isPopular} />}
          onChange={() => setIsPopular(isPopular ? 0 : 1)}
          label='Популярне'
        />
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isMonth} />}
          onChange={() => setIsMonth(isMonth ? 0 : 1)}
          label='Форма місяця'
        />
        <TextField
          style={{ marginTop: '7px' }}
          size='small'
          id='outlined-basic'
          label='Теги'
          variant='outlined'
          value={searchTags}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setSearchTags(event.target.value as string);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={error && !title}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />
        <div  style={{ display: 'flex' }}>
          <BasicDatePicker
            id='publicationAt'
            required
            label='Дата публікації'
            value={publicationAt}
            error={(!!errors && !publicationAt) || (error && !publicationAt)}
            onChange={setPublicationAt}
          />
        </div>
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Короткий заголовок'
          variant='outlined'
          value={title_short}
          error={error && !title_short}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitleShort(event.target.value as string);
          }}
        />
        <div style={{ marginTop: '20px' }}>
          <EditorDefault placeholder='Опис' editorRef={editorDefRef} initialValue={data?.description} />
        </div>
        <div style={{ marginTop: '20px' }}>
          <EditorFeatured
            categoryId={CATEGORIES.FORMS}
            placeholder='Текст'
            editorRef={editorRef}
            initialValue={data?.body}
            siteId={Configurations.siteId}
            docId={data?.id}
          />
        </div>
        <SelectRubrics
          required
          title='Рубрикатор'
          value={rubricId}
          error={error && !rubricId}
          
          options={Rubrics.rubrics && Rubrics.rubrics[CATEGORIES.FORMS]}
          onChange={setRubricId}
        />
        <UploadFile
          required
          isImg
          accept='image/*'
          id='img-file'
          title='Зображення'
          value={img}
          error={error && !img}
          
          onChange={setImg}
          hint='Ширина 1100px'
        />
        {type === 'edit' && (
          <SelectPopupFormFile
            
            title='Шаблони'
            files={data?.files || []}
            docId={data?.id || 0}
            url='/form-files'
            type={1}
          />
        )}
        {type === 'edit' && (
          <SelectPopupFormFile
            
            title='Зразки'
            files={data?.files || []}
            docId={data?.id || 0}
            url='/form-files'
            type={2}
          />
        )}
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Forms.loading} onClick={onSubmit}>
            {Forms.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
