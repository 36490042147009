import React from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { SelectOutline, SelectRubrics, SelectSearchReq } from '../ui';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { ISubBlock } from '../../store/main/types';
import { IOptions } from '../../hooks/useSelectedMain';
import { IDataOpt } from '../ui/SelectSearchReq';
import { FileModal } from '../modals';
import EditIcon from '@mui/icons-material/Edit';
import { CATEGORIES } from '../../utils/consts';

const byDefault = { id: 0, name: 'По дефолту', children: [{ id: 0, name: 'По дефолту' }] };
const special = { id: -1, name: 'Спеціальний', children: [{ id: -1, name: 'Спеціальний' }] };

export type OptionType = { [x: string]: IDataOpt[] };

interface ISelectProps {
  type?: 'main' | 'selection' | 'default';
  index: number;
  id: number;
  categoryId: number;
  value?: string;
  image?: string | null;
  onChange: (props: IOptions) => void;
}

const SelectView: React.FC<ISelectProps> = React.memo(({ id, type, index, value, image, categoryId, onChange }) => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const { Configurations } = useSelector((store: AppStore) => store);

  const isSpecial = value === '-1' && index === 0 && type === 'main';

  const getOptions = () => {
    if (Configurations.configurations && index === 0 && type === 'main' && categoryId && id) {
      return [byDefault, ...Configurations.configurations.viewsBlocks, special];
    } else if (Configurations.configurations) {
      return [byDefault, ...Configurations.configurations.viewsBlocks];
    } else {
      return [byDefault];
    }
  };

  const getValue = () => {
    if (value && index === 0 && type === 'main') {
      return value;
    } else if (value !== '-1') {
      return value;
    } else {
      return '0';
    }
  };

  return (
    <>
      <SelectRubrics
        style={{ maxWidth: isSpecial ? '10.5%' : '14%', cursor: 'pointer', marginRight: '5px' }}
        styleSelect={{ minWidth: '250px' }}
        title="Вигляд"
        value={getValue()}
        options={getOptions()}
        onChange={(value) => onChange({ value, index, type: 'viewBlockId' })}
      />
      {isSpecial ? (
        <IconButton aria-label="edit" style={{ color: '#3f51b5' }} onClick={() => setIsModal({ id })}>
          <EditIcon />
        </IconButton>
      ) : null}
      {isModal ? (
        <FileModal
          handleClose={() => setIsModal(null)}
          value={image}
          open={!!isModal}
          id={id}
          categoryId={categoryId}
          handleChange={(value: string) => {
            onChange({ value, index, type: 'image' });
          }}
        />
      ) : null}
    </>
  );
});

interface ISelectIdProps extends ISelectProps {
  categoryId: number;
  error?: boolean;
  options: OptionType;
  handleSearch?: (categoryId: number, itemId: string, callBack?: (resData: IDataOpt[]) => void) => void;
  title: string;
}

const SelectId: React.FC<ISelectIdProps> = React.memo(({ id, index, categoryId, options, onChange, handleSearch }) => {
  return (
    <SelectSearchReq
      style={{ maxWidth: '10%', cursor: 'pointer', marginRight: '20px' }}
      title="ID"
      data={id ? { id, label: String(id) } : null}
      options={options[categoryId] ? options[categoryId].map((el) => ({ id: el.id, label: el.label })) : []}
      handleRequest={(value, callback) => {
        if (handleSearch) {
          handleSearch(categoryId, (value as IDataOpt).title || String(value), callback);
        }
      }}
      onChange={(value) => {
        if (value) {
          onChange({ value: value.label, index, categoryId, type: 'itemId' });
          onChange({
            index,
            type: 'title',
            value: options[categoryId]?.find((el) => el.id === value.id)?.title || value.title || '',
          });
        }
      }}
    />
  );
});

interface ISelectCategoryProps extends ISelectProps {
  categoryId: number;
  error?: boolean;
}

const SelectCategory: React.FC<ISelectCategoryProps> = React.memo(({ id, index, error, categoryId, onChange }) => {
  return (
    <SelectOutline
      id={`${index}-${id}-${categoryId}`}
      title="Категорія"
      value={categoryId ? String(categoryId) : ''}
      error={error && !categoryId}
      style={{ width: '200px', marginRight: '20px' }}
      handleChange={(value) => {
        onChange({ index, type: 'categoryId', value });
      }}
      options={[
        { value: '1', label: 'Консультації' },
        { value: '5', label: 'Нормативні документи' },
        { value: '2', label: 'Форми' },
        { value: '6', label: 'Довідники' },
        { value: '3', label: 'Відео' },
        { value: '4', label: 'Сервіси' },
      ]}
    />
  );
});

interface PropsType {
  type?: 'main' | 'selection' | 'default';
  blocks: ISubBlock[];
  error?: boolean;
  options: OptionType;
  handleChange: (props: IOptions) => void;
  setSubBlocks: React.Dispatch<React.SetStateAction<ISubBlock[]>>;
  handleSearch?: (categoryId: number, itemId: string, callBack?: (resData: IDataOpt[]) => void) => void;
}

const ManageBlocks: React.FC<PropsType> = React.memo(({ type, blocks, error, options, setSubBlocks, handleChange, handleSearch }) => {
  const [draggIndex, setDraggIndex] = React.useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = React.useState<number | null>(null);

  const handleDraggEnd = () => {
    setSubBlocks((prev) => {
      if (prev && draggIndex !== null && hoverIndex !== null) {
        const arr = [...prev];
        const item = arr[draggIndex];
        arr.splice(draggIndex, 1);
        arr.splice(hoverIndex, 0, item);
        return arr;
      }
      return prev;
    });

    setDraggIndex(null);
    setHoverIndex(null);
  };

  const onChangeMemo = React.useCallback(handleChange, [handleChange]);

  return (
    <>
      {blocks.map((it, i) => {
        return (
          <div
            key={i}
            style={{
              display: 'flex',
              width: type === 'selection' ? '1500px' : '1120px',
              paddingTop: '20px',
              cursor: 'move',
              background: i === hoverIndex ? '#554e4e70' : '',
            }}
            draggable
            onDragOver={() => setHoverIndex(i)}
            onDragEnd={() => handleDraggEnd()}
            onDrag={() => !draggIndex && setDraggIndex(i)}
          >
            <TextField
              size="small"
              id={`${i}-${it.itemId}-${it.blockId}-${it.categoryId}-outlined-basic`}
              label="Назва довідника"
              variant="outlined"
              value={it.title}
              error={error && !it.title}
              style={{ marginRight: '20px', width: type === 'selection' ? '250px' : '160px' }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                handleChange({ index: i, type: 'title', value: event.target.value as string });
              }}
            />
            <TextField
              placeholder="Без #"
              size="small"
              id={`${i}-${it.itemId}-${it.blockId}-${it.categoryId}-outlined-basic`}
              label="Якір"
              variant="outlined"
              value={it.anchor || ''}
              error={error && !it.title}
              style={{ marginRight: '20px', width: type === 'selection' ? '250px' : '100px' }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                handleChange({ index: i, type: 'anchor', value: event.target.value as string });
              }}
            />
            {type !== 'selection' ? (
              <SelectCategory index={i} categoryId={it.categoryId} id={it.itemId} error={error} onChange={onChangeMemo} />
            ) : null}
            <SelectId
              index={i}
              categoryId={it.categoryId || CATEGORIES.CATALOGUES}
              id={it.itemId}
              error={error}
              options={options}
              onChange={onChangeMemo}
              handleSearch={handleSearch}
              title={it.title}
            />
            {type !== 'selection' ? (
              <TextField
                size="small"
                id={`${i}-${it.itemId}-${it.blockId}-${it.categoryId}-outlined-basic2`}
                label="Доповнення"
                variant="outlined"
                value={it.label}
                error={error && !it.label}
                style={{ marginRight: '20px', width: '170px' }}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                  handleChange({ index: i, type: 'label', value: event.target.value as string });
                }}
              />
            ) : null}
            {type !== 'selection' ? (
              <SelectView
                index={i}
                type={type}
                id={it.itemId}
                image={it.image}
                categoryId={it.categoryId}
                value={String(it.viewBlockId)}
                onChange={onChangeMemo}
              />
            ) : null}
            <IconButton
              aria-label="add"
              style={{ marginRight: '10px', color: '#3f51b5' }}
              onClick={() => handleChange({ index: null, type: 'add', value: '' })}
            >
              <AddIcon />
            </IconButton>
            {blocks.length > 1 && (
              <>
                <IconButton
                  aria-label="delete"
                  style={{ marginRight: '10px', color: '#e75d5d' }}
                  onClick={() =>
                    handleChange({
                      index: i,
                      type: 'delete',
                      value: String(it.itemId),
                      id: it.id,
                      categoryId: it.categoryId,
                    })
                  }
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton aria-label="arrow" style={{ color: 'silver', cursor: 'move' }}>
                  <OpenWithIcon />
                </IconButton>
              </>
            )}
          </div>
        );
      })}
    </>
  );
});

export { ManageBlocks };
