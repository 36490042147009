import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Pagination from '@mui/material/Pagination';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IAuthor } from '../../store/authors/types';
import { DeleteAuthor, GetAuthors, GetSearchAuthors, resetAuthors } from '../../store/authors/actions';
import { EditAuthor } from './EditAuthor';
import { ROW_PAGE } from '../../utils/consts';
import { WarningModal } from '../modals';
import { useSearch } from '../../hooks/useSearch';

interface Column {
  id: 'id' | 'fio' | 'position' | 'city' | 'photo' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '3%' },
  { id: 'fio', label: 'ПІБ', align: 'left' },
  { id: 'position', label: 'Посада', align: 'left', width: '20%' },
  { id: 'city', label: 'Місто', align: 'left', width: '10%' },
  { id: 'photo', label: 'Фото/Фото для відео', align: 'center', width: '20%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListAuthors: React.FC<PropsType> = () => {
  const [page, setPage] = React.useState(1);
  const [editData, setEditData] = React.useState<{ type: 'edit' | 'new'; data?: IAuthor | null } | null>(null);
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const { Authors, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const { searchFio, handleSearchFio } = useSearch({
    SearchData: GetSearchAuthors,
    ResetData: resetAuthors,
  });

  React.useEffect(() => {
    dispatch(resetAuthors());
  }, [dispatch]);

  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      setEditData(null);
    }
  }, [Sidebar.page]);

  const handleRemove = (id: number) => {
    dispatch(DeleteAuthor.request({ id }));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    handleGetAuthors(newPage);
  };

  const handleGetAuthors = (page: number) => {
    if (!Authors.data) return null;
    if (!Authors.authors) return null;
    const limit = Authors.authors.length / 20 - 1 < page ? Authors.authors.length + 100 : Authors.authors.length;
    if (limit > Authors.authors.length && Authors.authors.length !== Authors.data.total) {
      dispatch(
        GetAuthors.request({
          data: {
            fio: searchFio,
            limit,
          },
        })
      );
    }
  };

  if (editData) {
    return <EditAuthor type={editData.type} data={editData.data} handleCancel={() => setEditData(null)} />;
  }

  return (
    <>
      <Box>
        <Button
          style={{ margin: '0 10px 10px 0' }}
          variant='contained'
          color='primary'
          onClick={() => {
            setEditData({ type: 'new' });
          }}
        >
          Створити автора
        </Button>
        <TextField
          size='small'
          id='outlined-basic'
          label='Пошук по ПІБ'
          variant='outlined'
          value={searchFio}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setPage(1);
            handleSearchFio(event.target.value as string);
          }}
        />
      </Box>
      <TableContainer style={{ maxHeight: 'calc(100vh - 230px)' }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Authors.authors?.slice((page - 1) * ROW_PAGE, page * ROW_PAGE).map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell >{row.fio}</TableCell>
                  <TableCell >{row.position}</TableCell>
                  <TableCell >{row.city}</TableCell>
                  <TableCell  align='center'>
                    {row.photo ? (
                      <AddIcon fontSize='small' style={{ marginRight: '10px' }} />
                    ) : (
                      <RemoveIcon fontSize='small' style={{ marginRight: '10px' }} />
                    )}
                    {row.photoForVideo ? <AddIcon fontSize='small' /> : <RemoveIcon fontSize='small' />}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='edit' onClick={() => setEditData({ type: 'edit', data: row })}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton
                      aria-label='delete'
                      disabled={!!row.isConsult}
                      onClick={() => !row.isConsult && setIsModal({ id: row.id })}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        
        count={Authors.authors ? Math.ceil(Authors.authors.length / ROW_PAGE) : 0}
        page={page}
        onChange={handleChangePage}
      />
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
