import { Box, Button, CircularProgress, CssBaseline, TextField, TextareaAutosize, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { AppStore } from '../../store/applicationState';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { SearchRatings } from '../../store/ratings/actions';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

export const RatingDetails: React.FC = () => {
  const { Configurations } = useSelector((store: AppStore) => store);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [publicationDetail, setPublicationDetail] = useState<any>(null);
  const [searchParams] = useSearchParams();
  const pub_id = searchParams.get('pub_id');
  const type = searchParams.get('type');

  const location = useLocation();
 
  const handleNavigate = () => {
    const params = new URLSearchParams(location.search);
    const queryParams = Object.fromEntries(params.entries());

    const queryString = Object.keys(queryParams).reduce((acc, key, index) => {
      const separator = index === 0 ? '?' : '&';
      return `${acc}${separator}${key}=${queryParams[key]}`;
    }, '');

     navigate(ROUTES.routeChange(`list/${queryString}`));
  };

  useEffect(() => {
    if (id && pub_id && type) {
      dispatch(
        SearchRatings.request({
          data: {
            id: Number(id),
            siteId: Configurations.siteId,
            type: type || '0',
            pub_id: pub_id,
          },
          callBack: (success, detail) => {
            if (success) setPublicationDetail(detail.ratings[0]);
          },
        })
      );
    }
  }, [pub_id, Configurations.siteId, id, dispatch, type]);

  if (!publicationDetail) {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );
  }

  return (
    <Box>
      <CssBaseline />
      <div >
        <Box >
          <Typography component='h2' variant='h6'>
            ОЦІНКА
          </Typography>
          <Button variant='contained' color='inherit' onClick={handleNavigate}>
            Повернутися
          </Button>
        </Box>

        <div >
          <h4>Публікація</h4>
          <TextField
            size='small'
            id='outlined-basic'
            variant='outlined'
            value={publicationDetail?.publication.title || ''}
            
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
            disabled
          />
        </div>
        <div style={{ width: '35%' }} >
          <h4>Тип</h4>
          <TextField
            size='small'
            id='outlined-basic'
            variant='outlined'
            value={publicationDetail?.type || ''}
            
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
            disabled
          />
        </div>

        <div >
          <div>
            <div >
              <h4>ID публікації</h4>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                value={'ID: ' + publicationDetail?.publication.id}
                
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
                disabled
              />
            </div>
          </div>
          <div>
            <div >
              <h4>Дата</h4>
              <TextField
                size='small'
                InputProps={{
                  style: { color: blue[700] },
                }}
                id='outlined-basic'
                variant='outlined'
                value={format(new Date(publicationDetail?.created_at), 'dd.MM.yyyy')}
                disabled
                
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
              />
            </div>
          </div>
        </div>

        <div>
          <div >
            <h4>Оцінка</h4>
            <TextField
              size='small'
              id='outlined-basic'
              variant='outlined'
              disabled
              value={publicationDetail?.rating.rate}
              
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
            />
          </div>
        </div>

        <div >
          <h4>Відповідь</h4>
          <TextField
            size='small'
            id='outlined-basic'
            disabled
            variant='outlined'
            value={publicationDetail?.rating.title}
            
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
          />
        </div>
        <div >
          <h4>Відгук</h4>
          <TextareaAutosize
            value={publicationDetail?.comments}
            aria-label='maximum height'
            maxRows={255}
            style={{
              width: '100%',
              minHeight: '40px',
              borderRadius: '3px',
              padding: '8.5px 14px',
              fontSize: '1rem',
              fontFamily: 'inherit',
            }}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
            disabled
          />
        </div>

        <div >
          <h4>ID користувача</h4>
          <TextField
            size='small'
            InputProps={{
              style: { color: blue[700], caretColor: 'transparent', outline: 'none' },
            }}
            id='outlined-basic'
            variant='outlined'
            value={publicationDetail?.userId3}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
            disabled
          />
        </div>

        <div >
          <h4>ПІБ користувача</h4>
          <TextField
            size='small'
            id='outlined-basic'
            variant='outlined'
            disabled
            value={publicationDetail?.userId3_fio}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
          />
        </div>

        <div >
          <div>
            <div >
              <h4>Телефон користувача</h4>
              <TextField
                size='small'
                InputProps={{
                  style: { color: blue[700], caretColor: 'transparent', outline: 'none' },
                }}
                id='outlined-basic'
                variant='outlined'
                value={publicationDetail?.userId3_phone}
                
                disabled
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
              />
            </div>
          </div>
          <div>
            <div >
              <h4>Email користувача</h4>
              <TextField
                size='small'
                id='outlined-basic'
                variant='outlined'
                value={publicationDetail?.userId3_email}
                
                onChange={(event: React.ChangeEvent<{ value: unknown }>) => {}}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};
