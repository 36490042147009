import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDataOpt } from '../components/ui/SelectSearchReq';
import { AppStore } from '../store/applicationState';
import { SearchDocs } from '../store/officialDoc/actions';
import { IOfficialDoc } from '../store/officialDoc/types';
import { APPROVED } from '../utils/consts';

export const useSearchOfficialDoc = () => {
  const [options, setOptions] = React.useState<IDataOpt[] | undefined>();
  const [officialDoc, setOfficialDoc] = React.useState<IOfficialDoc[]>([]);

  const { Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (Configurations.configurations) {
      const opt = Configurations.configurations.officialDoc
        .filter((it) => it.approved === APPROVED.PUBLISH)
        .map((it) => ({
          id: it.id,
          label: `№ ${it.id}; ${it.title}`,
        }));
      setOptions(opt);
      setOfficialDoc(Configurations.configurations.officialDoc);
    }
  }, [Configurations.configurations]);

  const handleSearch = (value: string | IDataOpt, callBack?: (resData: IDataOpt[]) => void) => {
    if (value) {
      const data: { [x: string]: string } = {};
      if (!Number.isNaN(Number(value))) {
        data['id'] = String((value as unknown as IDataOpt).id) || String(value);
      } else {
        data['title'] = (value as unknown as IDataOpt).title || String(value);
      }
      dispatch(
        SearchDocs.request({
          data,
          callBack: (succ, data) => {
            if (succ && Array.isArray(data) && callBack) {
              const opt = data
                .filter((it) => it.approved === APPROVED.PUBLISH)
                .map((it: IOfficialDoc) => ({
                  id: it.id,
                  label: `№ ${it.id}; ${it.title}`,
                }));
              callBack(opt);
              setOfficialDoc(data);
            } else {
              callBack && callBack(options || []);
            }
          },
        })
      );
    } else {
      callBack && callBack(options || []);
    }
  };

  return {
    options,
    officialDoc,
    handleSearch,
  };
};
