import { IRubricItem } from '../rubrics/types';


export interface TCataloguesState {
    data: { catalogues: ICatalog[]; total: number } | null;
    catalogues: ICatalog[] | null;
    current: ICatalog | null;
    readonly loading: boolean;
    readonly errors?: string | undefined;
  }

export interface ICatalog {
  id: number;
  rubricId: number;
  siteId: number;
  title: string;
  description: string;
  body: string;
  rubrics: IRubricItem[];
  rubricName: string;
  adate: Date;
  update: Date;
  isPublic: number;
  searchTags: string;
  publication_at: Date;
  is_in_cart: number;
  authorFio?: string;
}

enum ActionTypes {
  SET_CATALOGUES = '@@catalogues/SET_CATALOGUES',
  RESET_CATALOGUES = '@@catalogues/RESET_CATALOGUES',

  GET_CATALOGUES_R = '@@catalogues/GET_CATALOGUES_R',
  GET_CATALOGUES_S = '@@catalogues/GET_CATALOGUES_S',
  GET_CATALOGUES_E = '@@catalogues/GET_CATALOGUES_E',

  GET_CATALOG_R = '@@catalogues/GET_CATALOG_R',
  GET_CATALOG_S = '@@catalogues/GET_CATALOG_S',
  GET_CATALOG_E = '@@catalogues/GET_CATALOG_E',

  SEARCH_CATALOGUES_R = '@@catalogues/SEARCH_CATALOGUES_R',
  SEARCH_CATALOGUES_S = '@@catalogues/SEARCH_CATALOGUES_S',
  SEARCH_CATALOGUES_E = '@@catalogues/SEARCH_CATALOGUES_E',

  CREATE_CATALOG_R = '@@catalogues/CREATE_CATALOG_R',
  CREATE_CATALOG_S = '@@catalogues/CREATE_CATALOG_S',
  CREATE_CATALOG_E = '@@catalogues/CREATE_CATALOG_E',

  UPDATE_CATALOG_R = '@@catalogues/UPDATE_CATALOG_R',
  UPDATE_CATALOG_S = '@@catalogues/UPDATE_CATALOG_S',
  UPDATE_CATALOG_E = '@@catalogues/UPDATE_CATALOG_E',

  DELETE_CATALOG_R = '@@catalogues/DELETE_CATALOG_R',
  DELETE_CATALOG_S = '@@catalogues/DELETE_CATALOG_S',
  DELETE_CATALOG_E = '@@catalogues/DELETE_CATALOG_E',
}

export default ActionTypes;
