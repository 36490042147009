import React, { MutableRefObject, useState } from 'react';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import { EditorFeatured } from '../../Editors';
import { CATEGORIES } from '../../../utils/consts';
import { AppStore } from '../../../store/applicationState';
import { WarningModal } from '../../modals';
import { useSelector } from 'react-redux';

interface PropsType {
  type: 'edit' | 'new';
  handleCancel: () => void;
  setTemplates: (templates: any[]) => void;
  templates: any[];
  editTextFieldTemplate: {
    position: number;
    id: number;
    type: 'new' | 'edit';
  } | null;
  textFieldEditorRef: MutableRefObject<any> | undefined;
  textFieldTemplate: string;
  setTextFieldTemplate: (value: string) => void;
}

export const EditTextFieldTemplate: React.FC<PropsType> = ({
  handleCancel,
  setTemplates,
  templates,
  setTextFieldTemplate,
  textFieldEditorRef,
  editTextFieldTemplate,
  type,
}) => {
  const loading = false;
  const error = undefined;
  const errors = '';

  const { Configurations } = useSelector((store: AppStore) => store);
  const [comeBackModal, setComeBackModal] = useState<{ id: number } | null>(null);
  const [isSave, setIsSave] = useState(false);

  const onSubmit = () => {
    setIsSave(true);
    const description = textFieldEditorRef?.current?.getContent();
    setTextFieldTemplate(description);

    if (type === 'edit') {
      setTemplates(
        templates.map((el) => {
          if (el.id === editTextFieldTemplate?.id) {
            return { ...el, month_main_detail: { description } };
          }
          return el;
        })
      );
    } else if (type === 'new') {
      const ids = templates.sort((a, b) => a.id - b.id);
    
      setTemplates([
        ...templates,
        {
          title: 'Текстове поле',
          is_public: 0,
          position: editTextFieldTemplate?.position,
          id: ids[ids.length-1].id + 1,
          month_main_detail: {
            description,
          },
        },
      ]);
      handleCancel();
      setIsSave(false);
    }
  };

  const handleReturnBack = () => {
    const description = textFieldEditorRef?.current?.getContent();
    let isUnsavedData = false;
    if (type === 'new') {
      isUnsavedData = description.trim() !== '';
    } else {
      templates.forEach((el) => {
        if (el.id === editTextFieldTemplate?.id && el.month_main_detail?.description !== description) {
          isUnsavedData = true;
        }
      });
    }

    isUnsavedData && !isSave ? setComeBackModal({ id: Math.random() + 1 }) : handleCancel();
    setIsSave(false);
  };

  const initialValue =
    type === 'edit' ? templates.find((el) => el.id === editTextFieldTemplate?.id).month_main_detail?.description : '';

  return (
    <Box>
      <CssBaseline />
      <div >
        <Typography component='h1' variant='h5'>
          {type === 'edit' ? 'Редагувати' : 'Створити'} шаблон «Текстове поле»
        </Typography>
        <Box>
          <div style={{ marginTop: '10px' }}>
            <EditorFeatured
              categoryId={CATEGORIES.TEMPLATES}
              editorRef={textFieldEditorRef}
              initialValue={initialValue}
              siteId={Configurations.siteId}
            />
          </div>
        </Box>

        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={loading} onClick={onSubmit}>
            {loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleReturnBack}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
        {comeBackModal ? (
          <WarningModal
            handleApprove={(isApprove) => {
              isApprove && handleCancel();
              setComeBackModal(null);
            }}
            value={`Ви дійсно хочете повернутися? У вас є незбережені дані!`}
            open={!!comeBackModal}
            type='comeback'
          />
        ) : null}
      </div>
    </Box>
  );
};
