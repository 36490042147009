import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateVideo,
  DeleteVideo,
  GetVideo,
  GetVideoById,
  SearchVideo,
  TypeCreateVideoR,
  TypeDeleteVideoR,
  TypeGetVideoByIdR,
  TypeGetVideoR,
  TypeSearchVideoR,
  TypeUpdateVideoR,
  UpdateVideo,
} from './actions';
import ActionTypes, { IVideo, TVideoState } from './types';

function* getVideoWorker(action: ReturnType<typeof GetVideo.request>): Generator {
  const { data, callBack } = action.payload as TypeGetVideoR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/video?${query}`,
    })) as TVideoState['data'];
    yield put(GetVideo.success(resp));
  } catch (e) {
    success = false;
    yield put(GetVideo.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getVideoByIdWorker(action: ReturnType<typeof GetVideoById.request>): Generator {
  const { id, callBack } = action.payload as TypeGetVideoByIdR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/video/one/${id}`,
    })) as IVideo;
    yield put(GetVideoById.success(resp));
  } catch (e) {
    success = false;
    yield put(GetVideoById.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* searchVideoWorker(action: ReturnType<typeof SearchVideo.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchVideoR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/video?${query}`,
    })) as TVideoState['data'];
    yield put(SearchVideo.success(resp ? resp.video : []));
  } catch (e) {
    success = false;
    yield put(SearchVideo.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createVideoWorker(action: ReturnType<typeof CreateVideo.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateVideoR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/video/',
    })) as IVideo;
    yield put(CreateVideo.success(resp));
  } catch (e) {
    success = false;
    resp = e;
    yield put(CreateVideo.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateVideoWorker(action: ReturnType<typeof UpdateVideo.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateVideoR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/video/${id}`,
    })) as IVideo;
    yield put(UpdateVideo.success(resp));
  } catch (e) {
    success = false;
    error = e;
    yield put(UpdateVideo.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteVideoWorker(action: ReturnType<typeof DeleteVideo.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteVideoR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/video/${id}`,
    })) as IVideo;
    yield put(DeleteVideo.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteVideo.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_VIDEO_R, getVideoWorker);
  yield takeEvery(ActionTypes.GET_VIDEO_BY_ID_R, getVideoByIdWorker);
  yield takeEvery(ActionTypes.SEARCH_VIDEO_R, searchVideoWorker);
  yield takeEvery(ActionTypes.CREATE_VIDEO_R, createVideoWorker);
  yield takeEvery(ActionTypes.UPDATE_VIDEO_R, updateVideoWorker);
  yield takeEvery(ActionTypes.DELETE_VIDEO_R, deleteVideoWorker);
}

export default function* videoSaga() {
  yield all([fork(watchFetchRequest)]);
}
