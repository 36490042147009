export interface TMainState {
  data: { blocks: IMain[]; total: number } | null;
  titleIdsPairs: ITitleIdsPairs | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IMain {
  id: number;
  siteId: number;
  setdate: Date;
  subBlocks: ISubBlock[];
  isPublic: number;
  adate: Date;
  update: Date;
  anchor: string;
}

export interface ITitleIdsPairs {
  [x: string]: { id: number; title: string }[];
}
export interface ISubBlock {
  id: number;
  blockId: number;
  categoryId: number;
  itemId: number;
  position: number;
  title: string;
  label: string;
  image?: string | null;
  viewBlockId: number;
  isChange?: boolean;
  anchor: string;
}

enum ActionTypes {
  GET_MAIN_BLOCKS_R = '@@main/GET_MAIN_BLOCKS_R',
  GET_MAIN_BLOCKS_S = '@@main/GET_MAIN_BLOCKS_S',
  GET_MAIN_BLOCKS_E = '@@main/GET_MAIN_BLOCKS_E',

  GET_FREE_IDS_R = '@@main/GET_FREE_IDS_R',
  GET_FREE_IDS_S = '@@main/GET_FREE_IDS_S',
  GET_FREE_IDS_E = '@@main/GET_FREE_IDS_E',

  SEARCH_FREE_IDS_R = '@@main/SEARCH_FREE_IDS_R',
  SEARCH_FREE_IDS_S = '@@main/SEARCH_FREE_IDS_S',
  SEARCH_FREE_IDS_E = '@@main/SEARCH_FREE_IDS_E',

  RESET_ITEM_ID_R = '@@main/RESET_ITEM_ID_R',
  RESET_ITEM_ID_S = '@@main/RESET_ITEM_ID_S',
  RESET_ITEM_ID_E = '@@main/RESET_ITEM_ID_E',

  CREATE_MAIN_BLOCK_R = '@@main/CREATE_MAIN_BLOCK_R',
  CREATE_MAIN_BLOCK_S = '@@main/CREATE_MAIN_BLOCK_S',
  CREATE_MAIN_BLOCK_E = '@@main/CREATE_MAIN_BLOCK_E',

  UPDATE_MAIN_BLOCK_R = '@@main/UPDATE_MAIN_BLOCK_R',
  UPDATE_MAIN_BLOCK_S = '@@main/UPDATE_MAIN_BLOCK_S',
  UPDATE_MAIN_BLOCK_E = '@@main/UPDATE_MAIN_BLOCK_E',

  DELETE_SUB_MAIN_BLOCK_R = '@@main/DELETE_SUB_MAIN_BLOCK_R',
  DELETE_SUB_MAIN_BLOCK_S = '@@main/DELETE_SUB_MAIN_BLOCK_S',
  DELETE_SUB_MAIN_BLOCK_E = '@@main/DELETE_SUB_MAIN_BLOCK_E',

  DELETE_MAIN_BLOCK_R = '@@main/DELETE_MAIN_BLOCK_R',
  DELETE_MAIN_BLOCK_S = '@@main/DELETE_MAIN_BLOCK_S',
  DELETE_MAIN_BLOCK_E = '@@main/DELETE_MAIN_BLOCK_E',
}

export default ActionTypes;
