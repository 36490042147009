import React from 'react';
import Button from '@mui/material/Button';
import { OfficialDocList } from './OfficialDocList';
import { setSideBar } from '../../store/sidebar/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { TypeDocList } from './TypeDoc/TypeDocList';
import { PublishersList } from './Publishers/PublishersList';
import { setPublishers } from '../../store/publishers/actions';
import { GetDocs } from '../../store/officialDoc/actions';
import { Statistics } from './Statistics';

type PropsType = {};

export const OfficialDoc: React.FC<PropsType> = () => {
  const [tab, setTab] = React.useState(1);

  const { Sidebar, OfficialDoc } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (e: Event) => {
    //@ts-ignore
    if (e.target?.scrollingElement?.scrollTop > 60 && ref.current) {
      ref.current.style.top = '0';
    } else if (ref.current) {
      ref.current.style.top = '70px';
    }
  };

  const renderRoutePage = () => {
    if (tab === 1) {
      return <OfficialDocList />;
    }
    if (tab === 2) {
      return <PublishersList />;
    }
    if (tab === 3) {
      return <TypeDocList />;
    }
    if (tab === 4) {
      return <Statistics />;
    }

    return null;
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          zIndex: 1001,
          top: 70,
          backgroundColor: 'white',
          paddingTop: '20px',
        }}
        ref={ref}
      >
        <Button
          style={{ marginRight: '15px' }}
          variant={tab === 1 ? 'contained' : 'outlined'}
          color={tab === 1 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(1);
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
            dispatch(GetDocs.request({ data: {} }));
          }}
        >
          Список документів
        </Button>
        <Button
          style={{ marginRight: '15px' }}
          variant={tab === 2 ? 'contained' : 'outlined'}
          color={tab === 2 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(2);
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
            if (OfficialDoc.configurations) {
              dispatch(setPublishers(OfficialDoc.configurations.publishers));
            }
          }}
        >
          Список видавників
        </Button>
        <Button
          variant={tab === 3 ? 'contained' : 'outlined'}
          color={tab === 3 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(3);
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
          }}
        >
          Список типів
        </Button>
        <Button
          style={{ marginLeft: '15px' }}
          variant={tab === 4 ? 'contained' : 'outlined'}
          color={tab === 4 ? 'primary' : 'inherit'}
          onClick={() => {
            setTab(4);
            dispatch(setSideBar({ value: Sidebar.page.value, name: Sidebar.page.name, type: 'list' }));
          }}
        >
          Статистика
        </Button>
      </div>
      <div style={{ paddingTop: 50 }}>{renderRoutePage()}</div>
    </>
  );
};
