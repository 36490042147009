import { IAuthor } from '../authors/types';

export interface TVideoState {
  data: { video: IVideo[]; total: number } | null;
  video: IVideo[] | null;
  current: IVideo | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IVideo {
  id: number;
  rubricId: number;
  siteId: number;
  title: string;
  authors: IAuthor[];
  description: string;
  baseUrl: string;
  urls: { name: string; url: string; time: string }[];
  rubricName: string;
  adate: Date;
  update: Date;
  isPopular: number;
  isRecommendation: number;
  body: string;
  isPublic: number;
  searchTags: string;
  publication_at: Date;
  is_in_cart: number;
  authorFio?: string;
}

enum ActionTypes {
  SET_VIDEO = '@@video/SET_VIDEO',
  RESET_VIDEO = '@@video/RESET_VIDEO',

  GET_VIDEO_R = '@@video/GET_VIDEO_R',
  GET_VIDEO_S = '@@video/GET_VIDEO_S',
  GET_VIDEO_E = '@@video/GET_VIDEO_E',

  GET_VIDEO_BY_ID_R = '@@video/GET_VIDEO_BY_ID_R',
  GET_VIDEO_BY_ID_S = '@@video/GET_VIDEO_BY_ID_S',
  GET_VIDEO_BY_ID_E = '@@video/GET_VIDEO_BY_ID_E',

  SEARCH_VIDEO_R = '@@video/SEARCH_VIDEO_R',
  SEARCH_VIDEO_S = '@@video/SEARCH_VIDEO_S',
  SEARCH_VIDEO_E = '@@video/SEARCH_VIDEO_E',

  CREATE_VIDEO_R = '@@video/CREATE_VIDEO_R',
  CREATE_VIDEO_S = '@@video/CREATE_VIDEO_S',
  CREATE_VIDEO_E = '@@video/CREATE_VIDEO_E',

  UPDATE_VIDEO_R = '@@video/UPDATE_VIDEO_R',
  UPDATE_VIDEO_S = '@@video/UPDATE_VIDEO_S',
  UPDATE_VIDEO_E = '@@video/UPDATE_VIDEO_E',

  DELETE_VIDEO_R = '@@video/DELETE_VIDEO_R',
  DELETE_VIDEO_S = '@@video/DELETE_VIDEO_S',
  DELETE_VIDEO_E = '@@video/DELETE_VIDEO_E',
}

export default ActionTypes;
