import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TVideoState, IVideo } from './types';

export const setVideo = (payload: TVideoState['video']) => action(ActionTypes.SET_VIDEO, payload);
export const resetVideo = () => action(ActionTypes.RESET_VIDEO, {});

const GetVideoAction = new Actions('GET_VIDEO', ActionTypes);
export interface TypeGetVideoR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}
export const GetVideo = {
  request: (payload: TypeGetVideoR) => GetVideoAction.request(payload),
  success: (payload: TVideoState['data']) => GetVideoAction.success(payload || {}),
  error: (message: string) => GetVideoAction.error(message),
};

const SearchVideoAction = new Actions('SEARCH_VIDEO', ActionTypes);
export interface TypeSearchVideoR extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    limit?: number;
    publication_at?: Date | null;
  };
}
export const SearchVideo = {
  request: (payload: TypeSearchVideoR) => SearchVideoAction.request(payload),
  success: (payload: IVideo[]) => SearchVideoAction.success(payload || {}),
  error: (message: string) => SearchVideoAction.error(message),
};

const CreateVideoAction = new Actions('CREATE_VIDEO', ActionTypes);
export interface TypeCreateVideoR extends TDefRequest {
  data: {
    rubricId: number;
    siteId: number;
    authorsIds: number[];
    title: string;
    publication_at: Date | null;
    baseUrl: string;
    description?: string;
    urls?: { url: string; time: string }[];
    isPopular?: number;
    isRecommendation?: number;
    isPublic: number;
    searchTags: string;
    is_in_cart: number;
    body: string;
  };
}
export const CreateVideo = {
  request: (payload: TypeCreateVideoR) => CreateVideoAction.request(payload),
  success: (payload: IVideo) => CreateVideoAction.success(payload || {}),
  error: (message: string) => CreateVideoAction.error(message),
};

const UpdateVideoAction = new Actions('UPDATE_VIDEO', ActionTypes);
export interface TypeUpdateVideoR extends TDefRequest {
  data: {
    rubricId: number;
    siteId: number;
    authorsIds: number[];
    title: string;
    publication_at?: Date | null;
    baseUrl: string;
    description?: string;
    urls?: { url: string; time: string }[];
    isPopular?: number;
    isRecommendation?: number;
    isPublic: number;
    searchTags: string;
    is_in_cart: number;
    body: string;
  };
  id: number;
}
export const UpdateVideo = {
  request: (payload: TypeUpdateVideoR) => UpdateVideoAction.request(payload),
  success: (payload: IVideo) => UpdateVideoAction.success(payload || {}),
  error: (message: string) => UpdateVideoAction.error(message),
};

const DeleteVideoAction = new Actions('DELETE_VIDEO', ActionTypes);
export interface TypeDeleteVideoR extends TDefRequest {
  id: number;
}
export const DeleteVideo = {
  request: (payload: TypeDeleteVideoR) => DeleteVideoAction.request(payload),
  success: (payload: IVideo) => DeleteVideoAction.success(payload || {}),
  error: (message: string) => DeleteVideoAction.error(message),
};

const GetVideoByIdAction = new Actions('GET_VIDEO_BY_ID', ActionTypes);
export interface TypeGetVideoByIdR extends TDefRequest {
  id: number;
}
export const GetVideoById = {
  request: (payload: TypeGetVideoByIdR) => GetVideoByIdAction.request(payload),
  success: (payload: IVideo) => GetVideoByIdAction.success(payload || {}),
  error: (message: string) => GetVideoByIdAction.error(message),
};
