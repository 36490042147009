import { IOfficialDocFile } from '../officialDocFiles/types';
import { IPublisherDoc } from '../publishers/types';

export interface TOfficialDocState {
  statistics: any | null;
  docsTotal:number;
  selectList: IOfficialDocSelect[] | null;
  officialDocUpdate: IOfficialDocUpdate[] | null;
  data: {
    officialDoc: IOfficialDoc[];
    currentPage: number;
    pages: number;
  } | null;
  searchData: {
    officialDoc: IOfficialDoc[];
    currentPage: number;
    pages: number;
    total?: number;
  } | null;
  redactions: { officialDoc: IOfficialDoc[]; total: number } | null;
  editData: { type: 'edit' | 'new'; data: IOfficialDoc } | null;
  configurations: IConfigurationsOfficial | null;
  status: {
    lowStatus: { [x: string]: string };
    approved: { [x: string]: string };
  };
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IConfigurationsOfficial {
  officialDoc: { officialDoc: IOfficialDoc[]; currentPage: number; pages: number };
  publishers: { publishers: IPublisherDoc[]; currentPage: number; pages: number };
  typesDoc: ITypeDoc[];
}

export interface ITypeDoc {
  id: number;
  name: string;
  shortName: string;
  approved: number;
  docCount: number;
}

export interface IOfficialDoc {
  id: number;
  authorId: number;
  authorFio: string;
  creatorFio: string;
  groupId: number;
  typeId: number;
  publisherIds: number[];
  publishers: IPublisherDoc[];
  attachments: IOfficialDocFile[];
  docNum: string;
  docDate: Date;
  title: string;
  type: string;
  body: string;
  searchTags: string;
  startDate: Date;
  endDate: Date | null;
  registNum: string;
  registDate: Date;
  status: number;
  approved: number;
  update: Date;
  warning?:string;
}

export interface IOfficialDocUpdate {
  id: number;
  doc_id?: number;
  title?: string;
  url?: string;
  publication_at?: Date;
}

export interface IOfficialDocSelect {
  id: number;
  title: string;
}

enum ActionTypes {
  RESET_DATA = '@@officialDoc/RESET_DATA',
  SET_EDIT_DOC = '@@officialDoc/SET_EDIT_DOC',
  SET_DOC = '@@officialDoc/SET_DOC',
  SET_DOCS = '@@officialDoc/SET_DOCS',
  SET_SEARCH_DATA = '@@officialDoc/SET_SEARCH_DATA',
  SET_DOCS_TOTAL = '@@officialDoc/SET_DOCS_TOTAL',

  GET_CONFIG_R = '@@officialDoc/GET_CONFIG_R',
  GET_CONFIG_S = '@@officialDoc/GET_CONFIG_S',
  GET_CONFIG_E = '@@officialDoc/GET_CONFIG_E',

  GET_PUBLISHERS_R = '@@officialDoc/GET_PUBLISHERS_R',
  GET_PUBLISHERS_S = '@@officialDoc/GET_PUBLISHERS_S',
  GET_PUBLISHERS_E = '@@officialDoc/GET_PUBLISHERS_E',

  GET_TYPES_DOC_R = '@@officialDoc/GET_TYPES_DOC_R',
  GET_TYPES_DOC_S = '@@officialDoc/GET_TYPES_DOC_S',
  GET_TYPES_DOC_E = '@@officialDoc/GET_TYPES_DOC_E',

  GET_DOCS_R = '@@officialDoc/GET_DOCS_R',
  GET_DOCS_S = '@@officialDoc/GET_DOCS_S',
  GET_DOCS_E = '@@officialDoc/GET_DOCS_E',

  SEARCH_DOCS_R = '@@officialDoc/SEARCH_DOCS_R',
  SEARCH_DOCS_S = '@@officialDoc/SEARCH_DOCS_S',
  SEARCH_DOCS_E = '@@officialDoc/SEARCH_DOCS_E',

  GET_DOCS_OF_SELECT_R = '@@officialDoc/GET_DOCS_OF_SELECT_R',
  GET_DOCS_OF_SELECT_S = '@@officialDoc/GET_DOCS_OF_SELECT_S',
  GET_DOCS_OF_SELECT_E = '@@officialDoc/GET_DOCS_OF_SELECT_E',

  GET_DOCS_STATISTICS_R = '@@officialDoc/GET_DOCS_STATISTICS_R',
  GET_DOCS_STATISTICS_S = '@@officialDoc/GET_DOCS_STATISTICS_S',
  GET_DOCS_STATISTICS_E = '@@officialDoc/GET_DOCS_STATISTICS_E',

  GET_OFFICIAL_DOCS_FROM_UPDATE_R = '@@officialDoc/GET_OFFICIAL_DOCS_FROM_UPDATE_R',
  GET_OFFICIAL_DOCS_FROM_UPDATE_S = '@@officialDoc/GET_OFFICIAL_DOCS_FROM_UPDATE_S',
  GET_OFFICIAL_DOCS_FROM_UPDATE_E = '@@officialDoc/GET_OFFICIAL_DOCS_FROM_UPDATE_E',

  GET_REDACTIONS_R = '@@officialDoc/GET_REDACTIONS_R',
  GET_REDACTIONS_S = '@@officialDoc/GET_REDACTIONS_S',
  GET_REDACTIONS_E = '@@officialDoc/GET_REDACTIONS_E',

  GET_DOC_R = '@@officialDoc/GET_DOC_R',
  GET_DOC_S = '@@officialDoc/GET_DOC_S',
  GET_DOC_E = '@@officialDoc/GET_DOC_E',

  CREATE_GROUP_R = '@@officialDoc/CREATE_GROUP_R',
  CREATE_GROUP_S = '@@officialDoc/CREATE_GROUP_S',
  CREATE_GROUP_E = '@@officialDoc/CREATE_GROUP_E',

  CREATE_DOC_R = '@@officialDoc/CREATE_DOC_R',
  CREATE_DOC_S = '@@officialDoc/CREATE_DOC_S',
  CREATE_DOC_E = '@@officialDoc/CREATE_DOC_E',

  CREATE_OFFICIAL_DOC_OF_UPDATE_R = '@@officialDoc/CREATE_OFFICIAL_DOC_OF_UPDATE_R',
  CREATE_OFFICIAL_DOC_OF_UPDATE_S = '@@officialDoc/CREATE_OFFICIAL_DOC_OF_UPDATE_S',
  CREATE_OFFICIAL_DOC_OF_UPDATE_E = '@@officialDoc/CREATE_OFFICIAL_DOC_OF_UPDATE_E',

  UPDATE_DOC_R = '@@officialDoc/UPDATE_DOC_R',
  UPDATE_DOC_S = '@@officialDoc/UPDATE_DOC_S',
  UPDATE_DOC_E = '@@officialDoc/UPDATE_DOC_E',

  DELETE_DOC_R = '@@officialDoc/DELETE_DOC_R',
  DELETE_DOC_S = '@@officialDoc/DELETE_DOC_S',
  DELETE_DOC_E = '@@officialDoc/DELETE_DOC_E',

  CREATE_TYPE_DOC_R = '@@officialDoc/CREATE_TYPE_DOC_R',
  CREATE_TYPE_DOC_S = '@@officialDoc/CREATE_TYPE_DOC_S',
  CREATE_TYPE_DOC_E = '@@officialDoc/CREATE_TYPE_DOC_E',

  UPDATE_TYPE_DOC_R = '@@officialDoc/UPDATE_TYPE_DOC_R',
  UPDATE_TYPE_DOC_S = '@@officialDoc/UPDATE_TYPE_DOC_S',
  UPDATE_TYPE_DOC_E = '@@officialDoc/UPDATE_TYPE_DOC_E',

  DELETE_TYPE_DOC_R = '@@officialDoc/DELETE_TYPE_DOC_R',
  DELETE_TYPE_DOC_S = '@@officialDoc/DELETE_TYPE_DOC_S',
  DELETE_TYPE_DOC_E = '@@officialDoc/DELETE_TYPE_DOC_E',

  DELETE_OFFICIAL_DOCS_FROM_UPDATE_R = '@@officialDoc/DELETE_OFFICIAL_DOCS_FROM_UPDATE_R',
  DELETE_OFFICIAL_DOCS_FROM_UPDATE_S = '@@officialDoc/DELETE_OFFICIAL_DOCS_FROM_UPDATE_S',
  DELETE_OFFICIAL_DOCS_FROM_UPDATE_E = '@@officialDoc/DELETE_OFFICIAL_DOCS_FROM_UPDATE_E',

  UPDATE_OFFICIAL_DOCS_FROM_UPDATE_R = '@@officialDoc/UPDATE_OFFICIAL_DOCS_FROM_UPDATE_R',
  UPDATE_OFFICIAL_DOCS_FROM_UPDATE_S = '@@officialDoc/UPDATE_OFFICIAL_DOCS_FROM_UPDATE_S',
  UPDATE_OFFICIAL_DOCS_FROM_UPDATE_E = '@@officialDoc/UPDATE_OFFICIAL_DOCS_FROM_UPDATE_E',
}

export default ActionTypes;
