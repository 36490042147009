import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import TableContainer from '@mui/material/TableContainer/TableContainer';
import Table from '@mui/material/Table/Table';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import TableBody from '@mui/material/TableBody/TableBody';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppStore } from '../../store/applicationState';
import { DeleteVideo, GetVideo, resetVideo, SearchVideo } from '../../store/video/actions';
import { useSearch } from '../../hooks/useSearch';
import { IVideo } from '../../store/video/types';
import { GetCart } from '../../store/cart/actions';
import { useSearchAuthors } from '../../hooks/useSearchAuthors';
import { ROUTES } from '../../utils/routes';
import { SelectSearchReq } from '../../components/ui';
import { WarningModal } from '../../components/modals';

interface Column {
  id:
    | 'id'
    | 'title'
    | 'author'
    | 'authorFio'
    | 'baseUrl'
    | 'urls'
    | 'rubric'
    | 'isPopular'
    | 'isRecomm'
    | 'delete'
    | 'isPublic';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: '№', align: 'center', width: '6%' },
  { id: 'title', label: 'Заголовок', align: 'left' },
  { id: 'author', label: 'Автор', align: 'left', width: '10%' },
  {
    id: 'authorFio',
    label: 'Редактор',
    align: 'left',
  },
  { id: 'baseUrl', label: 'Базовий URL', align: 'left' },
  { id: 'urls', label: 'Фрагменти', align: 'center', width: '17%' },
  {
    id: 'isPublic',
    label: 'Публікція',
    align: 'center',
  },
  {
    id: 'isPopular',
    label: 'Популярне',
    align: 'center',
  },
  {
    id: 'isRecomm',
    label: 'Рекомендація',
    align: 'center',
  },
  {
    id: 'rubric',
    label: 'Рубрика',
    align: 'left',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const ListVideo: React.FC<PropsType> = () => {
  const [isModal, setIsModal] = React.useState<{ id: number } | null>(null);
  const [videos, setVideos] = useState<IVideo[]>();

  const { Video, Configurations } = useSelector((store: AppStore) => store);
  const { options, handleSearchAuthors } = useSearchAuthors();
  const dispatch = useDispatch();

  const {
    loading,
    searchId,
    searchAuthor,
    searchTitle,
    resetState,
    handleSearchId,
    handleSearchTitle,
    handleSearchAuthor,
  } = useSearch({
    SearchData: SearchVideo,
    ResetData: resetVideo,
  });

  React.useEffect(() => {
    if (Video.video) setVideos(Video.video.filter((el) => !el.is_in_cart));
  }, [Video]);

  React.useEffect(() => {
    dispatch(resetVideo());
  }, [dispatch]);

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`list/${path}?site_id=${Configurations.siteId}`));
    };

  const handleRemove = (id: number) => {
    dispatch(
      DeleteVideo.request({
        id,
        callBack: (success) => {
          if (success) {
            dispatch(
              GetCart.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
            dispatch(
              GetVideo.request({
                data: {
                  siteId: Configurations.siteId,
                },
              })
            );
          }
        },
      })
    );
  };

  return (
    <>
      <Box >
        <Button
          
          variant='contained'
          color='primary'
          onClick={handleChangeRoute('new')}
        >
          Створити відео
        </Button>
        <TextField
          size='small'
          id='outlined-basic-id'
          label='Пошук по ID'
          variant='outlined'
          value={searchId}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleSearchId(event.target.value as string);
          }}
        />
        <TextField
          
          size='small'
          id='outlined-basic-title'
          label='Пошук по заголовку'
          variant='outlined'
          value={searchTitle}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            handleSearchTitle(event.target.value as string);
          }}
        />
        <SelectSearchReq
          style={{ maxWidth: '200px' }}
          title='Автор'
          
          onChange={(value) => {
            handleSearchAuthor(value);
          }}
          options={options}
          handleRequest={handleSearchAuthors}
          value={searchAuthor?.label}
        />
        <Button  variant='contained' color='primary' onClick={resetState}>
          {loading ? <CircularProgress color='inherit' size={25} /> : 'Очистити фільтри'}
        </Button>
      </Box>
      <TableContainer >
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {videos?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>
                    <Link sx={{ cursor: 'pointer' }} onClick={handleChangeRoute(row.id)}>
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell >{row.title}</TableCell>
                  <TableCell >
                    {row.authors && row.authors.length > 0
                      ? row.authors.map((author) => (
                          <div key={`${i}-${author.id}`}>
                            <span>{author.fio}</span>
                            <br />
                          </div>
                        ))
                      : ''}
                  </TableCell>
                  <TableCell >{row.authorFio || ''}</TableCell>
                  <TableCell >{row.baseUrl}</TableCell>
                  <TableCell align='center' >
                    {row.urls?.length || 0}
                  </TableCell>
                  <TableCell align='center' >
                    {row.isPublic ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align='center' >
                    {row.isPopular ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell align='center' >
                    {row.isRecommendation ? <DoneIcon /> : ''}
                  </TableCell>
                  <TableCell >{row.rubricName}</TableCell>
                  <TableCell align='center'>
                    <IconButton aria-label='delete' onClick={() => setIsModal({ id: row.id })}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.id}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
