import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import { setPublishers } from '../publishers/actions';
import { IPublisherDoc } from '../publishers/types';
import {
  CreateDoc,
  CreateGroup,
  CreateOfficialDocOfUpdate,
  CreateTypeDoc,
  DeleteDoc,
  DeleteOfficialDocFromUpdate,
  DeleteTypeDoc,
  GetConfig,
  GetDoc,
  GetDocs,
  GetDocsOfSelect,
  GetDocsStatistic,
  GetOfficialDocOfUpdate,
  GetPublishers,
  GetRedactions,
  GetTypesDoc,
  SearchDocs,
  TypeCreateDocR,
  TypeCreateGroupR,
  TypeCreateOfficialDocUpdateR,
  TypeCreateTypeDocR,
  TypeDeleteDocR,
  TypeDeleteDocS,
  TypeDeleteOfficialDocR,
  TypeDeleteTypeDocR,
  TypeGetConfigR,
  TypeGetDocR,
  TypeGetDocsR,
  TypeGetDocsStatisticsR,
  TypeGetOfficialDocOfUpdateR,
  TypeGetPublishersR,
  TypeGetRedactionsR,
  TypeGetTypesDocR,
  TypeSearchDocsR,
  TypeUpdateDocR,
  TypeUpdateTypeDocOfUpdateR,
  TypeUpdateTypeDocR,
  UpdateDoc,
  UpdateOfficialDocOfUpdate,
  UpdateTypeDoc,
  setDocsTotal,
} from './actions';
import ActionTypes, {
  IOfficialDoc,
  IConfigurationsOfficial,
  ITypeDoc,
  TOfficialDocState,
  IOfficialDocUpdate,
} from './types';

function* getConfigWorker(action: ReturnType<typeof GetConfig.request>): Generator {
  const { callBack } = action.payload as TypeGetConfigR;

  let success = true;

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/configurations/officialdoc`,
    })) as IConfigurationsOfficial;
    yield put(GetConfig.success(resp));
    yield put(setPublishers(resp.publishers));
  } catch (e) {
    success = false;
    yield put(GetConfig.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getDocsWorker(action: ReturnType<typeof GetDocs.request>): Generator {
  const { data, callBack } = action.payload as TypeGetDocsR;

  let success = true;
  const query = buildQuery(data);
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc?${query}`,
    })) as TOfficialDocState['data'];
    yield put(GetDocs.success(resp));
  } catch (e) {
    success = false;
    yield put(GetDocs.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp?.officialDoc);
  }
}

function* getDocsOfSelectWorker(action: ReturnType<typeof GetDocsOfSelect.request>): Generator {
  const { data, callBack } = action.payload as TypeGetDocsR;

  let success = true;
  const query = buildQuery(data);
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc/select?${query}`,
    })) as TOfficialDocState['selectList'];
    yield put(GetDocsOfSelect.success(resp));
  } catch (e) {
    success = false;
    yield put(GetDocsOfSelect.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getDocsStatisticsWorker(action: ReturnType<typeof GetDocsStatistic.request>): Generator {
  const { data, callBack } = action.payload as TypeGetDocsStatisticsR;

  let success = true;
  const query = buildQuery(data);
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc/statistics?${query}`,
    })) as TOfficialDocState['statistics'];
    yield put(GetDocsStatistic.success(resp));
  } catch (e) {
    success = false;
    yield put(GetDocsStatistic.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* searchDocsWorker(action: ReturnType<typeof SearchDocs.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchDocsR;

  let success = true;
  const query = buildQuery(data);
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc?${query}`,
    })) as TOfficialDocState['data'];
    yield put(SearchDocs.success(resp));
  } catch (e) {
    success = false;
    yield put(SearchDocs.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp?.officialDoc);
  }
}

function* getDocWorker(action: ReturnType<typeof GetDoc.request>): Generator {
  const { id, callBack } = action.payload as TypeGetDocR;

  let success = true;
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc/doc/${id}`,
    })) as IOfficialDoc;
    yield put(GetDoc.success(resp));
  } catch (e) {
    success = false;
    yield put(GetDoc.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* getPublishersWorker(action: ReturnType<typeof GetPublishers.request>): Generator {
  const { data, callBack } = action.payload as TypeGetPublishersR;

  let success = true;
  const query = buildQuery(data);
  let resp: { publishers: IPublisherDoc[] };
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc/publishers?${query}`,
    })) as { publishers: IPublisherDoc[] };
    yield put(GetPublishers.success(resp.publishers));
  } catch (e) {
    success = false;
    yield put(GetPublishers.error(e as string));
  } finally {
    //@ts-ignore
    if (callBack) yield call(callBack, success, resp.publishers);
  }
}

function* getTypesDocWorker(action: ReturnType<typeof GetTypesDoc.request>): Generator {
  const { callBack } = action.payload as TypeGetTypesDocR;

  let success = true;

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc/typedoc`,
    })) as ITypeDoc[];
    yield put(GetTypesDoc.success(resp));
  } catch (e) {
    success = false;
    yield put(GetTypesDoc.error(e as string));
  } finally {
    //@ts-ignore
    if (callBack) yield call(callBack, success);
  }
}

function* getRedactionsWorker(action: ReturnType<typeof GetRedactions.request>): Generator {
  const { data, callBack } = action.payload as TypeGetRedactionsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc?${query}`,
    })) as TOfficialDocState['data'];
    yield put(GetRedactions.success(resp));
  } catch (e) {
    success = false;
    yield put(GetRedactions.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createGroupWorker(action: ReturnType<typeof CreateGroup.request>): Generator {
  const { callBack } = action.payload as TypeCreateGroupR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: '/officialdoc/group',
    })) as { id: number; document: IOfficialDoc };
    yield put(CreateGroup.success(resp));
  } catch (e: any) {
    success = false;
    yield put(CreateGroup.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp?.id);
  }
}

function* createDocWorker(action: ReturnType<typeof CreateDoc.request>): Generator {
  const { id, callBack } = action.payload as TypeCreateDocR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/officialdoc/redaction/${id}`,
    })) as { id: number; document: IOfficialDoc };
    yield put(CreateDoc.success(resp));
  } catch (e: any) {
    success = false;
    yield put(CreateDoc.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp?.id);
  }
}

function* updateDocWorker(action: ReturnType<typeof UpdateDoc.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateDocR;

  let success = true;
  let error = null;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/officialdoc/doc/${id}`,
    })) as IOfficialDoc;
    yield put(UpdateDoc.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateDoc.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error || resp?.warning);
  }
}

function* deleteDocWorker(action: ReturnType<typeof DeleteDoc.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteDocR;

  let success = true;
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'delete',
      path: `/officialdoc/doc/${id}`,
    })) as TypeDeleteDocS;
    yield put(DeleteDoc.success(resp.doc));
    yield put(setDocsTotal(resp.docsTotal));
  } catch (e) {
    success = false;
    yield put(DeleteDoc.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createTypeDocWorker(action: ReturnType<typeof CreateTypeDoc.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateTypeDocR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/officialdoc/typedoc',
    })) as ITypeDoc;
    yield put(CreateTypeDoc.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(CreateTypeDoc.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateTypeDocWorker(action: ReturnType<typeof UpdateTypeDoc.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateTypeDocR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/officialdoc/typedoc/${id}`,
    })) as ITypeDoc;
    yield put(UpdateTypeDoc.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateTypeDoc.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteTypeDocWorker(action: ReturnType<typeof DeleteTypeDoc.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteTypeDocR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/officialdoc/typedoc/${id}`,
    })) as ITypeDoc;
    yield put(DeleteTypeDoc.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteTypeDoc.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

//Doc Updates
function* getOfficialDocUpdatesWorker(action: ReturnType<typeof GetOfficialDocOfUpdate.request>): Generator {
  const { data, callBack } = action.payload as TypeGetOfficialDocOfUpdateR;

  let success = true;
  const query = buildQuery(data);
  let resp;
  try {
    resp = (yield call(callApi, {
      method: 'get',
      path: `/official-doc-updates?${query}`,
    })) as IOfficialDocUpdate;
    yield put(GetOfficialDocOfUpdate.success(resp));
  } catch (e) {
    success = false;
    yield put(GetOfficialDocOfUpdate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* deleteOfficialDocFromUpdateWorker(action: ReturnType<typeof DeleteOfficialDocFromUpdate.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteOfficialDocR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/official-doc-updates/${id}`,
    })) as IOfficialDocUpdate;
    yield put(DeleteOfficialDocFromUpdate.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteOfficialDocFromUpdate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createOfficialDocOfUpdateWorker(action: ReturnType<typeof CreateOfficialDocOfUpdate.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateOfficialDocUpdateR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/official-doc-updates',
    })) as IOfficialDocUpdate;
    yield put(CreateOfficialDocOfUpdate.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(CreateOfficialDocOfUpdate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* updateOfficialDocOfUpdateWorker(action: ReturnType<typeof UpdateOfficialDocOfUpdate.request>): Generator {
  let success = true;
  let error = null;
  const { data, callBack, id } = action.payload as TypeUpdateTypeDocOfUpdateR;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: '/official-doc-updates/' + id,
    })) as IOfficialDocUpdate;
    yield put(UpdateOfficialDocOfUpdate.success(resp));
  } catch (e) {
    success = false;
    yield put(UpdateOfficialDocOfUpdate.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CONFIG_R, getConfigWorker);
  yield takeEvery(ActionTypes.GET_DOC_R, getDocWorker);
  yield takeEvery(ActionTypes.GET_DOCS_R, getDocsWorker);
  yield takeEvery(ActionTypes.GET_DOCS_OF_SELECT_R, getDocsOfSelectWorker);
  yield takeEvery(ActionTypes.GET_DOCS_STATISTICS_R, getDocsStatisticsWorker);
  yield takeEvery(ActionTypes.GET_OFFICIAL_DOCS_FROM_UPDATE_R, getOfficialDocUpdatesWorker);
  yield takeEvery(ActionTypes.SEARCH_DOCS_R, searchDocsWorker);
  yield takeEvery(ActionTypes.GET_REDACTIONS_R, getRedactionsWorker);
  yield takeEvery(ActionTypes.GET_PUBLISHERS_R, getPublishersWorker);
  yield takeEvery(ActionTypes.GET_TYPES_DOC_R, getTypesDocWorker);
  yield takeEvery(ActionTypes.CREATE_GROUP_R, createGroupWorker);
  yield takeEvery(ActionTypes.CREATE_DOC_R, createDocWorker);
  yield takeEvery(ActionTypes.CREATE_OFFICIAL_DOC_OF_UPDATE_R, createOfficialDocOfUpdateWorker);
  yield takeEvery(ActionTypes.UPDATE_OFFICIAL_DOCS_FROM_UPDATE_R, updateOfficialDocOfUpdateWorker);
  yield takeEvery(ActionTypes.UPDATE_DOC_R, updateDocWorker);
  yield takeEvery(ActionTypes.DELETE_DOC_R, deleteDocWorker);
  yield takeEvery(ActionTypes.CREATE_TYPE_DOC_R, createTypeDocWorker);
  yield takeEvery(ActionTypes.UPDATE_TYPE_DOC_R, updateTypeDocWorker);
  yield takeEvery(ActionTypes.DELETE_TYPE_DOC_R, deleteTypeDocWorker);
  yield takeEvery(ActionTypes.DELETE_OFFICIAL_DOCS_FROM_UPDATE_R, deleteOfficialDocFromUpdateWorker);
}

export default function* officialDocSaga() {
  yield all([fork(watchFetchRequest)]);
}
