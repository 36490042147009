import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateForms,
  DeleteForms,
  GetForm,
  GetForms,
  SearchForms,
  TypeCreateFormsR,
  TypeDeleteFormsR,
  TypeGetFormR,
  TypeGetFormsR,
  TypeSearchFormsR,
  TypeUpdateFormsR,
  UpdateForms,
} from './actions';
import ActionTypes, { IForms, TFormsState } from './types';

function* getFormsWorker(action: ReturnType<typeof GetForms.request>): Generator {
  const { data, callBack } = action.payload as TypeGetFormsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/forms?${query}`,
    })) as TFormsState['data'];
    yield put(GetForms.success(resp));
  } catch (e) {
    success = false;
    yield put(GetForms.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getFormWorker(action: ReturnType<typeof GetForm.request>): Generator {
  const { id, callBack } = action.payload as TypeGetFormR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/forms/one/${id}`,
    })) as IForms;
    yield put(GetForm.success(resp));
  } catch (e) {
    success = false;
    yield put(GetForm.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* searchFormsWorker(action: ReturnType<typeof SearchForms.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchFormsR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/forms?${query}`,
    })) as TFormsState['data'];
    yield put(SearchForms.success(resp ? resp.forms : []));
  } catch (e) {
    success = false;
    yield put(SearchForms.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createFormsWorker(action: ReturnType<typeof CreateForms.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateFormsR;

  let success = true;
  let resp = null;
  try {
    resp = (yield call(callApi, {
      method: 'post',
      data,
      path: '/forms/',
      isFormData: true,
    })) as IForms;
    yield put(CreateForms.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(CreateForms.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateFormsWorker(action: ReturnType<typeof UpdateForms.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateFormsR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data,
      path: `/forms/${id}`,
      isFormData: true,
    })) as IForms;
    yield put(UpdateForms.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateForms.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteFormsWorker(action: ReturnType<typeof DeleteForms.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteFormsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/forms/${id}`,
    })) as IForms;
    yield put(DeleteForms.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteForms.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_FORMS_R, getFormsWorker);
  yield takeEvery(ActionTypes.GET_FORM_R, getFormWorker);
  yield takeEvery(ActionTypes.SEARCH_FORMS_R, searchFormsWorker);
  yield takeEvery(ActionTypes.CREATE_FORMS_R, createFormsWorker);
  yield takeEvery(ActionTypes.UPDATE_FORMS_R, updateFormsWorker);
  yield takeEvery(ActionTypes.DELETE_FORMS_R, deleteFormsWorker);
}

export default function* formsSaga() {
  yield all([fork(watchFetchRequest)]);
}
