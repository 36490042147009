import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
// import mammoth from 'mammoth';
import Pagination from '@mui/material/Pagination';
import { API_URL_SYSTEM, CDN_URL, URI, configSites } from '../../../config';
// import { transformElement } from './entities/textTransform';
import { ACCEPT, anchorIcon, HEIGHT } from './entities/consts';
import {
  backlight,
  callTimeout,
  defineMeaning,
  defineScrollDirection,
  handleConverteQuotes,
  handleSelectedMeaning,
  parseHTML,
  handleScrollMeaning,
  // setUuid,
  uuid,
} from './entities/utils';
import { callApi } from '../../../utils/callApi';
import { IMeaning } from './entities/EditorTypes';

interface EditorProps {
  editorRef?: React.MutableRefObject<Editor['editor'] | null>;
  initialValue?: string;
  docId?: number;
}

export const EditorDocum: React.FC<EditorProps> = ({ editorRef, initialValue, docId }) => {
  const [pages, setPages] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [isScroll, setIsScroll] = React.useState(false);
  const [paragraphId, setParagraphId] = React.useState('');
  const [selectedMeaningId, setSelectedMeaningId] = React.useState<string>('');
  const [prevScrollY, setPrevScrollY] = useState<number>(0);
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up'>('down');
  const [initVal, setInitVal] = useState(initialValue);

  const meaningRef = React.useRef<IMeaning[]>([]);

  const docIdRef = React.useRef({ docId: 0 });
  const emblem =
    '<p><img ' +
    `src="${CDN_URL.trim()}/media/images/ukraine.jpg"` +
    'width="80" ' +
    'height="94"' +
    ' alt="" ' +
    'style="display: block; ' +
    'margin-left: auto; ' +
    'margin-right: auto;" ' +
    '/></p>';

  const [showActiveQuotes, setShowActiveQuotes] = useState(false);

  React.useEffect(() => {
    if (docIdRef.current && docId) {
      docIdRef.current.docId = docId;
    }
  }, [docId]);

  React.useEffect(() => {
    if (initialValue) {
      const data = defineMeaning(initialValue);
      meaningRef.current = data;
    }
    setInitVal(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    setSelectedMeaningId(paragraphId);
  }, [paragraphId, editorRef]);

  React.useEffect(() => {
    if (page && isScroll) {
      const top = (page - 1) * 870;
      //@ts-ignore
      editorRef.current?.getWin().scrollTo({ top });
      setIsScroll(false);
    }
  }, [page, isScroll, editorRef]);

  // eslint-disable-next-line
  const debounceSave = React.useCallback(
    callTimeout(() => {
      const value = editorRef?.current?.getContent();
      if (value) {
        const meaning = defineMeaning(value);
        meaningRef.current = meaning;
      }
    }, 1000),
    []
  );

  const handleChangePage = (page: number) => {
    setPage(page);
    setIsScroll(true);
  };

  const handleButtonClick = (e: any) => {
    e.stopPropagation();
    handleConverteQuotes(editorRef, setShowActiveQuotes);
  };

  const defineScrollDirectionHandler = defineScrollDirection(prevScrollY, setPrevScrollY, setScrollDirection);

  useEffect(() => {
    editorRef?.current?.on('ScrollContent', defineScrollDirectionHandler);
    return () => {
      editorRef?.current?.off('ScrollContent', defineScrollDirectionHandler);
    };
  }, [defineScrollDirectionHandler, editorRef]);

  const handleWindowClick = (e: any) => {
    const content = editorRef?.current?.getContent();
    const tempElement = document.createElement('html');
    tempElement.innerHTML = content || '';
    if (!showActiveQuotes && content?.includes('<mark>')) {
      const body = backlight(false, tempElement.innerHTML);
      const editor = editorRef?.current;
      if (editor) editor.setContent(body);
    } else {
      setShowActiveQuotes(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 128px)',
      }}
    >
      <Meaning
        meaning={meaningRef.current}
        setParagraphId={setParagraphId}
        selectedMeaning={selectedMeaningId}
        scrollDirection={scrollDirection}
        editorRef={editorRef}
      />
      <div>
        <div className="tox-tbtn" onClick={handleButtonClick}>
          « »
        </div>
        <Editor
          onInit={(evt, editor) => editorRef && (editorRef.current = editor)}
          apiKey="uzm54hzb90l1dcm92ia4tfjp6aj0krmgjak7eof1jhyd17pv"
          initialValue={backlight(false, initVal) ? backlight(false, initVal) : emblem}
          init={{
            convert_urls: true,
            urlconverter_callback: function (url, node, on_save, name) {
              const regexName = /\b(\w*\.expertus\.com\.ua)\/law\w*\b/g;
              const regexInt = /^\d+/g;
              if (url.match(regexName)) {
                url = url.replace(
                  // eslint-disable-next-line
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?/gm,
                  ''
                );
                url = url.replace(/\/laws\//gm, '/law/');
              } else if (url.match(regexInt)) {
                url = `/law/${url}`;
              }

              return url;
            },
            link_context_toolbar: true,
            target_list: [
              { title: 'Нова сторінка', value: '_blank' },
              { title: 'Та сама сторінка', value: '_self' },
            ],
            link_default_protocol: 'https',
            language: 'uk',
            height: '100%',
            width: '900px',
            plugins:
              'powerpaste advcode visualblocks image link codesample table charmap hr lists checklist ' +
              'imagetools advtable template lists fullscreen',
            toolbar:
              'undo redo | code | blocks | styleselect | anchorbutton | ' +
              'bold italic underline | alignleft aligncenter alignright alignjustify | ' +
              'charmap | forecolor backcolor | imageoptions  link | alignleft aligncenter alignright alignjustify' +
              ' | indent outdent | table tableinsertdialog tablecellprops tableprops advtablerownumbering ' +
              '| fullscreen',
            table_toolbar:
              'tableprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | ' +
              'tableinsertcolbefore tableinsertcolafter tabledeletecol | tabledelete | alignmentLeft alignmentRight',
            menu: {
              custom: {
                title: 'Завантажити',
                items: 'basicItem | basicItemPart',
              },
            },
            menubar: 'insert format table custom',
            quickbars_selection_toolbar: false,
            verify_html: true,
            relative_urls: false,
            extended_valid_elements: 'a[!href|rel|target|class]',
            forced_root_block: 'p',
            style_formats: [
              { title: 'Абзац', block: 'p' },
              { title: 'Заголовок', block: 'h2' },
              { title: 'Підзаголовок', block: 'h3' },
              { title: 'Шапка документа', block: 'span', classes: 'head-doc' },
              { title: 'Примітки', block: 'span', classes: 'notes' },
            ],
            table_class_list: [
              { title: 'По дефолту', value: ' ' },
              { title: 'Суцільна межа', value: 'table-solid-borders' },
              { title: 'Без меж', value: 'table-no-borders' },
              { title: 'Табл. Праворуч', value: 'table-alignment-right' },
            ],
            table_cell_class_list: [
              { title: 'По дефолту', value: ' ' },
              { title: 'Суцільна межа', value: 'table-solid-borders' },
              { title: 'Без меж', value: 'table-no-borders' },
            ],
            table_default_styles: {
              width: '100%',
            },
            content_style: `
              .mce-item-table:not([border]),
              .mce-item-table:not([border]) caption, 
              .mce-item-table:not([border]) td, 
              .mce-item-table:not([border]) th {
                border: 1px solid #000;
              }
              table,
              caption,
              td,
              th {
                vertical-align: initial;
              }
              table {
                width: 100%;
                height: auto;
              }
              .mce-content-body {
                margin: 0 1rem 0 2rem;
              }
              h1,h2,h3,h4,h5,p,div {
                position: relative;
              }
              .head-doc {
                display: block;
                text-align: center;
                font-size: 24px;
                font-weight: bold;
                line-height: 1.8;
              }
              .notes {
                display: block;
                font-size: 14px;
                margin: 40px 0;
              }
              .anchor:before {
                pointer-events: all;
                transform: translateY(2px);
                position: absolute;
                left: -26px;
                transition: transform .3s;
                will-change: transform;
                cursor: pointer;
                content: url(${URI.trim()}/images/img-icon/3006691.svg);
              }
              .myCopied {
                border: 1px solid black;
                border-block-end-color: gray;
                border-right-color: gray;
                background-color: white;
                color: black;
                text-align: center;
                padding: 5px;
                position: absolute;
                z-index: 1;
                font-size: small;
                font-weight: 400;
                left: 0;
                top: 0;
              }
              h1, h2, h3, h4 {
                text-align: center;
              }
              .table-no-borders,
              .table-no-borders td, 
              .table-no-borders th {
                border: none !important;
              }

              .table-solid-borders,
              .table-solid-borders td,
              .table-solid-borders th {
                border: 1px solid #000 !important;
              }
              `,
            paste_postprocess: (_plugin: any, args: any) => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(args.node.innerHTML, 'text/html');
              const links = doc.querySelectorAll('a');

              for (let i = 0; i < links.length; i++) {
                const link = links[i].href;
                const domain = link.split('//')[1]?.split('/')[0] || '';
                const parentNode = links[i].parentNode;
                if (!domain.includes('expertus') && parentNode) {
                  //@ts-ignore
                  parentNode.innerHTML = parentNode.innerHTML.replace(/<a\s.*?>(.*?)<\/a>/gi, '$1');
                }
              }

              args.node.innerHTML = doc.documentElement.innerHTML.replace(/<\/?div[^>]*>/g, '');
              const children = args.node.children;
              if (children) {
                for (const key in children) {
                  if (!Number.isNaN(Number(key))) {
                    args.node.children[key].id = uuid();
                  }
                }
              }
            },
            images_upload_handler: async (blobInfo) => {
              const formData = new FormData();
              if (blobInfo) {
                formData.append('file', blobInfo.blob(), blobInfo.filename());
                formData.append('id', docId ? String(docId) : '');
                const path = await callApi({
                  method: 'post',
                  data: formData,
                  isFormData: true,
                  path: '/officialdoc-files/img',
                }).catch((err) => console.log(err));

                if (path) {
                  return `${API_URL_SYSTEM}/${path}`;
                }
                return '';
              } else {
                return '';
              }
            },
            setup: (editor) => {
              function setStyleWidthTable(html: string) {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                doc.querySelectorAll('table').forEach((table) => {
                  if (!table.style.width) {
                    table.style.width = '100%';
                  }
                });
                doc.querySelectorAll('.paragraph-alignment-right').forEach((item) => {
                  item.removeAttribute('class');
                  item.setAttribute('style', 'text-align: right');
                });
                doc.querySelectorAll('.paragraph-alignment-center').forEach((item) => {
                  item.removeAttribute('class');
                  item.setAttribute('style', 'text-align: center');
                });
                return doc.body.innerHTML;
              }
              function getTable(el: HTMLElement | null, level = 1): HTMLElement | null {
                if (!el || level > 5) return null;
                if (el.nodeName.toLowerCase() === 'table') return el;
                return getTable(el.parentElement, level + 1);
              }
              function changePositionTable(alignment: 'left' | 'right') {
                return () => {
                  const node = editor.selection.getNode();
                  const table = getTable(node.parentElement);
                  if (table) {
                    // const value = alignment === 'right' ? 'auto 0px auto auto' : 'auto auto auto 0px';
                    // editor.$(table).removeAttr('data-mce-style');
                    // editor.$(table).css('margin', value);
                  }
                };
              }
              editor.on('click', (node) => {
                if (node.target?.classList?.contains('anchor') && node.x < 25) {
                  const id = node.target.id || editor.selection.getNode().id;
                  navigator.clipboard.writeText(docIdRef.current.docId ? `${configSites[1].url}/law/${docIdRef.current.docId}#${id}` : id);
                  const el = editor.dom.create('span', { class: 'myCopied' }, 'Скопійовано');
                  node.target.append(el);
                  let timeout = setTimeout(() => {
                    el.remove();
                    clearTimeout(timeout);
                  }, 1000);
                }
              });
              editor.on('ScrollContent', (e) => {
                if (e?.currentTarget?.scrollY) {
                  const _page = Math.ceil(e.currentTarget.scrollY / (HEIGHT - 10));
                  setPage(_page);
                  handleScrollMeaning(e, meaningRef, editorRef, selectedMeaningId, setSelectedMeaningId);
                }
              });
              editor.on('click', () => {
                const content = editor.getContent();
                if (!showActiveQuotes && content?.includes('<mark>')) {
                  editor.setContent(backlight(false, content));
                }
                if (showActiveQuotes) {
                  setShowActiveQuotes(false);
                }
              });
              editor.on('BeforeSetContent', (e) => {
                if (e.content === '') {
                  e.content = `<p id="${uuid()}"><br></p>`;
                } else {
                  e.content = setStyleWidthTable(e.content || '');
                }
              });
              editor.on('keyup', (e) => {
                debounceSave();
                const body = editor.getBody();
                const pages = Math.ceil(body.offsetHeight / HEIGHT);
                setPages(pages);
                const elem = editor.selection.getNode();
                if (!elem.getAttribute('id')) {
                  elem.setAttribute('id', uuid());
                }
              });
              editor.on('SetContent', (e) => {
                const body = editor.getBody();
                const pages = Math.ceil(body.offsetHeight / HEIGHT);
                setPages(pages);
              });
              editor.ui.registry.addIcon('anchorIcon', anchorIcon);
              editor.ui.registry.addButton('anchorbutton', {
                icon: 'anchorIcon',
                onAction: () => {
                  const _body = editor.getBody();
                  if (_body.children[0].classList.contains('anchor')) {
                    editor.dom.removeClass(_body.childNodes as unknown as any[], 'anchor');
                  } else {
                    editor.dom.addClass(_body.childNodes as unknown as any[], 'anchor');
                  }
                },
              });
              editor.ui.registry.addButton('alignmentRight', {
                icon: 'chevron-right',
                onAction: changePositionTable('right'),
              });
              editor.ui.registry.addButton('alignmentLeft', {
                icon: 'chevron-left',
                onAction: changePositionTable('left'),
              });
              editor.ui.registry.addMenuItem('basicItem', {
                text: 'Завантажити .docx',
                onAction: () => {
                  const inputFile = document.createElement('input');
                  inputFile.setAttribute('type', 'file');
                  inputFile.accept = ACCEPT;
                  document.body.appendChild(inputFile);
                  inputFile.click();
                  inputFile.onchange = (e) => {
                    //@ts-ignore
                    const file: File = e.target?.files[0];
                    if (!file.name.includes('.docx')) return null;
                    const render = new FileReader();
                    render.onload = () => {
                      // const options = {
                      //   transformDocument: (doc: any) => {
                      //     const children = doc.children.map(transformElement);
                      //     return { ...doc, children };
                      //   },
                      //   styleMap: [
                      //     "p[style-name='Heading2'] => h2",
                      //     "p[style-name='Heading3'] => h3",
                      //     "p[style-name='ParagraphCenter'] => p.paragraph-alignment-center:fresh",
                      //     "p[style-name='ParagraphRight'] => p.paragraph-alignment-right:fresh",
                      //     'b => ',
                      //     'u => u',
                      //     's => del',
                      //     'strike => del',
                      //     'comment-reference => sup',
                      //   ],
                      // };
                      // mammoth.convertToHtml({ arrayBuffer: render.result as ArrayBuffer }, options).then((result) => {
                      //   const text = setUuid(result.value.replaceAll('<td></td>', '<td> </td>').replace(/<[^/>][^>]*><\/[^>]+>/g, ''), [
                      //     'p',
                      //     'h2',
                      //     'h3',
                      //   ]);
                      //   const meaning = defineMeaning(text);
                      //   editorRef?.current?.setContent(setStyleWidthTable(emblem + text));
                      //   meaningRef.current = meaning;
                      //   setPage(1);
                      // });
                    };
                    render.readAsArrayBuffer(file);
                  };
                  inputFile.parentNode && inputFile.parentNode.removeChild(inputFile);
                },
              });
              editor.ui.registry.addMenuItem('basicItemPart', {
                text: 'Завантажити фрагмент .docx',
                onAction: () => {
                  const inputFile = document.createElement('input');
                  inputFile.setAttribute('type', 'file');
                  inputFile.accept = ACCEPT;
                  document.body.appendChild(inputFile);
                  inputFile.click();
                  inputFile.onchange = (e) => {
                    //@ts-ignore
                    const file: File = e.target?.files[0];
                    if (!file.name.includes('.docx')) return null;
                    const render = new FileReader();
                    render.onload = () => {
                      // const options = {
                      //   transformDocument: (doc: any) => {
                      //     const children = doc.children.map(transformElement);
                      //     return { ...doc, children };
                      //   },
                      //   styleMap: [
                      //     "p[style-name='Heading2'] => h2",
                      //     "p[style-name='Heading3'] => h3",
                      //     "p[style-name='ParagraphCenter'] => p.paragraph-alignment-center:fresh",
                      //     "p[style-name='ParagraphRight'] => p.paragraph-alignment-right:fresh",
                      //     'b => ',
                      //     'u => u',
                      //     's => del',
                      //     'strike => del',
                      //     'comment-reference => sup',
                      //   ],
                      // };
                      // mammoth.convertToHtml({ arrayBuffer: render.result as ArrayBuffer }, options).then((result) => {
                      //   const text = setUuid(result.value.replaceAll('<td></td>', '<td> </td>').replace(/<[^/>][^>]*><\/[^>]+>/g, ''), [
                      //     'p',
                      //     'h2',
                      //     'h3',
                      //   ]);
                      //   const meaning = defineMeaning(text);
                      //   editorRef?.current?.insertContent(setStyleWidthTable(text));
                      //   meaningRef.current = meaning;
                      //   setPage(1);
                      // });
                    };
                    render.readAsArrayBuffer(file);
                  };
                  inputFile.parentNode && inputFile.parentNode.removeChild(inputFile);
                },
              });
            },
          }}
        />
        <Pagination count={pages} page={page} onChange={(ev, _page) => handleChangePage(_page)} />
      </div>
    </div>
  );
};

interface IMeaningProps {
  meaning: IMeaning[];
  setParagraphId: (key: string) => void;
  selectedMeaning: string;
  scrollDirection: 'up' | 'down';
  editorRef: any;
}

const Meaning: React.FC<IMeaningProps> = React.memo(({ meaning, setParagraphId, selectedMeaning, scrollDirection, editorRef }) => {
  const containerMeaningScrollRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    handleSelectedMeaning(selectedMeaning, meaning, containerMeaningScrollRef, scrollDirection);
  }, [selectedMeaning, scrollDirection, meaning]);

  return (
    <div>
      <h2>Зміст</h2>
      <ul ref={containerMeaningScrollRef} className={'meaning'}>
        {meaning.map(({ id, level, text }, idx) => {
          const plainText = parseHTML(text);
          return (
            <li
              id={id + '.' + idx}
              key={`${idx}-${id}`}
              className={'itemIi'}
              style={{
                paddingLeft: level !== 1 ? '10px' : '',
                color: selectedMeaning === id || (!selectedMeaning && idx === 0) ? '#ffffff' : '',
                backgroundColor: selectedMeaning === id || (!selectedMeaning && idx === 0) ? '#3f51b5' : '',
                borderRadius: '2px',
              }}
              onClick={() => {
                const element = editorRef.current?.dom?.get(id);
                if (element) element.scrollIntoView({ behavior: 'instant' });
                setParagraphId(id);
              }}
              dangerouslySetInnerHTML={{ __html: plainText }}
            />
          );
        })}
      </ul>
    </div>
  );
});
