import { IAuthor } from '../authors/types';
import { IFormFile } from '../formFiles/types';

export interface TFormsState {
  data: { forms: IForms[]; total: number } | null;
  forms: IForms[] | null;
  current: IForms | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IForms {
  id: number;
  rubricId: number;
  siteId: number;
  title: string;
  title_short: string;
  body: string;
  description?: string;
  img: string;
  files: IFormFile[];
  rubricName: string;
  adate: Date;
  update: Date;
  isPopular: number;
  isMonth: number;
  isPublic: number;
  searchTags: string;
  publication_at: Date;
  is_in_cart: number;
  author?: IAuthor;
}

enum ActionTypes {
  SET_FORMS = '@@forms/SET_FORMS',
  RESET_FORMS = '@@forms/RESET_FORMS',

  GET_FORMS_R = '@@forms/GET_FORMS_R',
  GET_FORMS_S = '@@forms/GET_FORMS_S',
  GET_FORMS_E = '@@forms/GET_FORMS_E',

  GET_FORM_R = '@@forms/GET_FORM_R',
  GET_FORM_S = '@@forms/GET_FORM_S',
  GET_FORM_E = '@@forms/GET_FORM_E',

  SEARCH_FORMS_R = '@@forms/SEARCH_FORMS_R',
  SEARCH_FORMS_S = '@@forms/SEARCH_FORMS_S',
  SEARCH_FORMS_E = '@@forms/SEARCH_FORMS_E',

  CREATE_FORMS_R = '@@forms/CREATE_FORMS_R',
  CREATE_FORMS_S = '@@forms/CREATE_FORMS_S',
  CREATE_FORMS_E = '@@forms/CREATE_FORMS_E',

  UPDATE_FORMS_R = '@@forms/UPDATE_FORMS_R',
  UPDATE_FORMS_S = '@@forms/UPDATE_FORMS_S',
  UPDATE_FORMS_E = '@@forms/UPDATE_FORMS_E',

  DELETE_FORMS_R = '@@forms/DELETE_FORMS_R',
  DELETE_FORMS_S = '@@forms/DELETE_FORMS_S',
  DELETE_FORMS_E = '@@forms/DELETE_FORMS_E',
}

export default ActionTypes;
