import { Reducer } from 'redux';
import ActionTypes, { TConsultationsState } from './types';

export const initialState: TConsultationsState = {
  data: null,
  consultations: null,
  current: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TConsultationsState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CONSULTATIONS: {
      return {
        ...state,
        data: action.payload,
        consultations: action.payload.consultations,
        loading: false,
        errors: '',
      };
    }

    case ActionTypes.GET_CONSULTATIONS_S:
      return {
        ...state,
        consultations: action.payload.consultations,
        data: action.payload,
        loading: false,
        errors: '',
      };

    case ActionTypes.RESET_CONSULTATIONS:
      return {
        ...state,
        consultations: state.data ? state.data.consultations : null,
      };

    case ActionTypes.GET_CONSULTATIONS_R:
    case ActionTypes.SEARCH_CONSULTATIONS_R:
    case ActionTypes.CREATE_CONSULTATION_R:
    case ActionTypes.UPDATE_CONSULTATION_R:
    case ActionTypes.DELETE_CONSULTATION_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.SEARCH_CONSULTATIONS_S:
      return {
        ...state,
        consultations: action.payload,
        loading: false,
        errors: '',
      };

    case ActionTypes.GET_CONSULTATION_R:
      return {
        ...state,
        current: null,
      };

    case ActionTypes.GET_CONSULTATION_S:
      return {
        ...state,
        loading: false,
        errors: '',
        current: action.payload,
      };

    case ActionTypes.CREATE_CONSULTATION_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, consultations: [action.payload, ...state.data.consultations], total: state.data.total + 1 }
          : { consultations: [action.payload], total: 1 },
        consultations: state.consultations ? [action.payload, ...state.consultations] : [action.payload],
      };

    case ActionTypes.UPDATE_CONSULTATION_S: {
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? {
              ...state.data,
              consultations: state.data.consultations.map((it) => (it.id === action.payload.id ? action.payload : it)),
            }
          : state.data,
        consultations: state.consultations
          ? state.consultations.map((it) => (it.id === action.payload.id ? action.payload : it))
          : state.consultations,
        current: action.payload,
      };
    }
    case ActionTypes.DELETE_CONSULTATION_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? {
              ...state.data,
              consultations: state.data.consultations.filter((it) => it.id !== action.payload.id),
              total: state.data.total - 1,
            }
          : state.data,
        consultations: state.consultations?.filter((it) => it.id !== action.payload.id),
      };

    case ActionTypes.GET_CONSULTATIONS_E:
    case ActionTypes.SEARCH_CONSULTATIONS_E:
    case ActionTypes.CREATE_CONSULTATION_E:
    case ActionTypes.UPDATE_CONSULTATION_E:
    case ActionTypes.DELETE_CONSULTATION_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as ConsultationsReducer };
