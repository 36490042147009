import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { TServicesState } from './types';

const GetServicesAction = new Actions('GET_SERVICES', ActionTypes);
export interface TypeGetServicesR extends TDefRequest {
  data: {
    siteId?: number;
    url?: string;
    title?: string;
  };
}
export const GetServices = {
  request: (payload: TypeGetServicesR) => GetServicesAction.request(payload),
  success: (payload: TServicesState['services']) => GetServicesAction.success(payload || {}),
  error: (message: string) => GetServicesAction.error(message),
};

const GetServiceAction = new Actions('GET_SERVICE', ActionTypes);
export interface TypeGetServiceR extends TDefRequest {
  id: number;
}
export const GetService = {
  request: (payload: TypeGetServiceR) => GetServiceAction.request(payload),
  success: (payload: TServicesState['current']) => GetServiceAction.success(payload || {}),
  error: (message: string) => GetServiceAction.error(message),
};

const CreateServiceAction = new Actions('CREATE_SERVICE', ActionTypes);
export interface TypeCreateServiceR extends TDefRequest {
  data: {
    site_id: number;
    rubric_id: number;
    title: string;
    url: string;
    is_public: number;
    description?: string;
  };
}
export const CreateService = {
  request: (payload: TypeCreateServiceR) => CreateServiceAction.request(payload),
  success: (payload: TServicesState['current']) => CreateServiceAction.success(payload || {}),
  error: (message: string) => CreateServiceAction.error(message),
};

const UpdateServiceAction = new Actions('UPDATE_SERVICE', ActionTypes);
export interface TypeUpdateServiceR extends TDefRequest {
  id: number;
  data: {
    site_id: number;
    rubric_id: number;
    title: string;
    url: string;
    is_public: number;
    description?: string;
  };
}
export const UpdateService = {
  request: (payload: TypeUpdateServiceR) => UpdateServiceAction.request(payload),
  success: (payload: TServicesState['current']) => UpdateServiceAction.success(payload || {}),
  error: (message: string) => UpdateServiceAction.error(message),
};

const UpdatePositionAction = new Actions('UPDATE_POSITION', ActionTypes);
export interface TypeUpdatePositionR extends TDefRequest {
  data: {
    id: number;
    position: number;
  };
}
export const UpdatePosition = {
  request: (payload: TypeUpdatePositionR) => UpdatePositionAction.request(payload),
  success: (payload: TServicesState['services']) => UpdatePositionAction.success(payload || {}),
  error: (message: string) => UpdatePositionAction.error(message),
};

const DeleteServiceAction = new Actions('DELETE_SERVICE', ActionTypes);
export interface TypeDeleteServiceR extends TDefRequest {
  id: number;
}
export const DeleteService = {
  request: (payload: TypeDeleteServiceR) => DeleteServiceAction.request(payload),
  success: (payload: TServicesState['current']) => DeleteServiceAction.success(payload || {}),
  error: (message: string) => DeleteServiceAction.error(message),
};

const GetBlocksAction = new Actions('GET_BLOCKS', ActionTypes);
export interface TypeGetBlocksR extends TDefRequest {
  siteId: number;
}
export const GetServiceBlocks = {
  request: (payload: TypeGetBlocksR) => GetBlocksAction.request(payload),
  success: (payload: TServicesState['blocks']) => GetBlocksAction.success(payload || {}),
  error: (message: string) => GetBlocksAction.error(message),
};

const UpdateBlocksAction = new Actions('UPDATE_BLOCKS', ActionTypes);
export interface TypeUpdateBlocksR extends TDefRequest {
  data: {
    siteId: number;
    typeId: number;
    data: { id: number; position: number }[];
  };
}
export const UpdateBlocks = {
  request: (payload: TypeUpdateBlocksR) => UpdateBlocksAction.request(payload),
  success: (payload: TServicesState['blocks']) => UpdateBlocksAction.success(payload || {}),
  error: (message: string) => UpdateBlocksAction.error(message),
};

const DeleteBlockAction = new Actions('DELETE_BLOCK', ActionTypes);
export interface TypeDeleteBlockR extends TDefRequest {
  serviceId: number;
  typeId: number;
}
export const DeleteBlock = {
  request: (payload: TypeDeleteBlockR) => DeleteBlockAction.request(payload),
  success: (payload: TServicesState['blocks']) => DeleteBlockAction.success(payload || {}),
  error: (message: string) => DeleteBlockAction.error(message),
};
