import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { IFactoidBlock } from '../../store/factoids/types';
import { EditorFeatured } from '../Editors';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CreateFactoidBlocks, UpdateFactoidBlocks } from '../../store/factoids/actions';
import { CATEGORIES } from '../../utils/consts';

interface PropsType {
  factoid_id?: number;
  type: 'edit' | 'new';
  data?: IFactoidBlock | null;
  handleCancel: () => void;
}

export const EditFactoidBlocks: React.FC<PropsType> = ({ factoid_id = 0, type, data, handleCancel }) => {
  const [title, setTitle] = React.useState<string>(data?.title || '');
  const [approved, setApproved] = React.useState(data?.approved ? 1 : 0);
  const [position, setPosition] = React.useState(data?.position || 0);
  const [error, setError] = React.useState(false);
  const [errors, setErrors] = React.useState('');

  const editorRef = React.useRef<any | null>(null);

  const { Factoids, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();


  const onSubmit = () => {
    const _body = editorRef.current?.getBody();
    if (_body.children[0].classList.contains('anchor')) {
      editorRef.current.dom.removeClass(_body.childNodes, 'anchor');
    }

    const body = editorRef.current?.getContent();

    setError(false);
    setErrors('');
    const fd = new FormData();
    if (type === 'new') {
      fd.append('factoid_id', String(factoid_id));
    }
    fd.append('title', title);
    fd.append('body', body);
    fd.append('approved', String(approved));
    fd.append('position', String(position));

    if (type === 'new') {
      dispatch(
        CreateFactoidBlocks.request({
          data: fd,
          callBack: (success) => {
            if (!success) {
              setErrors('Сталася помилка');
            }
          },
        })
      );
    }
    if (data && type === 'edit') {
      dispatch(
        UpdateFactoidBlocks.request({
          data: fd,
          id: data.id,
          callBack: (success, dta) => {
            if (!success) {
              setErrors('Сталася помилка');
            }
          },
        })
      );
    }
  };

  return (
    <Box>
      <CssBaseline />
      <div >
        <Box >
          <Typography component='h1' variant='h5'></Typography>
          {data && (
            <Box style={{ display: 'flex' }}>
              <Box >
                Дата створення {moment(data?.created_at).format('DD.MM.YYYY HH:mm')}
              </Box>
              <Box>
                Дата редагування {data?.updated_at ? moment(data?.updated_at).format('DD.MM.YYYY HH:mm') : 'Відсутня'}
              </Box>
            </Box>
          )}
        </Box>
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={
            <Checkbox
              checked={!!approved}
              onChange={(e) => {
                setApproved(e.target.checked ? 1 : 0);
              }}
            />
          }
          label='Публікувати'
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Заголовок'
          variant='outlined'
          value={title}
          error={error && !title}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />
        <TextField
          required
          size='small'
          id='outlined-basic'
          label='Порядок сортування'
          variant='outlined'
          value={position}
          error={error && !position}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setPosition(event.target.value as number);
          }}
        />
        <div style={{ marginTop: '20px' }}>
          <EditorFeatured
            categoryId={CATEGORIES.FACTOIDS}
            editorRef={editorRef}
            initialValue={data?.body}
            siteId={Configurations.siteId}
            docId={data?.id}
          />
        </div>
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={Factoids.loading} onClick={onSubmit}>
            {Factoids.loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleCancel}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>{errors}</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
};
