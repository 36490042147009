import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import { BasicDatePicker, SelectSearch } from '../ui';
import { BoxModal, WarningModal } from '../modals';
import moment from 'moment';
import {
  CreateOfficialDocOfUpdate,
  DeleteOfficialDocFromUpdate,
  GetDocsOfSelect,
  GetOfficialDocOfUpdate,
  UpdateOfficialDocOfUpdate,
} from '../../store/officialDoc/actions';
import { Edit } from '@mui/icons-material';
import { IOfficialDocUpdate } from '../../store/officialDoc/types';

interface Column {
  id: 'id' | 'doc_id' | 'title' | 'publication_at' | 'delete' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '6%' },
  { id: 'title', label: 'Заголовок', align: 'left' },
  { id: 'publication_at', label: 'Дата оновлення', align: 'left' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '15%',
  },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '8%',
  },
];

type PropsType = {};

export const NormativkaUpdates: React.FC<PropsType> = () => {
  const [title, setTitle] = React.useState('');
  const [docId, setDocID] = React.useState('');
  const [publicationAt, setPublicationAt] = React.useState<Date | null>(null);
  const [error, setError] = React.useState(false);
  const [isModal, setIsModal] = React.useState<{ 
    id: number;
    docId: number;
    type: 'delete' | 'edit' | null;
    data?: IOfficialDocUpdate;
  } | null>(null);

  const { OfficialDoc, Configurations } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();

  const handleRemove = (id: number) => {
    dispatch(DeleteOfficialDocFromUpdate.request({ id }));
  };

  const handleCreate = () => {
    if (!publicationAt) {
      setError(true);
      return;
    }
    setError(false);
    dispatch(
      CreateOfficialDocOfUpdate.request({
        data: {
          siteId: Configurations.siteId,
          docId: Number(docId),
          title,
          publicationAt: new Date(String(publicationAt)),
        },
      })
    );
  };

  React.useEffect(() => {
    if (Configurations.siteId) {
      dispatch(GetDocsOfSelect.request({ data: {} }));
      dispatch(
        GetOfficialDocOfUpdate.request({
          data: {
            siteId: Configurations.siteId,
          },
        })
      );
    }
  }, [dispatch, Configurations.siteId]);

  return (
    <>
      <Box >
        <TextField
          style={{ width: '58%' }}
          
          size='small'
          id='outlined-basic-title'
          label='Заголовок'
          variant='outlined'
          value={title}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setTitle(event.target.value as string);
          }}
        />
        <SelectSearch
          style={{ width: '30rem' }}
          title='Id документа'
          value={docId}
          
          options={
            (OfficialDoc.selectList &&
              OfficialDoc.selectList?.length &&
              OfficialDoc.selectList?.map((it) => ({ value: String(it.id), label: String(it.id) }))) ||
            undefined
          }
          onChange={(docId) => {
            setDocID(docId);
            setTitle((OfficialDoc.selectList?.find((item) => String(item.id) === docId) || {}).title || '');
          }}
        />
        <div  style={{ display: 'flex' }}>
          <BasicDatePicker
            id='publicationAt'
            style={{ width: '16rem' }}
            label='Дата оновлення'
            value={publicationAt}
            error={error && !publicationAt}
            onChange={setPublicationAt}
          />
        </div>
        <Button
          
          style={{ marginRight: '20px' }}
          variant='contained'
          color='primary'
          onClick={() => handleCreate()}
        >
          Додати
        </Button>
      </Box>
      <TableContainer  style={{ maxHeight: 'calc(100vh - 280px)' }}>
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {OfficialDoc.officialDocUpdate &&
              OfficialDoc.officialDocUpdate?.map((row, i) => {
                return (
                  <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                    <TableCell>{row.doc_id}</TableCell>
                    <TableCell >
                      <a href={row.url} target='_blank' rel='noreferrer'>
                        {row.title}
                      </a>
                    </TableCell>
                    <TableCell >
                      {moment(row.publication_at).format('DD.MM.YYYY HH:mm')}
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        aria-label='delete'
                        onClick={() => setIsModal({ id: row.id, docId: Number(row.doc_id), type: 'edit', data: row })}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        aria-label='delete'
                        onClick={() => setIsModal({ id: row.id, docId: Number(row.doc_id), type: 'delete' })}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          {isModal && isModal.type === 'edit' ? (
            <BoxModal
              title='Редагувати'
              initValue={isModal.data?.title}
              handleClose={() => {
                setIsModal(null);
              }}
              handleConfirm={(value) => {
                const data = value as unknown as {
                  title: string;
                  date: Date;
                };
                dispatch(
                  UpdateOfficialDocOfUpdate.request({
                    data: {
                      title: data.title,
                      publication_at: data.date,
                      doc_id: Number(isModal?.data?.doc_id),
                      site_id: Configurations.siteId,
                    },
                    id: Number(isModal?.data?.id),
                  })
                );
                setIsModal(null);
              }}
              open={!!isModal}
              datePicker={{ date: isModal.data?.publication_at || null, lable: 'Дата оновлення', id: 'date-picker' }}
            />
          ) : null}
        </Table>
      </TableContainer>
      {isModal && isModal.type === 'delete' ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={`№ ${isModal.docId}`}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
