import { Reducer } from 'redux';
import ActionTypes, { TMainMonthState } from './types';

const initialState: TMainMonthState = {
  loading: false,
  error: null,
  mainInMonth: null,
  current: null,
};

const reducer: Reducer<TMainMonthState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_TEMPLATE_POSITION_R:
    case ActionTypes.UPDATE_MAIN_IN_MONTH_R:
    case ActionTypes.GET_ONE_MAIN_IN_MONTH_R:
    case ActionTypes.DELETE_MAIN_IN_MONTH_R:
    case ActionTypes.CREATE_MAIN_IN_MONTH_R:
    case ActionTypes.GET_MAIN_IN_MONTH_R:
      return { ...state, loading: true, error: null };

    case ActionTypes.CREATE_MAIN_IN_MONTH_S:
    case ActionTypes.GET_MAIN_IN_MONTH_S:
      return { ...state, loading: false, error: null, mainInMonth: action.payload };

    case ActionTypes.DELETE_MAIN_IN_MONTH_S: {
      const newArray = state.mainInMonth?.map((el) => ({
        ...el,
        children: el.children?.filter((el) => el.id !== action.payload),
      }));
      return {
        ...state,
        loading: false,
        error: null,
        mainInMonth: newArray?.filter((el) => {
          return el.children?.length;
        }),
      };
    }

    case ActionTypes.UPDATE_TEMPLATE_POSITION_S: {
      return {
        ...state,
        loading: false,
        error: null,
        current: {
          ...state.current,
          templates: action.payload,
        },
      };
    }


    case ActionTypes.GET_ONE_MAIN_IN_MONTH_S:
      return { ...state, error: null, current: action.payload };

    case ActionTypes.UPDATE_TEMPLATE_POSITION_E:
    case ActionTypes.UPDATE_MAIN_IN_MONTH_E:
    case ActionTypes.GET_ONE_MAIN_IN_MONTH_E:
    case ActionTypes.DELETE_MAIN_IN_MONTH_E:
    case ActionTypes.CREATE_MAIN_IN_MONTH_E:
    case ActionTypes.GET_MAIN_IN_MONTH_E:
      return { ...state, loading: true, error: action.payload };
  }
  return state;
};

export { reducer as MainInMonthReducer };
