import React from 'react';
import { useDispatch } from 'react-redux';
import { TDefRequest } from '../store/Actions';
import { TIMEOUT_REQ } from '../utils/consts';
import { AnyAction } from 'redux';

const debounce = (callback: (data: string, query: string, options?: { [x: string]: string }) => void, delay: number) => {
  let data = '';
  return (value: string, query: string, options?: { [x: string]: string }) => {
    data = value;
    let timeout = setTimeout(() => {
      let localData = value;
      if (data === localData) callback(data, query, options);
      clearTimeout(timeout);
    }, delay);
  };
};

export interface TypeSearchData extends TDefRequest {
  data: { [x: string]: string | number };
}

interface PropsType {
  SearchData: {
    request: (payload: TypeSearchData) => AnyAction;
  };
}

export const useSearchAny = ({ SearchData }: PropsType) => {
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  const requestSearch = (value: string, query: string, options?: { [x: string]: string }) => {
    setLoading(true);
    let obj: { [x: string]: string } = {};
    if (options) {
      Object.keys(options).map((key) => (obj[key] = options[key]));
    }
    obj[query] = value;
    const data: TypeSearchData = {
      data: obj,
      callBack: () => {
        setLoading(false);
      },
    };
    dispatch(SearchData.request(data));
  };

  // eslint-disable-next-line
  const handleSearch = React.useCallback(
    debounce((data, query, options?: { [x: string]: string }) => {
      requestSearch(data, query, options);
    }, TIMEOUT_REQ),
    []
  );

  return {
    loading,
    handleSearch,
  };
};
