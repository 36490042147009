import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IOfficialDoc } from '../../store/officialDoc/types';
import { GetDoc } from '../../store/officialDoc/actions';
import { EditorDocumRead } from '../Editors';

interface PropsType {
  data: IOfficialDoc;
  handleCancel: () => void;
}

export const EditNormativka: React.FC<PropsType> = React.memo(({ data, handleCancel }) => {
  const { OfficialDoc, Configurations } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();


  React.useEffect(() => {
    if (data.id) {
      dispatch(GetDoc.request({ id: data.id }));
    }
  }, [dispatch, data.id]);

  return (
    <Box>
      <CssBaseline />
      <div >
        <Box  style={{ width: '1120px' }}>
          <Typography component='h1' variant='h5'>
            {data.title}
          </Typography>
          <Button variant='contained' onClick={handleCancel} style={{ height: '36px', minWidth: '140px' }}>
            Повернутися
          </Button>
        </Box>
        <div style={{ margin: '20px 0 0 226px', transform: 'translateX(-20%)' }}>
          <EditorDocumRead siteId={Configurations.siteId} docId={data.id} initialValue={data?.body} />
        </div>
        {OfficialDoc.errors ? (
          <Alert severity='error' style={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
      </div>
    </Box>
  );
});
