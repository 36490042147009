import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { setEditDoc } from '../../store/officialDoc/actions';
import moment from 'moment';
import { colorsStatus } from '../../utils/consts';

interface Column {
  id: 'id' | 'name' | 'dataStart' | 'approved' | 'update' | 'dataEnd' | 'status' | 'edit';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id', label: 'ID', align: 'center', width: '5%' },
  { id: 'dataStart', label: 'Дата старту', align: 'left', width: '15%' },
  { id: 'dataEnd', label: 'Дата закінчення', align: 'left', width: '15%' },
  { id: 'status', label: 'Юр. Статус', align: 'left', width: '12%' },
  { id: 'approved', label: 'Статус', align: 'left', width: '10%' },
  { id: 'name', label: 'Назва', align: 'left' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '9%',
  },
];

type PropsType = {
  groupId: number;
  handleBack: () => void;
};

export const RedactionsList: React.FC<PropsType> = ({ handleBack }) => {
  const { OfficialDoc, Sidebar } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();


  React.useEffect(() => {
    if (Sidebar.page.type === 'main' || Sidebar.page.type === 'list') {
      dispatch(setEditDoc(null));
      handleBack();
    }
  }, [dispatch, Sidebar.page, handleBack]);

  return (
    <>
      <Box >
        <Button variant='outlined' color='primary' onClick={handleBack}>
          Повернутися
        </Button>
      </Box>
      <TableContainer >
        <Table  stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ width: column?.width || '' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {OfficialDoc.redactions?.officialDoc?.map((row, i) => {
              return (
                <TableRow style={{ maxHeight: 50 }} hover role='checkbox' tabIndex={-1} key={`${i}-${row.id}`}>
                  <TableCell align='center'>{row.id}</TableCell>
                  <TableCell >
                    {row.startDate ? moment(row.startDate).format('DD.MM.YYYY HH:mm') : ''}
                  </TableCell>
                  <TableCell >
                    {row.endDate ? moment(row.endDate).format('DD.MM.YYYY HH:mm') : ''}
                  </TableCell>
                  <TableCell >{OfficialDoc.status.lowStatus[row.status]}</TableCell>
                  <TableCell  style={{ color: colorsStatus[row.approved] }}>
                    {OfficialDoc.status.approved[row.approved]}
                  </TableCell>
                  <TableCell >{row.title}</TableCell>
                  <TableCell align='center'>
                    <IconButton
                      aria-label='edit'
                      onClick={() => {
                        window.open(`/document/${row.id}`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
