import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { buildQuery } from '../../utils/buildQuery';
import { callApi } from '../../utils/callApi';
import {
  CreateCatalog,
  DeleteCatalog,
  GetCatalog,
  GetCatalogues,
  SearchCatalogues,
  TypeCreateCatalogR,
  TypeDeleteCatalogR,
  TypeGetCatalogR,
  TypeGetCataloguesR,
  TypeSearchCataloguesR,
  TypeUpdateCatalogR,
  UpdateCatalog,
} from './actions';
import ActionTypes, { ICatalog, TCataloguesState } from './types';

function* getCataloguesWorker(action: ReturnType<typeof GetCatalogues.request>): Generator {
  const { data, callBack } = action.payload as TypeGetCataloguesR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/catalogues?${query}`,
    })) as TCataloguesState['data'];
    yield put(GetCatalogues.success(resp));
  } catch (e) {
    success = false;
    yield put(GetCatalogues.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getCatalogWorker(action: ReturnType<typeof GetCatalog.request>): Generator {
  const { id, callBack } = action.payload as TypeGetCatalogR;
  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/catalogues/one/${id}`,
    })) as ICatalog;
    yield put(GetCatalog.success(resp));
  } catch (e) {
    success = false;
    yield put(GetCatalog.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* searchCataloguesWorker(action: ReturnType<typeof SearchCatalogues.request>): Generator {
  const { data, callBack } = action.payload as TypeSearchCataloguesR;

  let success = true;
  const query = buildQuery(data);

  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/catalogues?${query}`,
    })) as TCataloguesState['data'];
    yield put(SearchCatalogues.success(resp ? resp.catalogues : []));
  } catch (e) {
    success = false;
    yield put(SearchCatalogues.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* createCatalogWorker(action: ReturnType<typeof CreateCatalog.request>): Generator {
  const { data, callBack } = action.payload as TypeCreateCatalogR;

  let success = true;
  let resp = null;

  try {
    resp = (yield call(callApi, {
      method: 'post',
      data: data,
      path: '/catalogues/',
    })) as ICatalog;
    yield put(CreateCatalog.success(resp));
  } catch (e: any) {
    success = false;
    resp = e;
    yield put(CreateCatalog.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, resp);
  }
}

function* updateCatalogWorker(action: ReturnType<typeof UpdateCatalog.request>): Generator {
  const { data, id, callBack } = action.payload as TypeUpdateCatalogR;

  let success = true;
  let error = null;
  try {
    const resp = (yield call(callApi, {
      method: 'put',
      data: data,
      path: `/catalogues/${id}`,
    })) as ICatalog;
    yield put(UpdateCatalog.success(resp));
  } catch (e: any) {
    success = false;
    error = e;
    yield put(UpdateCatalog.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success, error);
  }
}

function* deleteCatalogWorker(action: ReturnType<typeof DeleteCatalog.request>): Generator {
  const { id, callBack } = action.payload as TypeDeleteCatalogR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'delete',
      path: `/catalogues/${id}`,
    })) as ICatalog;
    yield put(DeleteCatalog.success(resp));
  } catch (e) {
    success = false;
    yield put(DeleteCatalog.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_CATALOGUES_R, getCataloguesWorker);
  yield takeEvery(ActionTypes.GET_CATALOG_R, getCatalogWorker);
  yield takeEvery(ActionTypes.SEARCH_CATALOGUES_R, searchCataloguesWorker);
  yield takeEvery(ActionTypes.CREATE_CATALOG_R, createCatalogWorker);
  yield takeEvery(ActionTypes.UPDATE_CATALOG_R, updateCatalogWorker);
  yield takeEvery(ActionTypes.DELETE_CATALOG_R, deleteCatalogWorker);
}

export default function* cataloguesSaga() {
  yield all([fork(watchFetchRequest)]);
}
