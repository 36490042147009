export const ROW_PAGE = 20;
export const LIMIT = 25;
export const LIMIT_BLOCK = 100;
export const TIMEOUT_REQ = 1000;
export const APPROVED = {
  PUBLISH: 2,
  CREATE: 1,
};

export const colorsStatus: { [x: string]: string } = {
  '2': 'green',
  '1': '#3f51b5',
};

export const monthsOptions = [
  { label: 'Грудень', value: 'Грудень' },
  { label: 'Січень', value: 'Січень' },
  { label: 'Лютий', value: 'Лютий' },
  { label: 'Березень', value: 'Березень' },
  { label: 'Квітень', value: 'Квітень' },
  { label: 'Травень', value: 'Травень' },
  { label: 'Червень', value: 'Червень' },
  { label: 'Липень', value: 'Липень' },
  { label: 'Серпень', value: 'Серпень' },
  { label: 'Вересень', value: 'Вересень' },
  { label: 'Жовтень', value: 'Жовтень' },
  { label: 'Листопад', value: 'Листопад' },
];

export const categoriesSelect: { value: string; name: string; disabled?: boolean }[] = [
  { value: '1', name: 'Консультації', disabled: false },
  { value: '2', name: 'Форми', disabled: false },
  { value: '3', name: 'Відео', disabled: false },
  { value: '8', name: 'Довідники', disabled: false },
];

export enum CATEGORIES {
  CONSULTATIONS = 1,
  FORMS,
  VIDEO,
  SERVICES,
  NPD,
  CATALOGUES = 8,
  FACTOIDS,
  TEMPLATES
}

export enum BLOCK_TYPES {
  MAIN = 1,
  CALCULATERS,
  NAVIGATORS,
}

export const categoryName: { [x: string]: string } = {
  [CATEGORIES.CONSULTATIONS]: 'recommendations',
  [CATEGORIES.FORMS]: 'forms',
  [CATEGORIES.VIDEO]: 'videos',
  [CATEGORIES.SERVICES]: 'services',
  [CATEGORIES.NPD]: 'laws',
  [CATEGORIES.CATALOGUES]: 'catalogues',
};
