import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TDefRequest } from '../store/Actions';
import { AppStore } from '../store/applicationState';
import { TIMEOUT_REQ } from '../utils/consts';
import { debounce } from '../utils/debounce';
import { IDataOpt } from '../components/ui/SelectSearchReq';
import { AnyAction } from 'redux';

interface TypeSearchData extends TDefRequest {
  data: {
    siteId: number;
    id?: number;
    title?: string;
    fio?: string;
    limit?: number;
    type?: string;
    author?: string;
    rate?: number;
    date_at?: string;
    date_to?: string;
    pub_id?: number;
    page?: string;
  };
}

interface PropsType {
  SearchData: {
    request: (payload: TypeSearchData) => AnyAction;
  };
  ResetData?: () => {
    type: string;
    payload: Object;
  };
}

export const useSearch = ({ ResetData, SearchData }: PropsType) => {
  const [searchId, setSearchId] = React.useState('');
  const [searchRate, setSearchRate] = React.useState('');
  const [searchTitle, setSearchTitle] = React.useState('');
  const [searchAuthor, setSearchAuthor] = React.useState<IDataOpt | null>();
  const [searchFio, setSearchFio] = React.useState('');
  const [searchType, setSearchType] = React.useState('');
  const [searchDateAt, setSearchDateAt] = React.useState<Date | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [searchDateTo, setSearchDateTo] = React.useState<Date | null>(null);

  const { Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const handleSearch = (
    value: string | IDataOpt,
    query: 'id' | 'title' | 'fio' | 'type' | 'author' | 'rate' | 'date_at' | 'date_to' | 'pub_id' | 'page',
    type?: string,
    page?: string
  ) => {
    if (value) {
      const data: TypeSearchData = {
        data: {
          siteId: Configurations.siteId,
          type: type,
          page: page,
        },
        callBack: () => {
          setLoading(false);
        },
      };

      if ((query === 'id' || query === 'rate' || query === 'pub_id') && !Number.isNaN(Number(value)))
        data.data[query] = Number(value);
      if (query === 'author') data.data[query] = String((value as IDataOpt).id);
      if (query === 'title' || query === 'fio' || query === 'type' || query === 'date_at' || query === 'date_to')
        data.data[query] = String(value);
      if (page) data.data['page'] = page;
      dispatch(SearchData.request(data));
    } else {
      resetState();
      setLoading(false);
    }
  };

  const resetState = () => {
    setSearchId('');
    setSearchTitle('');
    setSearchAuthor(null);
    setSearchFio('');
    setSearchType('');
    setSearchRate('');
    setSearchDateAt(null);
    setSearchDateTo(null);
    if (ResetData) {
      dispatch(ResetData());
    }
  };

  // eslint-disable-next-line
  const debouncePubIdSave = React.useCallback(
    debounce((data) => {
      const { id, type, page } = JSON.parse(String(data));
      handleSearch(id, 'pub_id', type, page);
    }, TIMEOUT_REQ),
    []
  );

  // eslint-disable-next-line
  const debounceIdSave = React.useCallback(
    debounce((data) => {
      handleSearch(data, 'id');
    }, TIMEOUT_REQ),
    []
  );

  // eslint-disable-next-line
  const debounceRateSave = React.useCallback(
    debounce((data) => {
      const { page, rate } = JSON.parse(String(data));
      handleSearch(rate, 'rate', '', page);
    }, TIMEOUT_REQ),
    []
  );

  // eslint-disable-next-line
  const debounceTitleSave = React.useCallback(
    debounce((data) => {
      handleSearch(data, 'title');
    }, TIMEOUT_REQ),
    []
  );

  // eslint-disable-next-line
  const debounceAuthorSave = React.useCallback(
    debounce((data) => {
      handleSearch(data, 'author');
    }, TIMEOUT_REQ),
    []
  );

  // eslint-disable-next-line
  const debounceDateAtSave = React.useCallback(
    debounce((data) => {
      const { date, page } = JSON.parse(String(data));
      handleSearch(date, 'date_at', '', page);
    }, TIMEOUT_REQ),
    []
  );

  // eslint-disable-next-line
  const debounceDateToSave = React.useCallback(
    debounce((data) => {
      const { date, page } = JSON.parse(String(data));
      handleSearch(date, 'date_to', '', page);
    }, TIMEOUT_REQ),
    []
  );

  // eslint-disable-next-line
  const debounceTypeSave = React.useCallback(
    debounce((data) => {
      const { type, page } = JSON.parse(String(data));
      handleSearch(type, 'type', '', page);
    }, TIMEOUT_REQ),
    []
  );
  // eslint-disable-next-line
  const debounceFioSave = React.useCallback(
    debounce((data) => {
      handleSearch(data, 'fio');
    }, TIMEOUT_REQ),
    []
  );

  const handleSearchId = (id: string) => {
    setLoading(true);
    setSearchId(id);
    debounceIdSave(id);
  };

  const handleSearchPubId = (id: string, type?: string, page?: string) => {
    setLoading(true);
    setSearchId(id);
    debouncePubIdSave(JSON.stringify({ id, type, page }));
  };

  const handleSearchRate = (rate: string, page: string) => {
    setLoading(true);
    setSearchRate(rate);
    debounceRateSave(JSON.stringify({ rate, page }));
  };

  const handleSearchTitle = (value: string) => {
    setLoading(true);
    setSearchTitle(value);
    debounceTitleSave(value);
  };

  const handleSearchAuthor = (value: IDataOpt | null) => {
    setLoading(true);
    setSearchAuthor(value);
    // @ts-ignore
    debounceAuthorSave(value);
  };

  const handleSearchDateAt = (date: Date | null, page: string) => {
    setLoading(true);
    setSearchDateAt(date);
    debounceDateAtSave(JSON.stringify({ date, page }));
  };

  const handleSearchDateTo = (date: Date | null, page: string) => {
    setLoading(true);
    setSearchDateTo(date);
    debounceDateToSave(JSON.stringify({ date, page }));
  };

  const handleSearchType = (type: string, page?: string) => {
    setLoading(true);
    setSearchType(type);
    debounceTypeSave(JSON.stringify({ type, page }));
  };

  const handleSearchFio = (value: string) => {
    setLoading(true);
    setSearchFio(value);
    debounceFioSave(value);
  };

  return {
    loading,
    searchId,
    searchTitle,
    searchAuthor,
    searchFio,
    handleSearchId,
    handleSearchTitle,
    handleSearchFio,
    handleSearchType,
    handleSearchAuthor,
    handleSearchRate,
    handleSearchDateAt,
    handleSearchPubId,
    searchDateAt,
    searchRate,
    resetState,
    searchType,
    searchDateTo,
    handleSearchDateTo,
  };
};
