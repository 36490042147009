import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { GetBlocks } from '../../store/blocks/actions';
import { GetMainBlocks } from '../../store/main/actions';
import { AppStore } from '../../store/applicationState';
import { GetVideoById, TypeCreateVideoR, UpdateVideo } from '../../store/video/actions';
import { VideoForm } from '../../components/VideoForm';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfigurationsActions } from '../../store/configurations/actions';

export const EditVideo: React.FC = () => {
  const { Video } = useSelector((store: AppStore) => store);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (id) {
      dispatch(GetVideoById.request({ id: Number(id) }));
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (Video.current) {
      dispatch(ConfigurationsActions.setSiteId(Video.current.siteId));
    }
  }, [dispatch, Video]);

  const handleUpdate = (data: TypeCreateVideoR['data'], callBack: (err?: string) => void) => {
    if (!Video.current) return null;

    dispatch(
      UpdateVideo.request({
        data,
        id: Video.current.id,
        callBack: (success, dta) => {
          if (!success && dta) {
            if (dta.status === 409) callBack('Такий заголовок вже використовується');
            else callBack('Сталася помилка');
          } else {
            callBack();
          }
          if (success && Video.current?.isPublic === 1 && data.isPublic === 0) {
            dispatch(GetBlocks.request({ id: Video.current.siteId }));
            dispatch(GetMainBlocks.request({ id: Video.current.siteId }));
          }
        },
      })
    );
  };

  if (!Video.current)
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress color='secondary' />
      </Box>
    );

  return (
    <VideoForm
      type='edit'
      data={Video.current}
      handleSubmit={handleUpdate}
      handleCancel={() => navigate(ROUTES.routeChange('list'))}
    />
  );
};
