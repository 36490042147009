import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { BoxModal, WarningModal } from '../../modals';
import { IRubric } from '../../../store/rubrics/types';
import { CreateRubric, DeleteRubric, UpdatePositions, UpdateRubric } from '../../../store/rubrics/actions';
import { AppStore } from '../../../store/applicationState';

const findChildren = (cards: IRubric[] | null, ids: number[]): IRubric[] | null => {
  if (!cards) return cards;
  const card = cards[ids[0]];
  if (card && card.children) {
    ids.splice(0, 1);
    return findChildren(card.children, ids);
  }
  return cards;
};

const setChildren = (cards: IRubric[] | null, ids: number[], newChildren: IRubric[] | null): IRubric[] | null => {
  if (!cards) return cards;
  let prev = null;
  for (const id of ids) {
    if (prev && prev[id]) {
      prev = prev[id].children;
    }
    if (!prev && cards[id]) {
      prev = cards[id].children;
    }
  }
  prev = newChildren;
  return cards;
};

type IModal = 'add' | 'edit' | 'delete' | null;

type PropsType = {
  rubrics: IRubric[] | null;
  cards: IRubric[] | null;
  chain?: string;
  level?: number;
  categoryId: number;
  setCards: (cards: IRubric[] | null) => void;
};

export const Tree: React.FC<PropsType> = ({ rubrics, cards, categoryId, chain, level = 0, setCards }) => {
  const [draggId, setDraggId] = React.useState('');
  const [overId, setOverId] = React.useState('');
  const [expend, setExpand] = React.useState('');
  const [currentId, setCurrentId] = React.useState('');
  const [hoverId, setHoverId] = React.useState('');
  const [isModal, setIsModal] = React.useState<IModal>(null);

  const { Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();


  const moveCard = (dragIndex: number, hoverIndex: number, chain: string) => {
    if (!cards) return null;
    const ids = chain.split('-').map((str) => Number(str));
    ids.pop();
    const currentCards = findChildren(cards, ids);
    if (currentCards && currentCards[dragIndex]) {
      const card = currentCards[dragIndex];
      currentCards.splice(dragIndex, 1);
      currentCards.splice(hoverIndex, 0, card);

      dispatch(UpdatePositions.request({ data: currentCards.map((it, i) => ({ id: it.id, position: i })) }));

      const ids = chain.split('-').map((str) => Number(str));
      const newCards = setChildren([...cards], ids, currentCards);
      setCards(newCards);
    }
  };

  const onAddCard = (chain: string, id: number, value: string, docId?: number) => {
    if (!cards) return null;
    setExpand(chain);
    if (typeof id !== 'undefined') {
      dispatch(
        CreateRubric.request({ data: { mainId: id, categoryId, siteId: Configurations.siteId, name: value, docId } })
      );
    }
  };

  const onDeleteCard = (id: number) => {
    dispatch(DeleteRubric.request({ id }));
  };

  const onEditCard = (rubric: IRubric, name: string) => {
    dispatch(
      UpdateRubric.request({
        data: { categoryId, name, mainId: rubric.mainId, docId: rubric.docId, siteId: rubric.siteId },
        id: rubric.id,
      })
    );
  };

  const handleDraggEnd = (chainId: string) => {
    if (draggId !== overId) {
      const drId = draggId.split('-').pop();
      const chId = overId.split('-').pop();
      moveCard(Number(drId), Number(chId), chainId);
    }
    setDraggId('');
    setHoverId('');
  };

  const handleManage = (chainId: string, isModal: IModal) => {
    setCurrentId(chainId);
    setIsModal(isModal);
  };

  return (
    <>
      {rubrics?.map((rubric, index) => {
        const chainId = chain ? `${chain}-${index}` : String(index);
        const isDisabled = rubric.children ? rubric.children.length > 0 : false;
        return (
          <div
            key={chainId}
            onDragOver={() => {
              chainId.length !== expend.length && setHoverId(chainId);
            }}
            onDragLeave={() => setOverId(chainId)}
            onDrag={() => !draggId && setDraggId(chainId)}
            onDragEnd={() => handleDraggEnd(chainId)}
            draggable
          >
            <div >
              <div  onClick={() => setExpand(chainId === expend ? '' : chainId)}>
                {rubric.children && rubric.children.length > 0 ? (
                  expend === chainId ? (
                    <RemoveIcon style={{ fontSize: '1rem' }} />
                  ) : (
                    <AddIcon style={{ fontSize: '1rem' }} />
                  )
                ) : (
                  ''
                )}
              </div>
              <div
                
                style={{ background: chainId === hoverId && expend !== hoverId ? '#554e4e70' : '' }}
              >
                <div  style={{ color: '#3f51b5' }}>
                  {!rubric.docId ? (
                    expend === chainId ? (
                      <FolderOpenOutlinedIcon fontSize='small' />
                    ) : (
                      <FolderOutlinedIcon fontSize='small' />
                    )
                  ) : (
                    <InsertDriveFileOutlinedIcon fontSize='small' />
                  )}
                </div>
                <div  style={{ cursor: 'move' }}>
                  {rubric.name}
                </div>

                <div  onClick={() => level < 3 && handleManage(chainId, 'add')}>
                  {!rubric.children?.find((it) => !!it.docId) && !rubric.docId && (
                    <AddBoxOutlinedIcon style={{ fontSize: '1.1rem' }} />
                  )}
                </div>

                <div  onClick={() => !rubric.docId && handleManage(chainId, 'edit')}>
                  {!rubric.docId ? <EditIcon style={{ fontSize: '1.1rem' }} /> : null}
                </div>

                <IconButton
                  disabled={isDisabled}
                  
                  onClick={() => handleManage(chainId, 'delete')}
                >
                  <DeleteIcon color={isDisabled ? 'disabled' : 'error'} style={{ fontSize: '1.1rem' }} />
                </IconButton>
              </div>
            </div>

            {expend === chainId && (
              <div >
                <Tree
                  rubrics={rubric.children}
                  cards={cards}
                  categoryId={categoryId}
                  chain={chainId}
                  level={level + 1}
                  setCards={setCards}
                />
              </div>
            )}

            {isModal === 'add' && currentId === chainId ? (
              <BoxModal
                title='Створити рубрику'
                handleClose={() => {
                  setIsModal(null);
                }}
                handleConfirm={(value) => {
                  onAddCard(
                    chainId,
                    rubric.id,
                    (value as unknown as { title: string; date: Date })?.title || String(value)
                  );
                  setIsModal(null);
                }}
                open={!!isModal}
              />
            ) : null}
            {isModal === 'edit' && currentId === chainId ? (
              <BoxModal
                title='Редагувати'
                initValue={rubric.name}
                handleClose={() => {
                  setIsModal(null);
                }}
                handleConfirm={(value) => {
                  onEditCard(rubric, (value as unknown as { title: string; date: Date })?.title || String(value));
                  setIsModal(null);
                }}
                open={!!isModal}
              />
            ) : null}
            {isModal === 'delete' && currentId === chainId ? (
              <WarningModal
                handleApprove={(isApprove) => {
                  isApprove && onDeleteCard(rubric.id);
                  setIsModal(null);
                }}
                value={rubric.name}
                open={!!isModal}
              />
            ) : null}
          </div>
        );
      })}
    </>
  );
};
