import { IAuthor } from '../authors/types';
import { IRubric } from '../rubrics/types';

export interface TServicesState {
  blocks: IBlocksService | null;
  services: IService[] | null;
  current: IService | null;
  readonly loading: boolean;
  readonly errors?: string | undefined;
}

export interface IService {
  id: number;
  site_id: number;
  rubric_id: number;
  author_id: number;
  is_public: number;
  description: string;
  title: string;
  url: string;
  search_tags: string;
  rubric?: IRubric;
  author?: IAuthor;
  adate: Date;
  update: Date;
}

export interface IBlocksService {
  main: IService[];
  calculators: IService[];
  navigators: IService[];
}

enum ActionTypes {
  GET_SERVICES_R = '@@services/GET_SERVICES_R',
  GET_SERVICES_S = '@@services/GET_SERVICES_S',
  GET_SERVICES_E = '@@services/GET_SERVICES_E',

  GET_SERVICE_R = '@@services/GET_SERVICE_R',
  GET_SERVICE_S = '@@services/GET_SERVICE_S',
  GET_SERVICE_E = '@@services/GET_SERVICE_E',

  CREATE_SERVICE_R = '@@services/CREATE_SERVICE_R',
  CREATE_SERVICE_S = '@@services/CREATE_SERVICE_S',
  CREATE_SERVICE_E = '@@services/CREATE_SERVICE_E',

  UPDATE_SERVICE_R = '@@services/UPDATE_SERVICE_R',
  UPDATE_SERVICE_S = '@@services/UPDATE_SERVICE_S',
  UPDATE_SERVICE_E = '@@services/UPDATE_SERVICE_E',

  UPDATE_POSITION_R = '@@services/UPDATE_POSITION_R',
  UPDATE_POSITION_S = '@@services/UPDATE_POSITION_S',
  UPDATE_POSITION_E = '@@services/UPDATE_POSITION_E',

  DELETE_SERVICE_R = '@@services/DELETE_SERVICE_R',
  DELETE_SERVICE_S = '@@services/DELETE_SERVICE_S',
  DELETE_SERVICE_E = '@@services/DELETE_SERVICE_E',

  GET_BLOCKS_R = '@@services/GET_BLOCKS_R',
  GET_BLOCKS_S = '@@services/GET_BLOCKS_S',
  GET_BLOCKS_E = '@@services/GET_BLOCKS_E',

  UPDATE_BLOCKS_R = '@@services/UPDATE_BLOCKS_R',
  UPDATE_BLOCKS_S = '@@services/UPDATE_BLOCKS_S',
  UPDATE_BLOCKS_E = '@@services/UPDATE_BLOCKS_E',

  DELETE_BLOCK_R = '@@services/DELETE_BLOCK_R',
  DELETE_BLOCK_S = '@@services/DELETE_BLOCK_S',
  DELETE_BLOCK_E = '@@services/DELETE_BLOCK_E',
}

export default ActionTypes;
