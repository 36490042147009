import React from 'react';
import Button from '@mui/material/Button';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { CreateFormFile, UpdateFormFile } from '../../../store/formFiles/actions';
import TextField from '@mui/material/TextField';
import { IFormFile } from '../../../store/formFiles/types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { GetBlocks } from '../../../store/blocks/actions';
import { GetMainBlocks } from '../../../store/main/actions';
import { AppStore } from '../../../store/applicationState';

interface PropsType {
  docId: number;
  handleCancel: () => void;
  editData?: IFormFile | null;
  url?: string;
  type?: number;
}

export const EditFormFile: React.FC<PropsType> = ({ docId, handleCancel, url, type, editData }) => {
  const [fileName, setFileName] = React.useState<string>(editData?.name || '');
  const [isTop, setIsTop] = React.useState(editData?.is_top || 0);
  const [files, setFiles] = React.useState<{ file: File; success: 0 | 1 | 2 }[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { Configurations } = useSelector((store: AppStore) => store);

  const dispatch = useDispatch();


  const changeFile = (idx: number, success: 1 | 2) => {
    setFiles((prev) => prev.map((item, i) => (i === idx ? { ...item, success } : item)));
  };

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file) => ({ file, success: 0 })));
      setLoading(true);
      for (let i = 0; i < acceptedFiles.length; i++) {
        try {
          await new Promise((resolve) => {
            const name = acceptedFiles[i].name.substring(0, acceptedFiles[i].name.lastIndexOf('.'));

            const fd = new FormData();
            fd.append('name', name);
            fd.append('file', acceptedFiles[i]);
            fd.append('docId', String(docId));
            fd.append('typeId', String(type));

            dispatch(
              CreateFormFile.request({
                data: fd,
                url,
                callBack: (succ) => {
                  if (succ) {
                    changeFile(i, 1);
                  } else {
                    changeFile(i, 2);
                  }
                  resolve(null);
                },
              })
            );
            dispatch(GetBlocks.request({ id: Configurations.siteId }));
            dispatch(GetMainBlocks.request({ id: Configurations.siteId }));
          });
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      }
    },
    [dispatch, docId, Configurations.siteId, type, url]
  );

  const updateHandle = () => {
    const fd = new FormData();
    fd.append('original_name', fileName);
    if (isTop) {
      fd.append('is_top', String(isTop));
    }
    dispatch(
      UpdateFormFile.request({
        data: fd,
        id: editData?.id || 0,
        url: '/form-files',
        callBack: (success, dta) => {},
      })
    );
    dispatch(GetBlocks.request({ id: Configurations.siteId }));
    dispatch(GetMainBlocks.request({ id: Configurations.siteId }));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box>
      {editData && (
        <TextField
          style={{ marginTop: '7px' }}
          size='small'
          id='outlined-basic'
          label="Ім'я"
          variant='outlined'
          value={fileName}
          
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setFileName(event.target.value as string);
          }}
        />
      )}
      {editData && (
        <FormControlLabel
          style={{ marginTop: '7px' }}
          control={<Checkbox checked={!!isTop} />}
          onChange={() => setIsTop(isTop ? 0 : 1)}
          label='Головний'
        />
      )}
      {editData && (
        <Button
          variant='contained'
          className='btn btn-info'
          onClick={updateHandle}
          style={{ height: '36px', marginLeft: '40px' }}
        >
          Зберегти
        </Button>
      )}
      <CssBaseline />
      {!editData && (
        <div >
          <Box  style={{ justifyContent: 'flex-start',gap:'20px' }}>
            <Typography component='h1' variant='h5'>
              Додати файли
            </Typography>
            <Button variant='contained' onClick={handleCancel} color='inherit'>
              Повернутися
            </Button>
          </Box>
          {files.length > 0 ? (
            <div>
              {files.map((item, idx) => {
                return (
                  <div key={idx}>
                    <div style={{ marginRight: '10px' }}>{item.file.name}</div>
                    {item.success === 0 ? (
                      <CircularProgress size={15} />
                    ) : (
                      (item.success === 1 && <DoneIcon color='success' />) ||
                      (item.success === 2 && <CloseIcon color='error' />)
                    )}
                  </div>
                );
              })}
              <Button
                variant='contained'
                color='primary'
                style={{ margin: '10px 0 0 0', width: '170px' }}
                disabled={loading}
                onClick={() => setFiles([])}
              >
                Завантажити ще
              </Button>
            </div>
          ) : (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive ? 'Перетягніть сюди деякий файл або клацніть, щоб вибрати файл' : 'Можете вiдпускати'}
            </div>
          )}
        </div>
      )}
    </Box>
  );
};
