import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import { BasicDatePicker, SelectOutline } from '../../components/ui';
import { ROUTES } from '../../utils/routes';
import { AppStore } from '../../store/applicationState';
import { CalendarTree } from '../../components/Calendar/CalendarTree';
import { GetCalendar } from '../../store/calendar/actions';
import { ICalendar } from '../../store/calendar/types';

type PropsType = {};

export const Calendar: React.FC<PropsType> = () => {
  const { calendar } = useSelector((store: AppStore) => store.Calendar);
  const [calendarS, setCalendar] = useState<ICalendar[] | null>(calendar);
  const { Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const [date, setDate] = useState<Date | null>(new Date());
  const [day, setDay] = useState('');

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`new?site_id=${Configurations.siteId}`));
    };

  useEffect(() => {
    dispatch(
      GetCalendar.request({
        data: {
          site_id: Configurations.siteId,
          date: date && new Date(date),
          day: day,
        },
      })
    );
  }, [Configurations, dispatch, date, day]);

  useEffect(() => {
    setCalendar(calendar);
  }, [calendar]);

  const days = Array.from({ length: 31 }, (_, index) => index + 1);

  return (
    <>
      <Box display={'flex'} mb={2} gap={1}>
        <Button variant={'contained'} color={'primary'} onClick={() => {}}>
          ПОДІЇ
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={handleChangeRoute('new')}>
          СТВОРИТИ ПОДІЮ
        </Button>
      </Box>
      <Box display={'flex'} gap={2}>
        <BasicDatePicker
          disabled={true}
          format='MM.yyyy'
          style={{ width: '150px' }}
          id='publicationTo'
          views={['year', 'month']}
          required
          label={'Місяць-Рік'}
          value={date}
          error={false}
          onChange={(value) => {
            setDate(value);
          }}
        />

        <SelectOutline
          required
          style={{ width: 100 }}
          id='outlined-basic-status'
          title='день'
          value={day}
          error={false}
          options={days.map((el) => ({ label: String(el), value: String(el), disabled: false }))}
          handleChange={(value) => {
            setDay(value);
          }}
        />

        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setDay('');
            setDate(new Date());
          }}
        >
          Очистити фільтри
        </Button>
      </Box>
      <CalendarTree children={calendarS} cards={calendarS} categoryId={1} setCards={() => {}} />
    </>
  );
};
