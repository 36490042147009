import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../utils/routes';
import { useDispatch } from 'react-redux';
import { ConfigurationsActions } from '../../store/configurations/actions';
import { MainMonthForm } from '../../components/MainInMonth/MainMonthForm';

export const CreateMonthMain: React.FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const query = new URLSearchParams(search);
    const siteId = query.get('site_id');
    if (siteId) {
      dispatch(ConfigurationsActions.setSiteId(Number(siteId)));
    }
  }, [dispatch, search]);

  return <MainMonthForm editData={null} type='new' handleCancel={() => navigate(ROUTES.routeChange(''))} />;
};
