import { Reducer } from 'redux';
import ActionTypes, { TVideoState } from './types';

export const initialState: TVideoState = {
  data: null,
  video: null,
  current: null,
  loading: false,
  errors: undefined,
};

const reducer: Reducer<TVideoState> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_VIDEO:
      return { ...state, data: action.payload, video: action.payload.video, loading: false, errors: '' };

    case ActionTypes.GET_VIDEO_S:
      return { ...state, video: action.payload.video, loading: false, errors: '', data: action.payload };

    case ActionTypes.RESET_VIDEO:
      return {
        ...state,
        video: state.data ? state.data.video : null,
      };

    case ActionTypes.GET_VIDEO_R:
    case ActionTypes.SEARCH_VIDEO_R:
    case ActionTypes.CREATE_VIDEO_R:
    case ActionTypes.UPDATE_VIDEO_R:
    case ActionTypes.DELETE_VIDEO_R:
      return { ...state, loading: true, errors: '' };

    case ActionTypes.SEARCH_VIDEO_S:
      return {
        ...state,
        video: action.payload,
        loading: false,
        errors: '',
      };

    case ActionTypes.GET_VIDEO_BY_ID_R:
      return {
        ...state,
        current: null,
      };

    case ActionTypes.GET_VIDEO_BY_ID_S:
      return {
        ...state,
        loading: false,
        errors: '',
        current: action.payload,
      };

    case ActionTypes.CREATE_VIDEO_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, video: [action.payload, ...state.data.video], total: state.data.total + 1 }
          : { video: [action.payload], total: 1 },
        video: state.video ? [action.payload, ...state.video] : [action.payload],
      };

    case ActionTypes.UPDATE_VIDEO_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? { ...state.data, video: state.data.video.map((it) => (it.id === action.payload.id ? action.payload : it)) }
          : state.data,
        video: state.video?.map((it) => (it.id === action.payload.id ? action.payload : it)) || null,
        current: action.payload,
      };

    case ActionTypes.DELETE_VIDEO_S:
      return {
        ...state,
        loading: false,
        errors: '',
        data: state.data
          ? {
              ...state.data,
              video: state.data.video.filter((it) => it.id !== action.payload.id),
              total: state.data.total - 1,
            }
          : state.data,
        video: state.video?.filter((it) => it.id !== action.payload.id),
      };

    case ActionTypes.GET_VIDEO_E:
    case ActionTypes.SEARCH_VIDEO_E:
    case ActionTypes.CREATE_VIDEO_E:
    case ActionTypes.UPDATE_VIDEO_E:
    case ActionTypes.DELETE_VIDEO_E:
      return { ...state, loading: false, errors: action.payload };

    default:
      return state;
  }
};

export { reducer as VideoReducer };
