import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IRubric } from '../../store/rubrics/types';
import { AppStore } from '../../store/applicationState';
import Box from '@mui/material/Box';
import { ROUTES } from '../../utils/routes';
import { IEvent } from '../../store/calendar/types';
import { TableCell, TableHead, TableRow } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import { DeleteMainInMonth } from '../../store/mainInMonth/actions';
import { WarningModal } from '../modals';

type PropsType = {
  children: any[] | null;
  cards: any[] | null;
  chain?: string;
  level?: number;
  categoryId: number;
  setCards: (cards: IRubric[] | null) => void;
};

interface Column {
  id: 'url' | 'name' | 'coppy' | 'edit' | 'delete';
  label: string;
  align?: 'right' | 'left' | 'center';
  width?: string;
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'name', label: 'Назва', align: 'center', width: '19%' },
  { id: 'url', label: 'URL', align: 'center', width: '20%' },
  {
    id: 'edit',
    label: 'Редагувати',
    align: 'center',
    width: '10%',
  },
  { id: 'coppy', label: 'Копіювати', align: 'center', width: '10%' },
  {
    id: 'delete',
    label: 'Видалити',
    align: 'center',
    width: '10%',
  },
];

export const MainMonthTree: React.FC<PropsType> = ({ children, cards, categoryId, chain, level = 0, setCards }) => {
  const [expend, setExpand] = React.useState<string[]>(['0']);
  const [deleteModal, setDeleteModal] = useState<{ id: number; title: string } | null>(null);
  const [isCopied, setIsCopied] = useState({ id: 0 });

  const { Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const onDeleteCard = (id: number) => {
    dispatch(DeleteMainInMonth.request({ id }));
  };

  const handleChangeRoute =
    (path = '' as string | number) =>
    () => {
      window.open(ROUTES.routeChange(`edit/${path}?site_id=${Configurations.siteId}`));
    };

  const copyEvent = (event: IEvent) => {
    setIsCopied({ id: event.id });
    setTimeout(() => setIsCopied({ id: 0 }), 200);
    window.open(ROUTES.routeChange(`coppy/${event.id}?site_id=${Configurations.siteId}`));
  };

  return (
    <>
      <div>
        {level === 0 ? (
          <TableHead style={{ borderBottom: 'none', width: '100%' }}>
            <TableRow style={{ borderBottom: 'none', width: '100%' }}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ width: column?.width || '', borderBottom: 'none' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}

        {children
          ?.sort((a, b) => (level === 0 ? +b.title - +a.title : a.position - b.position))
          ?.map((event, index) => {
            const chainId = chain ? `${chain}-${index}` : String(index);
            const isDisabled = event.children ? event.children.length > 0 : false;

            return (
              <div tabIndex={-1} key={`${index}-${event.id}`}>
                <div key={chainId}>
                  <Box display={'flex'} mt={'10px'}>
                    <div
                      
                      onClick={() =>
                        setExpand(
                          expend.includes(chainId) ? expend.filter((el) => el !== chainId) : [...expend, chainId]
                        )
                      }
                    >
                      {event.children && event.children.length > 0 ? (
                        expend.includes(chainId) ? (
                          <RemoveIcon style={{ fontSize: '1rem' }} />
                        ) : (
                          <AddIcon style={{ fontSize: '1rem' }} />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      
                      style={{
                        border: level ? 'none' : 'solid 1px black',
                        borderTop: level ? 'solid 1px #efefef' : 'solid 1px black',
                        borderBottom: level ? 'solid 1px #efefef' : 'solid 1px black',
                        borderRadius: level ? 'none' : '4px',
                        padding: level ? '10px 15px' : '5px 10px',
                      }}
                    >
                      <div
                        
                        style={{
                          color: '#3f51b5',
                          width: '3%',
                        }}
                      >
                        {event.children && event.children.length > 0 ? (
                          expend.includes(chainId) ? (
                            <FolderOpenOutlinedIcon fontSize='small' />
                          ) : (
                            <FolderOutlinedIcon fontSize='small' />
                          )
                        ) : null}
                      </div>
                      <div
                        
                        style={{
                          fontWeight: !level ? 1000 : 500,
                        }}
                      >
                        {event.title}
                      </div>
                      <div>
                        {/* eslint-disable-next-line */}
                        <a style={{ textDecoration: 'none' }} href={event.url} target='_blank'>
                          {event.url}
                        </a>
                      </div>

                      <div style={{ display: !event.site_id ? 'flex' : 'none' }} >
                        <IconButton onClick={handleChangeRoute(event.id)}>
                          <EditIcon style={{ fontSize: '1.2rem' }} />
                        </IconButton>
                      </div>

                      <div >
                        {level > 0 && (
                          <IconButton onClick={() => copyEvent(event)}>
                            <ContentCopyIcon
                              htmlColor={isCopied.id === event.id ? '#3F51B5' : 'black'}
                              style={{ fontSize: '1.2rem' }}
                            />
                          </IconButton>
                        )}
                      </div>

                      <div >
                        <IconButton
                          style={{ display: !event.site_id ? 'flex' : 'none' }}
                          disabled={isDisabled}
                          onClick={() => setDeleteModal({ id: event.id, title: event.title })}
                        >
                          <DeleteIcon color={isDisabled ? 'disabled' : 'error'} style={{ fontSize: '1.2rem' }} />
                        </IconButton>
                      </div>
                    </div>
                  </Box>

                  {expend.includes(chainId) && (
                    <div >
                      <MainMonthTree
                        children={event.children}
                        cards={cards}
                        categoryId={categoryId}
                        chain={chainId}
                        level={level + 1}
                        setCards={setCards}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      {deleteModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && onDeleteCard(deleteModal.id);
            setDeleteModal(null);
          }}
          type='delete'
          value={` "${deleteModal.title}" `}
          open={!!deleteModal}
        />
      ) : null}
    </>
  );
};
