export const ROUTES = {
  main: '/',
  widgets: '/widgets',
  monthMain:'/month-main',
  calendar: '/calendar',
  ratings: '/ratings',
  catalogues: '/catalogues',
  consultations: '/consultations',
  forms: '/forms',
  video: '/video',
  services: '/services',
  npd: '/npd',
  authors: '/authors',
  blocks: '/blocks',
  configurator: '/configurator',
  publications: '/publications',
  factoids: '/factoids',
  archive: '/archive',
  document: '/document',
  routeChange: function (params: number | string) {
    const path = window.location.pathname.split('/')[1];
    return '/' + path + '/' + params;
  },
};
