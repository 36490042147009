import React, { MutableRefObject, useState } from 'react';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import { EditorFeatured } from '../../Editors';
import { SelectSearchReq } from '../../ui';
import { IDataOpt } from '../../ui/SelectSearchReq';
import { useSearchAuthors } from '../../../hooks/useSearchAuthors';
import { CATEGORIES } from '../../../utils/consts';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../store/applicationState';
import { WarningModal } from '../../modals';

interface PropsType {
  type: 'edit' | 'new';
  handleCancel: () => void;
  descriptionTemplateRef: MutableRefObject<any> | undefined;
  setDescriptionTemplate: (template: { author: IDataOpt | null; description: string }) => void;
  descriptionTemplate: { author: IDataOpt | null; description: string } | null;
  setTemplates: (templates: any[]) => void;
  templates: any[];
}

export const EditDescriptionTemplate: React.FC<PropsType> = ({
  type,
  handleCancel,
  descriptionTemplateRef,
  descriptionTemplate,
  setDescriptionTemplate,
  setTemplates,
  templates,
}) => {
  const loading = false;
  const error = null;
  const errors = '';
  const { Configurations } = useSelector((store: AppStore) => store);
  const { options, handleSearchAuthors } = useSearchAuthors();
  const [author, setAuthor] = useState<IDataOpt | null>(descriptionTemplate?.author || null);
  const [comeBackModal, setComeBackModal] = useState<{ id: number } | null>(null);
  const [isSave, setIsSave] = useState(false);

  const onSubmit = () => {
    setIsSave(true);
    const description = descriptionTemplateRef?.current?.getContent();
    setDescriptionTemplate({ author, description });
    setTemplates(
      templates.map((el) => {
        if (el.title === 'Опис') {
          return { ...el, month_main_detail: { description, author } };
        }
        return el;
      })
    );
  };

  const handleReturnBack = () => {
    const description = descriptionTemplateRef?.current?.getContent();
    let isUnsavedData = false;

    if (type === 'new') {
      isUnsavedData = description.trim() !== '' || (author?.id ? true : false);
    } else {
      if (
        !!(descriptionTemplate?.description !== description && description.trim() !== '') ||
        !!(author && descriptionTemplate?.author?.id !== author.id && author.id)
      ) {
        isUnsavedData = true;
      }
    }

    isUnsavedData && !isSave ? setComeBackModal({ id: Math.random() + 1 }) : handleCancel();
    setIsSave(false);
  };

  return (
    <Box>
      <CssBaseline />
      <div>
        <Typography component='h1' variant='h5'>
          Шаблон «Опис»
        </Typography>
        <Box>
          <SelectSearchReq
            title='Автор'
            data={author}
            onChange={(value) => setAuthor(value)}
            options={options}
            handleRequest={handleSearchAuthors}
          />
        </Box>
        <Box>
          <div style={{ marginTop: '10px' }}>
            <EditorFeatured
              categoryId={CATEGORIES.TEMPLATES}
              editorRef={descriptionTemplateRef}
              initialValue={descriptionTemplate?.description}
              siteId={Configurations.siteId}
            />
          </div>
        </Box>
        <Box display={'flex'} mt={2} gap={2}>
          <Button variant='contained' color='primary' disabled={loading} onClick={onSubmit}>
            {loading ? <CircularProgress size={15} /> : 'Зберегти'}
          </Button>
          <Button variant='contained' color='inherit' onClick={handleReturnBack}>
            Повернутися
          </Button>
        </Box>
        {error ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Заповніть поля</AlertTitle>
          </Alert>
        ) : null}
        {errors ? (
          <Alert severity='error' sx={{ width: '100%' }}>
            <AlertTitle>Сталася помилка</AlertTitle>
          </Alert>
        ) : null}
        {comeBackModal?.id ? (
          <WarningModal
            handleApprove={(isApprove) => {
              isApprove && handleCancel();
              setComeBackModal(null);
            }}
            value={`Ви дійсно хочете повернутися? У вас є незбережені дані!`}
            open={!!comeBackModal.id}
            type='comeback'
          />
        ) : null}
      </div>
    </Box>
  );
};
