import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { callApi } from '../../utils/callApi';
import { setAuthors } from '../authors/actions';
import { setConsultations } from '../consultations/actions';
import { setForms } from '../forms/actions';
import { setDocs } from '../officialDoc/actions';
import { setVideo } from '../video/actions';
import { GetConfigurations, GetSites, TypeGetConfigurationsR, TypeGetSitesR } from './actions';
import ActionTypes, { IConfigurations, ISites } from './types';
import { setCart } from '../cart/actions';

function* getSitesWorker(action: ReturnType<typeof GetSites.request>): Generator {
  const { callBack } = action.payload as TypeGetSitesR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/configurations/sites`,
    })) as ISites[];
    yield put(GetSites.success(resp));
  } catch (e) {
    success = false;
    yield put(GetSites.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* getConfigurationsWorker(action: ReturnType<typeof GetConfigurations.request>): Generator {
  const { id, callBack } = action.payload as TypeGetConfigurationsR;

  let success = true;
  try {
    const resp = (yield call(callApi, {
      method: 'get',
      path: `/configurations/systems/${id}`,
    })) as IConfigurations;
    yield put(setVideo(resp.video));
    yield put(setForms(resp.forms));
    yield put(setAuthors(resp.authors));
    yield put(setConsultations(resp.consultations));
    yield put(setDocs(resp.officialDoc));
    yield put(setCart(resp.publications));
    yield put(GetConfigurations.success(resp));
  } catch (e) {
    success = false;
    yield put(GetConfigurations.error(e as string));
  } finally {
    if (callBack) yield call(callBack, success);
  }
}

function* watchFetchRequest() {
  yield takeEvery(ActionTypes.GET_SITES_R, getSitesWorker);
  yield takeEvery(ActionTypes.GET_CONFIGURATIONS_R, getConfigurationsWorker);
}

export default function* configurationsSaga() {
  yield all([fork(watchFetchRequest)]);
}
