import React from 'react';
import clsx from 'clsx';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import TextareaAutosize from '@mui/material/TextareaAutosize';

type PropsType = {
  title?: string;
  value?: string;
  required?: boolean;
  error?: boolean;
  style?: React.CSSProperties | undefined;
  className?: string;
  onChange: (value: string) => void;
};

export const TextareaOutline: React.FC<PropsType> = ({ style, className, title, required, error, value, onChange }) => {
  const [isFocus, setIsFocus] = React.useState(false);
  const { ref } = useOutsideClick(() => setIsFocus(false));

  const setClass = () => {
    if (isFocus) return 'placeholderFocus';
    if (!!value) return 'placeholderInit';
  };

  return (
    <div style={style} className={clsx('selectInput', className)} ref={ref}>
      {title && (
        <div className={clsx('selectInput-title', setClass())} style={{ color: error && !isFocus ? 'red' : '' }}>
          {required ? `${title} *` : title}
        </div>
      )}
      <div
        className={clsx('wrappField', isFocus && 'wrappFieldFocus')}
        style={{ borderColor: error && !isFocus ? 'red' : '', padding: '8.5px 14px', height: '100%' }}
        onClick={() => setIsFocus(true)}
      >
        <TextareaAutosize className={'field'} style={{ height: '20px' }} value={value} onChange={(e) => onChange(e.target.value)} />
      </div>
    </div>
  );
};
