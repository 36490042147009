import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store/applicationState';
import { Alert, AlertTitle } from '@mui/lab';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { BasicDatePicker, SelectOutline, SelectPopup, SelectPopupFile, SelectSearch } from '../ui';
import { EditorDocum } from '../Editors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { APPROVED } from '../../utils/consts';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { WarningModal, PublishersModal } from '../modals';
import { IOfficialDoc } from '../../store/officialDoc/types';
import { CreateDoc, DeleteDoc, GetTypesDoc, UpdateDoc } from '../../store/officialDoc/actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import { backlight } from '../Editors/EditorDocum/entities/utils';

interface PropsType {
  type: 'edit' | 'new';
  data: IOfficialDoc;
  title: string;
  handleCancel: () => void;
}

export const EditOfficial: React.FC<PropsType> = ({ type, data, title: titlePage, handleCancel }) => {
  const [title, setTitle] = React.useState(data?.title || '');
  const [startDate, setStartDate] = React.useState(data ? data.startDate : null);
  const [endDate, setEndDate] = React.useState(data ? data.endDate : null);
  const [status, setStatus] = React.useState(typeof data?.status === 'number' ? String(data.status) : '');
  const [typeDoc, setTypeDoc] = React.useState(typeof data?.typeId === 'number' ? String(data.typeId) : '');
  const [publishers, setPublishers] = React.useState<{ id: number; name: string }[]>(data?.publishers || []);
  const [docDate, setDocDate] = React.useState<Date | null>(data?.docDate || null);
  const [docNum, setDocNum] = React.useState(data ? String(data.docNum) : '');
  const [registDate, setRegistDate] = React.useState<Date | null>(data?.registDate || null);
  const [registNum, setRegistNum] = React.useState(data?.registNum ? String(data.registNum) : '');
  const [isPublic, setIsPublic] = React.useState(typeof data?.approved === 'number' ? data.approved : APPROVED.CREATE);
  const [searchTags, setSearchTags] = React.useState(data?.searchTags || '');

  const [isEmpty, setIsEmpty] = React.useState(false);
  const [errors, setErrors] = React.useState('');
  const [isModal, setIsModal] = React.useState<{ id: number; value: string } | null>(null);
  const [isRefresh, setIsRefresh] = React.useState(false);

  const { OfficialDoc } = useSelector((store: AppStore) => store);

  const editorRef = React.useRef<any | null>(null);
  const ref = React.useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (data?.body) {
      //@ts-ignore
      editorRef.current?.setContent(data.body);
    }
  }, [dispatch, data?.body]);

  React.useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (e: Event) => {
    //@ts-ignore
    if (e.target?.scrollingElement?.scrollTop > 60 && ref.current) {
      ref.current.style.top = '0';
    } else if (ref.current) {
      ref.current.style.top = '70px';
    }
  };

  const handleRemove = (id: number) => {
    dispatch(
      DeleteDoc.request({
        id,
        callBack: (success) => {
          success && handleCancel();
        },
      })
    );
  };

  const onSubmit = () => {
    setErrors('');
    // dispatch(GetDoc.request({ id: data.id,callBack:(suc,data)=>{
    //     console.log(data)
    // } }));
    const _body = editorRef.current?.getBody();

    if (_body.children[0].classList.contains('anchor')) {
      editorRef.current.dom.removeClass(_body.childNodes, 'anchor');
    }
    const numStatus = Number(status);
    const body = backlight(false, editorRef.current?.getContent());
    if (!body || !docDate || !title || publishers.length === 0 || !typeDoc || !docNum) return setErrors('Заповніть поля');
    if (endDate && startDate && startDate > endDate) return setErrors('Дата закінчення має бути більша за дату старту');
    if (numStatus === 0 && !endDate) return setErrors('Якщо статус "Втратив чинність", то заповніть поле "Дата закінчення"');
    if (numStatus !== 0 && endDate) return setErrors('Якщо заповнене поле "Дата закінчення", то статус повинен бути "Втратив чинність"');

    if (!data) return setErrors('Сталася помилка');

    dispatch(
      UpdateDoc.request({
        id: data.id,
        data: {
          groupId: data.groupId,
          title,
          startDate,
          endDate,
          status: numStatus,
          publisherIds: publishers.map((it) => it.id),
          body,
          typeId: Number(typeDoc),
          registNum: registNum || null,
          registDate,
          docNum,
          docDate,
          approved: isPublic,
          searchTags,
        },
        callBack: (success: boolean, err) => {
          if (!success && err?.data?.message) {
            setErrors(err.data.message);
          } else if (!success) {
            setErrors('Сталася помилка');
          } else if (success && err) {
            setErrors(err);
          }
        },
      })
    );
  };

  const handleRefreshTypeDoc = () => {
    setIsRefresh(true);
    dispatch(
      GetTypesDoc.request({
        callBack: () => setIsRefresh(false),
      })
    );
  };

  const createRedaction = () => {
    if (!OfficialDoc.loading) {
      dispatch(
        CreateDoc.request({
          id: data.id,
          callBack: (success, id) => {
            if (!success) {
              setErrors('Сталася помилка');
              const timeId = setTimeout(() => {
                clearTimeout(timeId);
                setErrors('');
              }, 1500);
            }
            if (success && !Number.isNaN(Number(id))) {
              window.open(`/document/${id}`);
            }
          },
        })
      );
    }
  };

  return (
    <Box>
      <CssBaseline />
      <div className={'paper'} style={{ alignItems: 'center' }}>
        <Box className={'header'} pt={9}>
          <Typography component="h1" variant="h5">
            {type.includes('new') ? `Створити ${titlePage}` : `Редагувати ${titlePage}`}
          </Typography>
          <Button onClick={() => setIsEmpty(!isEmpty)} variant="text" color="primary" size="small">
            {isEmpty ? 'розгорнути' : 'згорнути'}
          </Button>
          <IconButton
            aria-label="delete"
            style={{ color: type !== 'new' && data?.approved ? 'red' : '' }}
            onClick={() => type !== 'new' && data?.approved && setIsModal({ id: data.id, value: titlePage })}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        <div style={{ display: isEmpty ? 'none' : '' }}>
          <Box className={'textField'}>
            <FormControlLabel
              style={{ marginTop: '22px' }}
              control={<Checkbox checked={isPublic === APPROVED.PUBLISH} />}
              onChange={() => setIsPublic(isPublic === APPROVED.PUBLISH ? APPROVED.CREATE : APPROVED.PUBLISH)}
              label="Публікувати"
            />
          </Box>
          <TextField
            required
            style={{ marginTop: '7px' }}
            size="small"
            id="outlined-basic"
            label="Назва"
            variant="outlined"
            value={title}
            error={!!errors && !title}
            className={'textField'}
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
              setTitle(event.target.value);
            }}
          />
          <div className={'textField'} style={{ display: 'flex' }}>
            <BasicDatePicker
              id="dateStart"
              style={{ marginRight: '10px' }}
              required
              label="Дата старту"
              value={startDate}
              error={!!errors && !startDate}
              onChange={setStartDate}
            />
            <BasicDatePicker
              id="dateEnd"
              required
              label="Дата закінчення"
              value={endDate}
              error={!!errors && Number(status) === 0 && !endDate}
              onChange={setEndDate}
            />
          </div>
          <TextField
            size="small"
            id="outlined-basic-tags"
            label="Теги"
            variant="outlined"
            value={searchTags}
            className={'textField'}
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
              setSearchTags(event.target.value);
            }}
          />
          <SelectOutline
            required
            id="outlined-basic-status"
            title="Юр. Статус"
            value={status}
            error={!!errors && !status}
            className={'textField'}
            options={[
              { value: '2', label: 'Чинний' },
              { value: '1', label: 'Не набрав чинності' },
              { value: '0', label: 'Втратив чинність' },
              { value: '3', label: 'Частково втратив чинність' },
            ]}
            handleChange={setStatus}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SelectSearch
              required
              title="Тип"
              value={typeDoc}
              error={!!errors && !typeDoc}
              className={'textField'}
              options={
                OfficialDoc.configurations?.typesDoc
                  .filter((it) => it.approved === APPROVED.PUBLISH)
                  .map((it) => ({ value: String(it.id), label: it.name })) || []
              }
              onChange={setTypeDoc}
            />
            <IconButton style={{ marginTop: '20px' }} aria-label="refresh" disabled={isRefresh} onClick={handleRefreshTypeDoc}>
              {isRefresh ? <CircularProgress size={25} /> : <RefreshIcon />}
            </IconButton>
          </div>
          <SelectPopup
            required
            title="Видавники"
            value={publishers}
            error={!!errors && publishers.length === 0}
            className={'textField'}
            options={OfficialDoc.configurations?.publishers.publishers.filter((it) => it.approved === APPROVED.PUBLISH)}
            onChange={setPublishers}
            PropsModal={PublishersModal}
          />
          <div className={'textField'} style={{ display: 'flex' }}>
            <BasicDatePicker
              id="dateStart"
              style={{ marginRight: '10px' }}
              required
              label="Дата документа"
              value={docDate}
              error={!!errors && !docDate}
              onChange={setDocDate}
            />
            <TextField
              fullWidth
              required
              size="small"
              id="outlined-basic"
              label="Номер"
              variant="outlined"
              value={docNum}
              error={!!errors && !docNum}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                setDocNum(event.target.value);
              }}
            />
          </div>
          <div className={'textField'} style={{ display: 'flex' }}>
            <BasicDatePicker
              id="dateStart"
              style={{ marginRight: '10px' }}
              label="Дата реєстрації в Мін’юсті"
              value={registDate}
              onChange={setRegistDate}
            />
            <TextField
              fullWidth
              size="small"
              id="outlined-basic"
              label="Номер"
              variant="outlined"
              value={registNum}
              onChange={(event: React.ChangeEvent<{ value: string }>) => {
                setRegistNum(event.target.value);
              }}
            />
          </div>
          <SelectPopupFile className={'textField'} title="Додатки" files={data.attachments} docId={data.id} />
        </div>
        <div style={{ margin: '20px 0px 0px 196px', transform: 'translateX(-20%)' }}>
          <EditorDocum docId={type === 'edit' && data ? data.id : 0} initialValue={data?.body} editorRef={editorRef} />
        </div>
        <div
          style={{
            position: 'fixed',
            top: '70px',
            zIndex: 1005,
            margin: 'auto',
            padding: '20px',
            left: '55%',
          }}
          ref={ref}
        >
          <div className="row">
            <div className="col m-auto">
              <div className="d-flex justify-content-end">
                <span style={{ display: 'none' }} className="mx-2 align-self-center">
                  Автозбереження через: сек.
                </span>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={OfficialDoc.loading}
                  onClick={onSubmit}
                  style={{ height: '36px', margin: '0 20px 0 60px' }}
                >
                  {OfficialDoc.loading ? <CircularProgress size={15} /> : 'Зберегти'}
                </Button>
                <Button variant="contained" color="inherit" onClick={handleCancel}>
                  Повернутися
                </Button>
                <Button
                  sx={{ marginLeft: '20px' }}
                  disabled={OfficialDoc.loading}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    createRedaction();
                  }}
                >
                  Створити редакцію
                </Button>
              </div>
            </div>
          </div>
          {errors ? (
            <Alert severity="error" sx={{ width: '100%', marginTop: '10px', position: 'absolute' }}>
              <AlertTitle>{errors}</AlertTitle>
            </Alert>
          ) : null}
        </div>
      </div>
      {isModal ? (
        <WarningModal
          handleApprove={(isApprove) => {
            isApprove && handleRemove(isModal.id);
            setIsModal(null);
          }}
          value={isModal.value}
          open={!!isModal}
        />
      ) : null}
    </Box>
  );
};
