import { action } from 'typesafe-actions';
import { Actions, TDefRequest } from '../Actions';
import ActionTypes, { IOpenPublication, IPublisherDoc, TOpenPublicationsState, TPublishersState } from './types';

export const setPublishers = (payload: TPublishersState['data']) => action(ActionTypes.SET_PUBLISHERS, payload);

export interface TypeGetPublishersR extends TDefRequest {
  data: {
    page?: number;
    id?: string;
    name?: string;
  };
}

export interface TypeGetOpenPublicationsR extends TDefRequest {
  data: {
    id?: number;
    title?: string;
    site_id?: number;
    category_id?: number;
  };
}

export interface TypeDeleteOpenPublicationR extends TDefRequest {
  id: number;
  category_id: number;
}

const GetPublishersAction = new Actions('GET_PUBLISHERS', ActionTypes);
export const GetPublishers = {
  request: (payload: TypeGetPublishersR) => GetPublishersAction.request(payload),
  success: (payload: TPublishersState['data']) => GetPublishersAction.success(payload || {}),
  error: (message: string) => GetPublishersAction.error(message),
};

const GetOpenPublicationsAction = new Actions('GET_OPEN_PUBLICATION', ActionTypes);
export const GetOpenPublications = {
  request: (payload: TypeGetOpenPublicationsR) => GetOpenPublicationsAction.request(payload),
  success: (payload: TOpenPublicationsState['data']) => GetOpenPublicationsAction.success(payload || {}),
  error: (message: string) => GetOpenPublicationsAction.error(message),
};

const GetOpenPublicationIdsByCategoryAction = new Actions('GET_OPEN_PUBLICATION_CATEGORY_IDS', ActionTypes);
export interface TypeOpenPublicationIdsByCategoryR extends TDefRequest {
  category_id: number;
  site_id?: number;
  id?: number;
}
export const GetOpenPublicationIdsByCategory = {
  request: (payload: TypeOpenPublicationIdsByCategoryR) => GetOpenPublicationIdsByCategoryAction.request(payload),
  success: (payload: TPublishersState['data']) => GetOpenPublicationIdsByCategoryAction.success(payload || {}),
  error: (message: string) => GetOpenPublicationIdsByCategoryAction.error(message),
};

const CreatePublisherAction = new Actions('CREATE_PUBLISHER', ActionTypes);
export interface TypeCreatePublisherR extends TDefRequest {
  data: {
    name: string;
    shortName: string;
    shortNameGenitive: string;
    synonyms?: string;
    status: number;
    approved: number;
  };
}
export const CreatePublisher = {
  request: (payload: TypeCreatePublisherR) => CreatePublisherAction.request(payload),
  success: (payload: IPublisherDoc) => CreatePublisherAction.success(payload || {}),
  error: (message: string) => CreatePublisherAction.error(message),
};

const CreateOpenPublicationAction = new Actions('CREATE_OPEN_PUBLICATION', ActionTypes);
export interface TypeCreateOpenPublicationR extends TDefRequest {
  id: number;
  category_id: number;
}
export const CreateOpenPublication = {
  request: (payload: TypeCreateOpenPublicationR) => CreateOpenPublicationAction.request(payload),
  success: (payload: IOpenPublication) => CreateOpenPublicationAction.success(payload || {}),
  error: (message: string) => CreateOpenPublicationAction.error(message),
};

const UpdatePublisherAction = new Actions('UPDATE_PUBLISHER', ActionTypes);
export interface TypeUpdatePublisherR extends TDefRequest {
  data: {
    name: string;
    shortName: string;
    shortNameGenitive: string;
    synonyms?: string;
    status: number;
    approved: number;
  };
  id: number;
}
export const UpdatePublisher = {
  request: (payload: TypeUpdatePublisherR) => UpdatePublisherAction.request(payload),
  success: (payload: IPublisherDoc) => UpdatePublisherAction.success(payload || {}),
  error: (message: string) => UpdatePublisherAction.error(message),
};

const UpdateOpenPublicationAction = new Actions('UPDATE_OPEN_PUBLICATION', ActionTypes);
export interface TypeUpdateOpenPublicationR extends TDefRequest {
  data: {
    title: string;
  };
  id: number;
}
export const UpdateOpenPublication = {
  request: (payload: TypeUpdateOpenPublicationR) => UpdateOpenPublicationAction.request(payload),
  success: (payload: IOpenPublication) => UpdateOpenPublicationAction.success(payload || {}),
  error: (message: string) => UpdateOpenPublicationAction.error(message),
};

const DeletePublisherAction = new Actions('DELETE_PUBLISHER', ActionTypes);
export interface TypeDeletePublisherR extends TDefRequest {
  id: number;
}
export const DeletePublisher = {
  request: (payload: TypeDeletePublisherR) => DeletePublisherAction.request(payload),
  success: (payload: IPublisherDoc) => DeletePublisherAction.success(payload || {}),
  error: (message: string) => DeletePublisherAction.error(message),
};

const DeleteOpenPublicationAction = new Actions('DELETE_OPEN_PUBLICATION', ActionTypes);
export interface TypeDeleteOpenPublicationR extends TDefRequest {
  id: number;
  category_id: number;
}
export const DeleteOpenPublication = {
  request: (payload: TypeDeleteOpenPublicationR) => DeleteOpenPublicationAction.request(payload),
  success: (payload: IOpenPublication) => DeleteOpenPublicationAction.success(payload || {}),
  error: (message: string) => DeleteOpenPublicationAction.error(message),
};
